export default {

    DASHBOARD: 'DASHBOARD',
    CONTACT: 'CONTACT',
    STOCK_ARTICLE: 'STOCK_ARTICLE',
    GENERAL_INVOICE: 'GENERAL_INVOICE',
    CRM_PIPELINE: 'CRM_PIPELINE',
    CRM_INVOICE: 'CRM_INVOICE',
    CRM_APPOINTMENT: 'CRM_APPOINTMENT',
    CRM_STATISTICS: 'CRM_STATISTICS',
    EXPLORER_DOCUMENT: 'EXPLORER_DOCUMENT',
    FINANCE_ACCOUNT: 'FINANCE_ACCOUNT',
    FINANCE_EXPENSE: 'FINANCE_EXPENSE',
    RH_ABSENCE: 'RH_ABSENCE',
    RH_PAYSLIP: 'RH_PAYSLIP',
    RH_CONTRACT: 'RH_CONTRACT',
    CUSTOMER_NPS: 'CUSTOMER_NPS',
    PROJECT: 'PROJECT',
    PROJECT_BUDGET: 'PROJECT_BUDGET',
    PROJECT_REPORTING: 'PROJECT_REPORTING',
    PROJECT_FEED_NEWS: 'PROJECT_FEED_NEWS',
    PROJECT_CHAT: 'PROJECT_CHAT',
    NOTARIAL_REPERTORY: 'NOTARIAL_REPERTORY',
    NOTARIAL_REPORT: 'NOTARIAL_REPORT',
    COMMUNICATION_MESSENGER: 'COMMUNICATION_MESSENGER',
    COMMUNICATION_MAIL: 'COMMUNICATION_MAIL',

}
