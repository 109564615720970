<template>
  <div class="modal fade" tabindex="-1" :id="'kt_modal_' + id">
      <div class="modal-dialog modal-lg" role="document">
        <form @submit.prevent="submitForm" class="form">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"> {{$t('multiSociety.new_society')}}</h5>
  
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
  
                <span class="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                      fill="currentColor" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                      fill="currentColor" />
                  </svg>
                </span>
              </div>
            </div>
            <div class="modal-body">
              <RequiredText />
              <div class="row">

                <div class="col-6">
                  <Input
                    :name="`${id}-name`"
                    type="text"
                    :label="$t('company.label.name')"
                    labelClass="form-label fs-6 text-black"
                    :inputClass="'form-control form-control-lg form-control-solid'"
                    :isRequired="true" size="lg"
                    :placeholder="$t('company.label.name')
                    "
                    v-model="formData.name"
                />

                <Input
                    
                    :name="`${id}-email`"
                    type="email"
                    :label="$t('company.label.email')"
                    labelClass="form-label fs-6 text-black"
                    :inputClass="'form-control form-control-lg form-control-solid'"
                    :isRequired="true" size="lg"
                    :placeholder="$t('company.label.email')"
                    v-model="formData.society_email"
                />
                  <Input
                      :name="`${id}-ifu_number`"
                      type="text"
                      :label="$t('company.label.ifu_number')"
                      labelClass="form-label fs-6 text-black"
                      :inputClass="'form-control form-control-lg form-control-solid'"
                      :isRequired="true" size="lg"
                      :placeholder="$t('company.label.ifu_number')
                    "
                      v-model="formData.ifu_number"
                  />

                </div>
                <div class="col-6">
                  <ImageInput class="" v-model:image="formData.logo_url" :model-image="formData.image_url" containerClass="mb-5 text-center " imageClass="rounded-circle"/>

               </div>

              </div>
              <div  class="row">
                <div class="col-md-6 col-12">
                  <Input
                      :name="`${id}-rccm_number`"
                      type="text"
                      :label="$t('company.label.rccm_number')"
                      labelClass="form-label fs-6 text-black"
                      :inputClass="'form-control form-control-lg form-control-solid'"
                      :isRequired="true" size="lg"
                      :placeholder="$t('company.label.rccm_number')
                    "
                      v-model="formData.rccm_number"
                  />
                </div>
                <div class="col-md-6 col-12">
                  <InputTel
                      :name="`${id}-phone`"
                      type="text"
                      :label="$t('user.phone')"
                      labelClass="form-label fs-6 text-black"
                      inputClass="form-control form-control-sm form-control-solid"
                      v-model:phone="formData.phone"
                      v-model:phone-code="formData.phone_code"
                      :placeholder="$t('enter_phone')"
                      :isRequired="true" />
                </div>
              </div>
               <div class="row">

                <div class="col-md-6 col-12">
                    <Input
                      :name="`${id}-head`"
                      type="text"
                      :label="$t('company.label.headquarters_address')"
                      labelClass="form-label fs-6 text-black"
                      :inputClass="'form-control form-control-lg form-control-solid'"
                      :isRequired="true" size="lg"
                      :placeholder="$t('company.label.headquarters_address')"
                      v-model="formData.headquarters_address"
                  />

                  </div>
                <div class="col-md-6 col-12">
                  <Input
                  :name="`${id}-legal`"
                  type="text"
                  :label="$t('company.label.legal_form')"
                  labelClass="form-label fs-6 text-black"
                  :inputClass="'form-control form-control-lg form-control-solid'"
                  :isRequired="true" size="lg"
                  :placeholder="$t('company.label.legal_form')"
                  v-model="formData.legal_form"
              />
                </div>

              </div>

              <div class="row ">

                    <div class="col-md-6 col-12">
                      <Select2
                      :multiple="false"
                      :show-search="true"
                      :parent-id="'#kt_modal_' + id"
                      :selectClass="'form-select form-control-lg form-select-solid'"
                      :name="`${id}-country`"
                      :label="$t('company.label.country')"
                      labelClass="form-label fs-6 text-black"
                      :isRequired="true"
                      :placeholderText="$t('company.label.country')"
                      v-model="formData.country_id">
                        <option v-for="(country, index) in countries" :key="index" :value="country.id">
                           {{ country.name }}
                        </option>
                     </Select2> 
                        
                    </div>
                    <div class="col-md-3 col-12">
                      
                      <Input
                          :name="`${id}-city`"
                          type="text"
                          :label="$t('company.label.city')"
                          labelClass="form-label fs-6 text-black"
                          :inputClass="'form-control form-control-lg form-control-solid'"
                          :isRequired="true" size="lg"
                          :placeholder="$t('company.label.city')"
                          v-model="formData.city"
                      />
                       
                    
                     </div>
                     <div class="col-md-3 col-12">
                    
                    <Input
                    :name="`${id}-zipcode`"
                    type="text"
                    :label="$t('auth.zipcode')"
                    labelClass="form-label fs-6 text-black"
                    :inputClass="'form-control form-control-lg form-control-solid'"
                    :isRequired="false" size="lg"
                    :placeholder="$t('auth.zipcode')"
                    v-model="formData.zipcode"
                />

                    
                  </div>
                </div>
               
                <div class="row">

                  <div class="col-md-6 col-12">

                      <Input
                    :name="`${id}-name`"
                    type="text"
                    :label="$t('company.label.address')"
                    labelClass="form-label fs-6 text-black"
                    :inputClass="'form-control form-control-lg form-control-solid'"
                    :isRequired="true" size="lg"
                    :placeholder="$t('company.label.address')"
                    v-model="formData.address"
                />
                        
                    </div>
                  <div class="col-md-6 col-12">
                    <Select2
                      :multiple="false"
                      :show-search="true"
                      :selectClass="'form-select form-control-lg form-select-solid'"
                      :name="`${id}-currency`"
                      :parent-id="'#kt_modal_' + id"
                      :label="$t('company.label.currency')"
                      labelClass="form-label fs-6 text-black"
                      :isRequired="true"
                      :placeholderText="$t('company.label.currency')"
                      v-model="formData.currency_id">
                        <option v-for="(currency, index) in currencies" :key="index" :value="currency.id">
                           {{ currency.name + ' (' +  currency?.iso + ')'}}
                        </option>
                     </Select2>
                  </div>
                 
                  
                </div>
            </div>
          
            <div class="modal-footer">
                <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.close" @click="closeModal"></Button>
                <Button btn-class="btn-sm me-3" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :is-loading="processing"></Button>
              </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script setup>

  import {ref, defineProps, computed, onMounted,watch} from "vue";
  import Input from "@/components/Input.vue";
  import ImageInput from "@/components/ImageInput.vue";
  import {useStore} from "vuex";
  import InputTel from "@/components/InputTel.vue";
  import Select2 from "@/components/Select2.vue";
  import RequiredText from "@/components/RequiredText.vue"
  import Button from "@/components/Button.vue"

  const store = useStore()

  const props = defineProps({
    id: { type: String, required: true}
  })


  const formData = ref(
    {
      name: '',
      rccm_number: '',
      ifu_number: '',
      society_email: '',
      phone_code: '',
      phone: '',
      city: '',
      currency_id:'',
      country_id:'',
      zipcode: '',
      address: '',
      headquarters_address: '',
      legal_form: '',
      logo_url: null,
      image_url: null
    }
  )
  const processing = ref(false)
  const emit = defineEmits(['reloadDatatable'])


  const currencies = computed(() => store.getters['currencies/getCurrencies'])
  const countries = computed(() => store.getters['countries/getCountries'])

  onMounted(() => {
    store.dispatch('countries/loadCountries')
    store.dispatch('currencies/loadCurrencies')
  })



  function closeModal(){
    $("#kt_modal_" + props.id).modal('hide')
  }

  function submitForm() {
    processing.value = true
    store.dispatch('multiSociety/storeSociety', formData.value)
        .then(res => {
          closeModal()
          emit('reloadDatatable')
          formData.value = {
            name: '',
            society_email: '',
            rccm_number: '',
            ifu_number: '',
            society_phone_code: '',
            society_phone: '',
            city: '',
            currency_id:'',
            country_id:'',
            zipcode: '',
            address: '',
            headquarters_address: '',
            legal_form: '',
            logo_url: null,
            image_url: null
          }
          store.dispatch('offices/loadOfficesBySocieties')
          window.toastr.success(t('notification.payment_created'))
          processing.value = false
        })
        .catch(err => {
          processing.value = false;
        });
  }
  
  </script>
  
  <style scoped></style>
  