 <template>
      <div>
          <div v-if="$can(abilities.STATS_PROJECT)" class="row mb-10">
            <div class="col-12">
              <Card class="card shadow-sm p-10">
                <h2>{{ $t('projects.stats') }}</h2>
                <div class="row mt-5">
                  <div class="col-3" v-for="(stat, index) in projectStatusStat" :key="index">
                    <Card class="card p-5">
                      <div class="d-flex  align-items-center">
                        <span class="badge badge-square fs-2 p-5" :class="'badge-'+stat.color">{{stat.count}}</span>
                        <div class="fs-2 ms-5">{{stat[locale]}}</div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <Card :title="$t('projects.list')">
                <template #cardToolbar>
                  <ButtonDropdownFilter
                      :btn-title="$t('projects.label.cat')"
                      :options="[...categories]"
                      value-field="id"
                      name-field="name"
                      v-model="query.project_category_id"
                  />
                </template>
                <CategoryGroupFilter v-model="query.project_category_group_id"/>
                <div class="card-body">
                  <ProjectListView :filter="query"  @reloadDatatable='loadData' :action="false"/>
                </div>
               
              </Card>
            </div>
          </div>
      </div>
 </template>
  <script setup>
  
  import Card from "@/components/Card.vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";
  import {useLocales} from "@/composables/locales";
  import {computed, ref, watch, onMounted} from "vue";
  import DateRangePicker from "@/components/DateRangePicker.vue";
  import abilities from "@/composables/abilities";
  import ButtonDropdownFilter from "@/components/ButtonDropdownFilter.vue";
  import CategoryGroupFilter from "@/Modules/Projects/views/Projects/components/CategoryGroupFilter.vue";
  import ProjectListView from "@/Modules/Projects/views/Projects/ListView.vue";

  const route = useRoute()
  const store = useStore()
  const {locale} = useLocales()
  const props = defineProps({
    filters : {type: Object, default: {}},
  })


  const refreshKey = ref(0)

  const query = ref({
    start_date: '',
    end_date: '',
    office: '',
    contact_id: route.params.id,
    project_category_id: '',
    project_category_group_id:'',
    reload: 0
  })

  watch(() => props.filters, (value) => {
    if(value){
      query.value.start_date = value.start_date
      query.value.end_date = value.end_date
    }
  }, {deep : true})

  watch(() => route.params.id, (value) => {
    if(value){
      query.value.contact_id = value
    }
  })



  const projectStatusStat = ref({})
  const getFistOffice = ref("")
  
  const offices = computed(() => (store.getters['offices/getSocietyOffices']))
  const currentCompany = computed(() => store.getters['offices/getSelectedCompany'])

  const categories = computed(() => store.getters['projectCategories/getProjectCategories'])


  watch(() => currentCompany.value, (value) => {
    if(value){
     store.dispatch("offices/loadSocietyOffices", value?.id)
    }
  })

  watch(() => offices.value, (value) => {
    if(value){
      getFistOffice.value = value[0]
    }
  })

  watch(() => getFistOffice.value, (value) => {
    if(value){
      query.value.office = getFistOffice.value?.id
    }
  })
  
  watch(() => [
      query.value.start_date,
    query.value.end_date,
    query.value.contact_id,
  ], (newValue) => {
    loadStat()
  })


  async function loadStat() {
    if(query.value.start_date && query.value.end_date ){
      await store.dispatch('projects/getProjectStatusStat', query.value).then((res) => { 
        projectStatusStat.value = res.data
      })
    }
  }

  function loadData(){
    refreshKey.value++
    query.value.reload += 1 
    loadStat()
  }
  
  onMounted(async() => {
    store.dispatch("projectCategories/loadProjectCategories")
    if(currentCompany.value){
      store.dispatch("offices/loadSocietyOffices", currentCompany.value?.id)
    }
    loadStat()
  })
  
</script>

<style scoped>
  .table-container {
    height: 350px;
    overflow-y: auto;
  }

  thead th {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
  }
</style>