import * as types from './mutation-types'

export default {
  [types.ADD_STATUS](state, data) {
    state.status = data
  },
  [types.ADD_HOLIDAYS](state, data) {
    state.holidays = data.holidays
  },
  [types.ADD_USER_WORkDAYS](state, data) {
    state.userWorkdays = data.workdays
  },
  [types.ADD_USER_PUBLIC_HOLIDAYS](state, data) {
    state.userPublicHolidays = data.public_holidays
  },
  [types.ADD_MANAGE_HOLIDAYS](state, data) {
    state.manage_absences = data.holidays
  },
  [types.ADD_SOCIETY_MANAGE_HOLIDAYS](state, data) {
    state.societyHolidaysByOffice = data.data
  },
  [types.LOAD_SOCIETY_OFFICES_HOLIDAYS](state, data) {
    state.societyOffices = data
  },
  [types.LOAD_USERS_HOLIDAYS](state, data) {
    state.usersHolidays = data.users
  },
}
