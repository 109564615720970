import * as types from './mutation-types'

export const createOffice = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.post('store/office', data).then((response) => {
      resolve(response)
      commit(types.ADD_OFFICE, response.data.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const getOffice = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/office/'+ id).then((response) => {

            commit(types.LOAD_OFFICE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const updateOffice = ({ commit, dispatch, state },  data) => {
  return new Promise((resolve, reject) => {
    axios.put('update/' + data.id + '/office', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const AddUsersToOffice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        axios.post('set/users/to/office', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const deleteUserOfOffice = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        axios.post("delete/user/from/office", data).then((response) => {
            //commit(types.DELETE_DEPARTMENT, id)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteOffice = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        axios.delete('delete/' + id + '/office' ).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeSociety = ({ commit, dispatch, state }, data) => {
    let form = new FormData
    form.append("name", data.name);
    form.append("society_email", data.society_email);
    form.append("rccm_number", data.rccm_number);
    form.append("ifu_number", data.ifu_number);
    form.append("phone", data.phone);
    form.append("phone_code", data.phone_code);
    form.append("address", data.address);
    form.append("city", data.city);
    form.append("zipcode", data.zipcode);
    form.append("country_id", data.country_id);
    form.append("currency_id", data.currency_id);
    form.append("headquarters_address", data.headquarters_address);
    form.append("legal_form", data.legal_form);
    form.append("logo", data.logo_url);

    form.append('_method', 'POST');


    return new Promise((resolve, reject) => {
        axios.post(`store/society`, form, { headers: { 'Content-type': 'multipart/form-data' } })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updateSociety = ({ commit, dispatch, state }, data) => {
    let form = new FormData
    form.append("name", data.name);
    form.append("society_email", data.society_email);
    form.append("rccm_number", data.rccm_number);
    form.append("ifu_number", data.ifu_number);
    form.append("phone", data.phone);
    form.append("phone_code", data.phone_code);
    form.append("address", data.address);
    form.append("city", data.city);
    form.append("zipcode", data.zipcode);
    form.append("country_id", data.country_id);
    form.append("currency_id", data.currency_id);
    form.append("headquarters_address", data.headquarters_address);
    form.append("legal_form", data.legal_form);
    form.append("logo", data.logo_url);

    form.append('_method', 'PATCH');


    return new Promise((resolve, reject) => {
        axios
            .post(`update/${data.id}/society`, form, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => resolve(response))
            .catch(err => reject(err));
    });
};

