<template>
   <div class="d-flex flex-column mb-8 fv-row" :class="[containerClass]">
      <label class="d-flex align-items-center fs-6 form-label mb-2">
        <span :class="required ? 'required' : ''">{{ $t(label) }}</span>
      </label>
      <div class="input-with-icon">
        <div class="icon-wrapper" >
          <span>{{ iso }}</span>
        </div>
        
        <input type="text"  @input="formatInput" v-model="amount"  class="form-control form-control-solid"
        :required="required"
        placeholder="0" :class="[inputClass]"/> 
      </div>
     
    </div>
</template>

<script setup>
import { defineProps, ref, watch, onMounted, computed } from 'vue'
import {useFormatAmount} from "@/composables/formatAmount"
import {useLocales} from "@/composables/locales"

const props = defineProps({
  number: {
    type: String,
    default: "0"
  },
  iso: {
    type: String,
    default: ""
  },
  required: Boolean,
  containerClass: String,
  inputClass: String,
  label: {
    type: String,
    default: "crm.pipeline.label.opportunityExpectedAmount"
  },
})

const {formatNumber} = useFormatAmount()
const {locale} = useLocales()

const emits = defineEmits(['amount'])

const formatAmount = ref(props.number)

const amount = computed(() => formatNumber(Number(props.number)) || 0)

watch(() => locale.value, (value) => {
  let formattedValue = formatNumber(formatAmount.value);
  amount.value = formattedValue;
  formatAmount.value = formattedValue.replaceAll(',', '').replaceAll(' ', '')
})

const formatInput = ($event) => {
  let value = $event.target.value;

  value = value.replace(/^0+/, ""); 
  value = value.replace(/[^0-9.]/g, '');

  let numericValue = parseFloat(value);
  if (isNaN(numericValue)) numericValue = 0;

  let formattedValue = formatNumber(numericValue);
  console.log("Formatted Value:", formattedValue);

  amount.value = formattedValue;
  formatAmount.value = numericValue; // Stocker la valeur brute pour l'émission d'événement
  emits('amount', formatAmount.value);
}

</script>

<style scoped>
input.invalid {
  border: 1px solid red;
}

.input-with-icon {
  position: relative;
}

.icon-wrapper {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 200px;
}

.input-with-icon input {
  padding-left: 40px;
}
</style>

