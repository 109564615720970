import * as types from './mutation-types'
import {ADD_TASK_PRIORITIE} from "./mutation-types";

export const loadTaskComments = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('tasks/'+data+'/comments', data).then((response) => {
            commit(types.ADD_COMMENTS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateTaskComment = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('tasks/' + data.id + '/comments/update', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const createTaskComment = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('tasks/comments/store', data).then((response) => {
            commit(types.ADD_COMMENT, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteTaskComment = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('tasks/' + id + '/comments/delete').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

