<template>
    <div :id="id"></div>
</template>

<script setup>
    import { ref, defineProps, onMounted, watch, nextTick, computed } from "vue";
    import {useLocales} from "@/composables/locales";
    import i18n from "@/plugins/i18n";
    import {useStore} from "vuex";
import ChangeDateModal from "@/Modules/CRM/components/Modals/Appointment/ChangeDateModal.vue";

    const store = useStore()
    const { locale } = useLocales()

    const emits = defineEmits(['handleClick', 'handleMonthClick'])

    watch(() => locale.value, (value) => {
        nextTick(() => initFullCalendar() )
    }, { deep: true })

    const props = defineProps({
        id: String,
        event: {
            type: Array,
            default: []
        },
        availableViews: {
            type: Array,
            default: () => ['dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listMonth']
        },
        size: {
            type: Object,
            default: {}
        },
        headerToolbar: {
            type: Boolean,
            default: true
        }
    });

    var calendar

    const fullcalendarValue = ref(null)
    const initialView = computed(() => props.availableViews[0])
    const events = computed(() => props.event)
    const currentUser = computed(() => store.getters['user/currentUser'])

    watch(() => events.value, (newValue) => {
        calendar.getEvents().forEach(event => {
            event.remove();
        });
        if(newValue){
            newValue.forEach(event => {
                calendar.addEvent(event);
            });
        }
    })

    onMounted(() => {
        initFullCalendar();
    });

    const $getCssVariable = (variable) => window.getComputedStyle(document.documentElement).getPropertyValue(variable).trim()

    function initFullCalendar() {
        fullcalendarValue.value = function () { 
            var exampleBasic = function () {
                var todayDate = moment().startOf('day');
                var YM = todayDate.format('YYYY-MM');
                var YESTERDAY = todayDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
                var TODAY = todayDate.format('YYYY-MM-DD');
                var TOMORROW = todayDate.clone().add(1, 'day').format('YYYY-MM-DD');

                var calendarEl = document.getElementById(props.id);
                
                calendar = new FullCalendar.Calendar(calendarEl, {
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: props.availableViews.join(',')
                    },
                    height: props.size.height || '900px',
                    contentHeight: props.size.contentHeight || '850px',
                    aspectRatio: 3,
                    eventOverlap: false,
                    nowIndicator: true,
                    eventMinHeight: 120,
                    now: TODAY + 'T09:25:00',
                    initialView: initialView.value,
                    initialDate: TODAY,
                    editable: true,
                    locale: locale.value,
                    dayMaxEvents: 2,
                    navLinks: true,
                    events: events.value,
                    datesSet: function (info) {
                        const { currentStart, currentEnd } = info.view;
                        emits('handleMonthClick', moment(currentStart).format('DD-MM-YYYY'), moment(currentEnd).format('DD-MM-YYYY'));
                    },
                    eventClick: handleEventClick,
                    dayCellDidMount: handleDayCellDidMount,
                    eventDidMount: function (info) {
                        const { color } = info.event.extendedProps;

                        info.el.style.backgroundColor = color || $getCssVariable('--bs-light-primary');
                        info.el.style.borderColor = color || $getCssVariable('--bs-light-primary');
                        info.el.style.borderRadius = '8px'; 
                        info.el.style.padding = '5px';
                        info.el.style.width = '100%';
                        info.el.style.height = '100%';
                    },
                    eventContent: function (info) {
                        const { numberMembers, startTime, endTime, check, creator } = info.event.extendedProps;

                        const container = document.createElement('div');
                        container.style.display = 'flex';
                        container.style.flexDirection = 'column';
                        container.style.padding = '5px';
                        container.style.borderRadius = '8px';
                        container.style.width = '100%';
                        container.style.height = '100%';

                        const titleRow = document.createElement('div');
                        titleRow.style.display = 'flex';
                        titleRow.style.alignItems = 'center';
                        titleRow.style.flex = '1';
                        titleRow.innerHTML = `
                            <span style="color: orange; font-size: 16px;">
                                <i class="fas fa-video" style="color: #F07817;"></i>
                            </span>
                            <span class="fs-4 title-container" style="margin-left: 5px; color: #0072CE; font-weight: bold; display: inline-block; max-width: calc(100% - 40px); overflow: hidden; text-overflow: ellipsis; white-space: nowrap; vertical-align: middle;">
                                ${info.event.title}
                                ${currentUser.value && currentUser.value.id !== creator ? `
                                <span class="bg-white px-4 py-2" style="position: absolute; top: 0; right: 0; border-top-right-radius: 8px;">
                                    <i class="fas fa-envelope fs-4 text-warning" style="position: relative; display: inline-block;"></i>
                                    <i class="text-warning fas fa-plus" style="position: absolute; top: 3px; right: 0; font-size: 0.8em;"></i>
                                </span>` : ''}
                            </span>
                        `;

                        const participantsRow = document.createElement('div');
                        participantsRow.style.display = 'flex';
                        participantsRow.style.alignItems = 'center';
                        participantsRow.style.marginTop = '5px';
                        participantsRow.style.flex = '1';
                        participantsRow.innerHTML = `
                            <span style="font-size: 14px;"><i class="fas fa-user" style="color: #303D4E;"></i></span>
                            <span style="margin-left: 5px; color: #303D4E;"><span class="fw-bolder">${numberMembers}</span> ${i18n.global.t('appointments.members')}</span>
                        `;

                        const timeRow = document.createElement('div');
                        timeRow.style.display = 'flex';
                        timeRow.style.alignItems = 'center';
                        timeRow.style.marginTop = '5px';
                        timeRow.style.flex = '1';
                        timeRow.innerHTML = `
                            <span style="color: #343a40; font-size: 14px;"><i class="fas fa-clock" style="color: #303D4E;"></i></span>
                            <span style="margin-left: 5px; color: #343a40;">${startTime} - ${endTime}</span>
                        `;

                        container.appendChild(titleRow);
                        container.appendChild(participantsRow);
                        container.appendChild(timeRow);

                        return { domNodes: [container] };
                    }
                });

                calendar.render();
            }

            return {
                init: function () {
                    exampleBasic();
                }
            };
        }();

        KTUtil.onDOMContentLoaded(function () {
            fullcalendarValue.value.init();
        });
    }

    function handleEventClick(info){
        emits('handleClick', info.event.extendedProps.id)
    }

    function handleDayCellDidMount(arg){
        const today = new Date();
        if (arg.date.getDate() === today.getDate() && arg.date.getMonth() === today.getMonth() && arg.date.getFullYear() === today.getFullYear()) {
            arg.el.style.backgroundColor = $getCssVariable('--bs-light-primary');
        }
    }
</script>

<style scoped>
    .bg_class{
        accent-color: #0072CE;
    }
    .fc-v-event {
        border: none !important;
        border: none !important;
        background-color: transparent !important;
        background-color: var(--fc-event-bg-color, transparent);
    }
</style>