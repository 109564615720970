<template>
  <div :class="[containerClass]">
    <label v-if="label" :id="`input-${name}-label`" :for="`input${name}`" :class="[labelClass || 'form-label fs-6 fw-bolder text-black', {isRequired: 'required'}]">
      {{ label }} <span v-if="isRequired" class="text-danger"> * </span>
    </label>
    <vue-tel-input required
                   :key="`input-${name}-key-${componentKey}`"
                   :validCharactersOnly="true"
                   :inputOptions="options"
                   :defaultCountry='defaultCountry'
                   :dropdownOptions="dropdownOptions"
                   @on-input="thirstInputChanged"
                   :autoDefaultCountry="true"
                   :id="`input-tel-name`"
                   :disabled="isDisabled"
                   styleClasses="form-control form-control-lg form-control-solid p-1 rounded"
                   name="input-phone"
                   v-model="telPhone">
    </vue-tel-input>
  </div>
</template>

<script setup>
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css';

import {computed, defineProps, defineEmits, ref, watch} from "vue";

const props = defineProps({
  phone: String,
  phoneCode: String,
  inputClass: String,
  name: String,
  placeholder: String,
  isRequired: Boolean,
  labelClass: String,
  containerClass: {
    type: String,
    default: 'col-12 mb-10'
  },
  label: String,
  isDisabled: Boolean,
})

const emits = defineEmits(['update:phone', "update:phoneCode"])

const options = computed(() => {
  return {
    placeholder: props.placeholder,
    showDialCode : false,
    styleClasses: 'rounded form-control-solid'
  }
})

const dropdownOptions = ref({
  showDialCodeInSelection: true,
  showDialCodeInList: true,
  showFlags: true
})

const defaultCountry = computed(() => props.phoneCode && props.phone ? Number(props.phoneCode.replace('+', '')) : 229)

const telPhone = computed(() => props.phone)
const componentKey = ref(0);

watch(() => props.phoneCode, (val) => {
  if(val) {
    defaultCountry.value = Number(props.phoneCode.replace('+', ''))
    dropdownOptions.value.showFlags = false
    componentKey.value = defaultCountry.value
  }
  dropdownOptions.value.showFlags = true
})


const thirstInputChanged = (number, phoneObject) => {
  if (phoneObject) {
    emits("update:phone", phoneObject.formatted);
    emits("update:phoneCode", '+' + phoneObject.countryCallingCode);
  }
}

</script>

<style scoped>
input.invalid {
  border: 1px solid red;
}

div.vti__dropdown {
  padding: 0 !important;
}

</style>
