<script setup>
import i18n from "@/plugins/i18n";
import {defineEmits, defineProps, nextTick, onMounted, ref, watch} from "vue";
import {useLocales} from "@/composables/locales";
const {locale} = useLocales()

const props =defineProps({
  id: { type: String, default: 'date-range-picker' }
})

const emits = defineEmits(['update:date'])

const range = ref({
  start_date: '',
  end_date: ''
})

watch(() => locale.value, (value) => {
  nextTick(() => {
    moment.locale(locale.value);
    initDateRange();
  })
}, { deep: true })

function initDateRange() {
  let element = document.querySelector('#' + props.id);
  let start = moment().subtract(29, 'days');
  let end = moment();
 
  range.value.start_date = start.format('DD-MM-YYYY')
  range.value.end_date = end.format('DD-MM-YYYY')

  emits('update:date', range.value)

  $(element).daterangepicker({
    startDate: start,
    endDate: end,
    opens: 'left',
    applyClass: 'btn-primary',
    cancelClass: 'btn-light-primary',
    locale: {
      format: 'DD/MM/YYYY',
      separator: ' - ',
      applyLabel: i18n.global.t('apply'),
      cancelLabel: i18n.global.t('cancel'),
      fromLabel: i18n.global.t('from'),
      toLabel: i18n.global.t('to'),
      customRangeLabel: i18n.global.t('custom_range'),
      weekLabel: 'S',
      daysOfWeek: [i18n.global.t('di'), i18n.global.t('lu'), i18n.global.t('ma'), i18n.global.t('me'), i18n.global.t('je'), i18n.global.t('ve'), i18n.global.t('sa')],
      monthNames: [i18n.global.t('january'), i18n.global.t('february'), i18n.global.t('march'), i18n.global.t('april'), i18n.global.t('may'),
        i18n.global.t('june'), i18n.global.t('july'), i18n.global.t('august'), i18n.global.t('september'), i18n.global.t('october'), i18n.global.t('november'), i18n.global.t('december')],
      firstDay: 1
    },
    ranges: {
      [i18n.global.t('today')]: [moment(), moment()],
      [i18n.global.t('yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      [i18n.global.t('last7Days')]: [moment().subtract(6, 'days'), moment()],
      [i18n.global.t('last30Days')]: [moment().subtract(29, 'days'), moment()],
      [i18n.global.t('thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
      [i18n.global.t('lastMonth')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

    }
  }, cb);

  cb(start, end);
}

function cb(start, end) {
  let element = document.querySelector('#' + props.id);
  let display = element.querySelector('div');
  
  if (display) {
    moment.locale(locale.value);
    display.innerHTML = start.format('D MMM YYYY') + ' - ' + end.format('D MMM YYYY');
  }

  range.value.start_date = start.format('DD-MM-YYYY')
  range.value.end_date = end.format('DD-MM-YYYY')

  emits('update:date', range.value)
}

onMounted(() => {
  nextTick(() => {
    moment.locale(locale.value);
    initDateRange()
  })
})

</script>

<template>
  <div :id="id" data-kt-daterangepicker-opens="left"
       class="btn btn-sm btn-withe h-35px d-flex mx-5 rounded-3 align-items-center px-4 shadow-sm">
    <i class="fas fa-calendar mx-5 text-primary ms-2 me-0"></i>
    <div class="text-primary mx-2 fw-bolder">
      {{ $t('loadingDateRange') }}...
    </div>
    <i class="fas fa-chevron-down mx-2 text-primary"></i>
  </div>
</template>

<style scoped>
</style>
