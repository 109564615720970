import * as types from './mutation-types'

export default {
  [types.LOAD_OFFICES](state, data) {
    data.forEach((society) => {
      if(window.Echo ){
        window.Echo.join(`App.Presence.${society.id}`)
            .here((users) => {
              state.companiesPresence = {...state.companiesPresence, [society.id]: users}
            })
            .joining((user) => {
              let companyPresence = state.companiesPresence[society.id] || []

              if (!companyPresence.some(cp => cp.id === user.id)) {
                companyPresence.push(user);
              }

              state.companiesPresence = {...state.companiesPresence, [society.id]: companyPresence}
            })
            .leaving((user) => {
              let companyPresence = state.companiesPresence[society.id] || []
              companyPresence = companyPresence.filter((cp) => cp.id !== user.id)
              state.companiesPresence = {...state.companiesPresence, [society.id]: companyPresence}
            })
      }
    })

    state.offices = data
  },
  [types.LOAD_SOCIETY_OFFICES](state, data) {
    state.society_offices = data
  },
  [types.LOAD_SOCIETY_EMPLOYEE](state, data) {
    state.employees = data

  },
  [types.SET_SELECTED_COMPANY](state, data) {
    state.selectedCompany = data
  },
}
