<template>
    <div :class="[containerClass]">
      <label v-if="label" :id="`input-${name}-label`" :for="`input${name}`" :class="[labelClass || 'form-label fs-6 fw-bolder', {required: 'required'}]">
        {{ label }} <span v-if="isRequired" class="text-danger"> * </span>
        </label>
      <textarea
          class="form-control form-control-solid form-control-lg"
          :class="inputClass"
          :name="name"
          :id="`textarea-${name}`"
          v-model="model"
          :placeholder="placeholder"
          :required="required"
          :rows="rows"
      />
    </div>
  </template>
  
  <script setup>  
  const model = defineModel()
  
  const props = defineProps( {
    name: String,
    placeholder: String,
    type: {type:String, default: "text"},
    rows: {type:Number, default: 6},
    required: {type:Boolean, default: true},
    inputClass: String,
    labelClass: String,
    label: String,
    disabled: Boolean,
    containerClass: {
      type: String,
      default: 'col-12 mt-2 mb-3'
    },
  })
  
  </script>
  
  <style scoped>
  </style>