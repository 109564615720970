<template>
  <div :class="[containerClass]">
    <label v-if="label" :id="`input-${name}-label`" :for="`input${name}`" :class="[labelClass || 'form-label fs-6 fw-bolder text-black', {isRequired: 'required'}]">
      {{ label }} <span v-if="isRequired" class="text-danger"> * </span>
    </label>
    <div :class="[
              IsInputGroup ? 'input-group' : ''
              ]">
      <input
          type="text"
          class="form-control tagify"
          :class="[getClasses(size), inputClass]"
          :name="id"
          :id="id"
          :value="modelValue"
          :placeholder="placeholder"
          :required="isRequired"
          :aria-label="label"
          :disabled="isDisabled"
      />
      <small :id="id+'help-block'" class="form-text text-warning"> {{ helperText }} </small>
    </div>
  </div>
</template>

<script>
import { id } from 'date-fns/locale'

export default {
  name: 'TagifyInput',
  data() {
    return {
      tagify: null
    }
  },
  props: {
    name: { type: String, required: true },
    parentId: { type: String, default: '' },
    rules: String,
    labelClass: String,
    inputClass: String,
    containerClass: {
      type: String,
      default: 'col-12 mb-10'
    },
    label: String,
    isDisabled: Boolean,
    isRequired: Boolean,
    modelValue: Array,
    iconText: String,
    IsInputGroup: Boolean,
    icon: String,
    iconDir: String,
    addonClass: String,
    clickAddon: Function,
    info: Boolean,
    infoText: String,
    placeholder: String,
    size: { type: String, default: 'md' },
    helperText: String,
    existingTags: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    id() {
      return `input-tag-${this.name}-id`
    }
  },
  mounted() {
    this.initTagify()
  },
  watch: {
    existingTags: {
      handler(newTags) {
        if (this.tagify) {
          this.tagify.settings.whitelist = newTags || [];
          this.tagify.dropdown.refilter();
        }
      },
      immediate: true
    }
  },
  methods: {
    initTagify() {
      if (!Array.isArray(this.existingTags)) {
        this.existingTags = [];
      }

      const input = document.getElementById(this.id);
      if (!input) return;

      this.tagify = new Tagify(input, {
        whitelist: this.existingTags,
        maxTags: 10,
        dropdown: {
          maxItems: 20,
          classname: "tagify__inline__suggestions",
          enabled: 0,
          closeOnSelect: false
        }
      });

      this.tagify
        .on("invalid", function (e) {
          if (e.detail.data.__isValid === "pattern mismatch") {
            e.preventDefault();
            window.toastr["error"]("variation invalid");
          }
        })
        .on("add", this.updateVal)
        .on("remove", this.updateVal);
    },
    updateVal (e) {
      const newVal = e.detail.tagify.value.map(el => el.value)
      e.preventDefault()
      this.$emit('update:modelValue', newVal)
    },
    getIcon (icon) {
      return icon ? icon : null
    },
    hasIcon (icon) {
      return icon ? "input-group" : null
    },
    getClasses (size) {
      let sizeValue;

      sizeValue = size ? `form-control-${size}` : null;

      return `${sizeValue}`;
    }
  }

}
</script>
<style scoped>
input.invalid {
  border: 1px solid red;
}
</style>
