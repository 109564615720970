import * as types from './mutation-types'

export default {
  [types.ADD_FILES](state, data) {
    state.files = data.data
  },
  [types.ADD_FILE] (state, data) {
    state.files.push(data)
  }
}
