import * as types from './mutation-types'

export default {
    [types.TYPES_SUCCESS] (state, data) {
        state.types = data
    },
    [types.EXT_SUCCESS] (state, data) {
        state.extensions = data
    },
}
