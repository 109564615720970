import * as types from './mutation-types'
import store from '@/store/index'
import {playCallSound, stopCallSound} from "@/mixins/Helpers";
import router from '@/router/index'

const notification = new Audio('/assets/media/sounds/notification.mp3')



export default {

  [types.ADD_CONVERSATIONS](state, conversations) {
      if(window.Echo){
          conversations.forEach((c) => {
              window.Echo.channel(`App.Conversation.${c.id}`)
                  .listen('.Core\\v1\\Chat\\Events\\NewMessageEvent', (e) => {
                      if(store.getters['user/currentUser']?.id !== e.message.from_id) {
                          let conversation = state.conversations[c.id] || {count: 0, messages: []}

                          let msgIndex = conversation.messages.findIndex(msg => msg?.id === e.message.id);

                if (msgIndex === -1) {

                    if(router.currentRoute.value.params.id !== conversation.id || document.hidden) {
                        conversation.unread++;
                    }

                    conversation.count++;
                    conversation.messages.push(e.message);
                    state.conversations = { ...state.conversations, [c.id]: conversation };
                } else {
                    conversation.messages[msgIndex] = e.message;
                    state.conversations = { ...state.conversations, [c.id]: conversation };
                }

                          if(router.currentRoute.value.params.id !== conversation.id || document.hidden) {
                              notification.currentTime = 0
                              notification.play()
                          }

                      }
                      state.conversations[e.message.conversation_id].last_update = e.conversation.last_update;
                  })
                  .listen('.Core\\v1\\Chat\\Events\\NewActionEvent', (e) => {
                      if(store.getters['user/currentUser']?.id !== e.action.from_id) {
                          let conversation = state.conversations[c.id] || {count: 0, actions: []}

                          if (e.action.action_type === 'REMOVE_USER' &&
                              (e.participators?.length && e.participators?.includes(store.getters['user/currentUser']?.id)))
                          {
                              window.Echo.leave(`App.Conversation.${c.id}`)
                          } else {
                              let actionIndex = conversation.actions.findIndex(action => action?.id === e.action.id);

                              if (actionIndex === -1) {
                                  conversation.count++;
                                  conversation.actions.push(e.action);
                                  state.conversations = { ...state.conversations, [c.id]: conversation };
                              } else {
                                  conversation.actions[actionIndex] = e.action;
                                  state.conversations = { ...state.conversations, [c.id]: conversation };
                              }

                              store.dispatch('chat/loadConversationUsers', c.id)
                          }
                      }
                      state.conversations[e.action.conversation_id].last_update = e.conversation.last_update;
                  })
                  .listen('.Core\\v1\\Chat\\Events\\ChatCallEvent', (e) => {
                      let conversation = state.conversations[e.call.conversation_id] || { count: 0, calls: [] };
                      let callIndex = conversation.calls.findIndex(call => call?.id === e.call.id);

                      if (store.getters['user/currentUser']?.id !== e.call.from_id) {
                          if (e.call.status === 'IN_PROGRESS' && callIndex === -1) {
                              playCallSound();
                          } else {
                              stopCallSound()
                          }
                      }

                      if (callIndex === -1) {
                        conversation.count++;
                        conversation.unread++;
                        conversation.calls.push(e.call);
                        state.conversations = { ...state.conversations, [e.call.conversation_id]: conversation };

                      } else {
                          let existingCall = conversation.calls[callIndex];
                          if (existingCall.status !== e.call.status || existingCall.updated_at !== e.call.updated_at) {
                              conversation.calls[callIndex] = e.call;
                              state.conversations = { ...state.conversations, [e.call.conversation_id]: conversation };
                          }
                      }

                      if(router.currentRoute.value.params.id !== conversation.id || document.hidden) {
                          notification.currentTime = 0
                          notification.play()
                      }
                      state.conversations[e.call.conversation_id].last_update = e.conversation.last_update;

                  })
          })
      }

    conversations.forEach((c) => {
      let conversation = state.conversations[c.id] || {messages: [], count: 0}
      conversation = {...conversation, ...c}
      state.conversations = {...state.conversations, ...{[c.id]: conversation}}
    })
  },

  [types.ADD_CONVERSATION](state, conversation) {
      if(!conversation.calls) {
          conversation.calls = []
      }
      if(!conversation.actions) {
          conversation.actions = []
      }

    state.conversations = {...{[conversation.id]: conversation}, ...state.conversations}
  },

  [types.ADD_MESSAGES](state, { messages = [], calls= [], actions= [], id, count }) {
    let conversation = state.conversations[id] || {}
    messages.length && (conversation.messages = messages)
    calls.length && (conversation.calls = calls)
    actions.length && (conversation.actions = actions)
    conversation.count = count
    conversation.unread = 0
    conversation.loaded = true
    state.conversations = {...state.conversations, ...{[id]: conversation}}
  },

  [types.ADD_MESSAGE](state, { message = '', call = '', action = '', id }) {
    let conversation = state.conversations[id] || {count: 0, messages: []}
    conversation.count++

    if(message) {
        let messages = conversation.messages || []
        messages.push(message)
        conversation.messages = messages
    }

    if(call) {
        let calls = conversation.calls || []
        calls.push(call)
        conversation.calls = calls
    }

      if(action) {
          let actions = conversation.actions || []
          actions.push(action)
          conversation.actions = actions
      }

    state.conversations = {...state.conversations, ...{[id]: conversation}}

  },

  [types.ADD_USERS](state, users) {
    state.users = users
  },

  [types.UPDATE_MESSAGE](state, { id, message = '', call = '', action= '' }) {
    let conversation = state.conversations[id];

    if (!conversation) return;

    if (message) {
      const messageIndex = conversation.messages.findIndex(msg => msg.id === message?.id);
      if (messageIndex !== -1) {
        conversation.messages[messageIndex] = { ...conversation.messages[messageIndex], ...message };
      }
    }

    if (call) {
      const callIndex = conversation.calls.findIndex(call => call.id === call?.id);
      if (callIndex !== -1) {
        conversation.calls[callIndex] = { ...conversation.calls[callIndex], ...call };
      }
    }

      if (action) {
          const actionIndex = conversation.actions.findIndex(action => action.id === action?.id);
          if (actionIndex !== -1) {
              conversation.actions[actionIndex] = { ...conversation.actions[actionIndex], ...action };
          }
      }

    state.conversations = { ...state.conversations, [id]: conversation };
  },

  [types.LOAD_CONVERSATION_USERS](state, users) {
    state.currentConversationUser = users
  },

  [types.CHAT_RESET](state)  {
      state.conversations = {}
      state.currentConversationUser = []
      state.users = []
  },

  [types.LOAD_CHAT_MODULE](state, data) {
      state.modules = data
  },


}
