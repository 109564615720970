import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";
import { computed } from "vue";

const projectGroup = ['projects-index', 'projects-view', 'tasks-view']
const reportGroup =  ['reports-index']
const performanceGroup =  ['performance-index']

export default {
    menus:  computed(() => ({
        sectionTitle: i18n.global.t('menu.projects.title'),
        module : [moduleAbilities.PROJECT, moduleAbilities.NOTARIAL_REPERTORY, moduleAbilities.NOTARIAL_REPORT],
        permission: [abilities.VIEW_PROJECT],
        children: [
            {
                title: i18n.global.t('menu.projects.title'),
                icon: 'fas fa-puzzle-piece icon-right2',
                route: 'projects-index',
                group: projectGroup,
                id: 'step-projects',
                permission: [abilities.VIEW_PROJECT],
                children: [],
                module: [moduleAbilities.PROJECT, moduleAbilities.NOTARIAL_REPERTORY],
            },
            {
                title: i18n.global.t('menu.processing.reports'),
                icon: 'fas fa-file icon-right2',
                route: 'reports-index',
                group: reportGroup,
                id: 'step-reports',
                permission: [abilities.VIEW_PROJECT],
                children: [],
                module: [moduleAbilities.NOTARIAL_REPORT],
            },
            {
                title: i18n.global.t('menu.processing.performance'),
                icon: 'fas fa-tachometer-alt icon-right2',
                route: 'performance-index',
                group: performanceGroup,
                id: 'step-reports',
                permission: [abilities.VIEW_PROJECT],
                children: [],
                module: [moduleAbilities.NOTARIAL_REPORT],
            }
        ]
    }))

}
