<template>
  <router-link :to="$can(abilities.VIEW_CONTACT) ? { name: 'contact-view-path', params: { id: contact.id } } : ''"  :key="contact.id" class="col-12 col-sm-12 col-md-3 col-xxl-2">
    <div class="card h-250px contact-card">
      <div class="card-body d-flex flex-center flex-column p-4">
        <div class="cursor-pointer symbol symbol-30px symbol-md-40px mb-6" v-if="contact.avatar">
          <img class="img-size rounded-circle" :alt="contact.lastname" :src="contact.avatar_url" />
          <div class="icon-overlay" v-if="contact.contactType == 'particular'" style="background-color: var(--bs-warning)">
              <i class="fas fa-user" style="color: white;"></i>
          </div>
          <div v-if="contact.contactType == 'society'" class="icon-overlay" style="background-color: #F3DC1F">
              <i class="fas fa-building" style="color: #000;"></i>
          </div>
        </div>
        
        <div class="cursor-pointer symbol symbol-30px symbol-md-40px mb-6" v-else>
            <span class="symbol-label fs-2x fw-bold text-primary bg-light-primary img-size rounded-circle">
              {{ contact.lastname ? contact.lastname.charAt(0).toUpperCase() : "" }}
               {{ contact.firstname ? contact.firstname.charAt(0).toUpperCase() : "" }}
            </span>
            <div class="icon-overlay" v-if="contact.contactType == 'particular'" style="background-color: var(--bs-warning)">
                <i class="fas fa-user" style="color: white;"></i>
            </div>
            <div v-if="contact.contactType == 'society'" class="icon-overlay" style="background-color: #F3DC1F">
                <i class="fas fa-building" style="color: #fff;"></i>
            </div>
        </div>
        <a href="#" class="fs-4 text-gray-800 text-hover-primary text-center fw-bolder mb-5">
          {{ contact.fullname }}
        </a>
        <div class="d-flex">
          <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_user">
            <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
              <i class="fas fa-user"></i>
            </span>
          </div>
          <div class="bg-gray-100 rounded w-150px py-3 px-3 mx-2 mb-3 phone-value">
            <div class="fw-bold text-gray-900" v-if="contact.customer || contact.supplier">
              <span v-if="contact.customer && contact.supplier">
                {{ $t('contacts.customer') }} & {{ $t('contacts.supplier') }}
              </span>
              <span v-else-if="contact.customer"> {{ $t('contacts.customer') }}</span>
              <span v-else-if="contact.supplier"> {{$t('contacts.supplier') }} </span>
            </div>
            <div class="fw-bold text-gray-900" v-else>
              <span>--------------</span>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_email">
            <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
              <i class="fas fa-envelope"></i>
            </span>
          </div>
          <div class="bg-gray-100 rounded w-150px py-3 px-3 mx-2 mb-3 phone-value">
            <div class="fw-bold text-gray-900" v-if="contact.email">
              {{ contact.email ? formatEmail(contact.email) : ""}}
            </div>
            <div class="fw-bold text-gray-900" v-else>
              <span>--------------</span>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="rounded py-2 px-3 me-2 mb-3 phone-card img_phone">
            <span class="svg-icon text-gray-900 svg-icon-muted svg-icon-0hx">
              <i class="fas fa-phone-alt"></i>
            </span>
          </div>
          <div class="bg-gray-100 rounded w-150px py-3 px-3 mx-2 mb-3 phone-value">
            <div class="fw-bold text-gray-900" v-if="contact.phone && JSON.parse(contact.phone)[0]">
              {{ contact && contact.phone && JSON.parse(contact.phone)[0] ?
                JSON.parse(contact.phone)[0].toString() : "" }}
            </div>
            <div class="fw-bold text-gray-900" v-else>
              <span>--------------</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </router-link>

</template>

<script setup>
import abilities from "@/composables/abilities";

const props = defineProps({
  contact: {
    type: Object,
    default() {
      return {
        id: 1,
        lastname: "test",
        firstname: "tttt",
        contactType: "particulier",
        fullname:"test tttt",
        avatar_url: "",
        email:"test@mail.com",
        phone: ["1234567"],
      }
    }
  }
})

function formatEmail(email) {
  return email.length > 15 ? email.slice(0, 15) + "..." : email;
}formatEmail
</script>
<style scoped>

.phone-card {
  background-color: #fff !important;
  color: #646E7B !important;
  border-radius: 10px;
  border: 1px solid #646E7B;
  width: 30px;
  height: 30px;
}

.phone-value {
  color: #222222 !important;
  background-color: #F2F3F4 !important;
}

.img-size {
  width: 110px !important;
  height: 110px !important;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.contact-card {
  height: 330px !important;
  border-radius: 10px;
}

.contact-card:hover {
  border: 2px solid var(--bs-primary);
  border-radius: 10px;
}

.contact-card:hover .img_user{
  background-color: #fff !important;
  color: var(--bs-primary) !important;
  border-radius: 10px;
  border: 1px solid var(--bs-primary);
  width: 30px;
  height: 30px;
}

.contact-card:hover .img_user i{
  color: var(--bs-primary) !important;
}

.contact-card:hover .img_email{
  background-color: #fff !important;
  color: var(--bs-warning) !important;
  border-radius: 10px;
  border: 1px solid var(--bs-warning);
  width: 30px;
  height: 30px;
}

.contact-card:hover .img_email i{
  color: var(--bs-warning) !important;
}

.contact-card:hover .img_phone{
  background-color: #fff !important;
  color: var(--bs-success) !important;
  border-radius: 10px;
  border: 1px solid var(--bs-success);
  width: 30px;
  height: 30px;
}

.contact-card:hover .img_phone i{
  color: var(--bs-success) !important;
}

.icon-overlay {
    position: absolute;
    bottom: -10px !important;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    border-radius: 50%;
    padding: 8px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
