import * as types from './mutation-types'

export default {
  [types.ADD_PROJECT_STEPS](state, data) {
    state.steps = data.data
  },
  [types.ADD_PROJECT_STEPS_WITH_TASKS](state, data) {
    state.step_with_tasks = data.data
  },
  [types.ADD_PROJECT_STEP] (state, data) {
    state.step_with_tasks.push(data)
  },
  [types.UPDATE_PROJECT_STEP] (state, data) {
    const index = state.step_with_tasks.findIndex(step => step.id === data.id);
    if (index !== -1) {
        state.step_with_tasks[index] = { ...state.step_with_tasks[index], ...data };
    }
  },
  [types.DESTROY_PROJECT_STEP] (state, id) {
    state.step_with_tasks = state.step_with_tasks.filter(step => step.id !== id);
  },
}
