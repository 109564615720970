import * as types from './mutation-types'
import {ADD_NORMALIZATION} from "./mutation-types";

export const loadNormalization = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/normalizedKey', data).then((response) => {

            commit(types.ADD_NORMALIZATION, response.data)


        }).catch((err) => {
            reject(err)
        })
    })
}
export const storeNormalization = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        
        window.axios.post('store/normalizedKey', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}