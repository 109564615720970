export const RESET_CURRENT_USER = 'RESET_CURRENT_USER'
export const BOOTSTRAP_CURRENT_USER = 'BOOTSTRAP_CURRENT_USER'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const ADD_USER = 'ADD_USER'
export const ADD_USERS = 'ADD_USERS'
export const COMPANY_INFO = 'COMPANY_INFO'

export const ADD_FAMILY_STATUS = 'ADD_FAMILY_STATUS'
export const STORE_FAMILY_STATUS = 'STORE_FAMILY_STATUS'
export const ADD_POST_CATEGORIES = 'ADD_POST_CATEGORIES'
export const STORE_POST_CATEGORIES = 'STORE_POST_CATEGORIES'