import * as types from './mutation-types'

export const getRecurrentExpenseById = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/${id}/expense/recurrent`).then((response) => {
            commit(types.SET_CURRENT_RECURRENT_EXPENSE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createRecurrentExpense = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`store/${data.office_id}/expense/recurrent`, data.data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateRecurrentExpense = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`update/${data.id}/expense/recurrent`, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const disableRecurrentExpense = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`disable/${id}/expense/recurrent`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const enableRecurrentExpense = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`active/${id}/expense/recurrent`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}






