<template>
  <div class="form-check form-switch form-check-custom form-check-solid" :class="{' d-flex flex-row-reverse justify-content-between align-items-center mb-0': between, ' mb-5': !between}">
      <input class="form-check-input" type="checkbox" 
        v-model="modelValue"
        :disabled="disabled" 
        :id="id"/>
      <label :for="id" :class="['form-check-label fs-6 fw-bolder text-black', {isRequired: 'required'}]">
        {{ label }} <span v-if="isRequired" class="text-danger"> * </span>
      </label>
  </div>
</template>

<script setup>

import { defineProps, defineModel } from 'vue';

const modelValue = defineModel({ type: Boolean, required: true });
const props = defineProps({
  label: String,
  disabled: Boolean,
  isRequired: Boolean,
  id: String,
  between: {type:Boolean, default: false},
  
});


</script>
<style scoped>
input.invalid {
  border: 1px solid red;
}
</style>
