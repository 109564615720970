import * as types from './mutation-types'
import {createFormData} from "@/mixins/Helpers";

export const loadProjectCategories = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/projects/categories/collection').then((response) => {
            commit(types.LOAD_PROJECT_CATEGORIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadCurrentProjectCategories = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`show/${id}/project/category`).then((response) => {
            commit(types.LOAD_PROJECT_CATEGORY, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeProjectCategory = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/project/category', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateProjectCategory = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/project/category', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteProjectCategory = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+id+'/project/category').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const loadCategoryGroups = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/projects/category/groups/collection').then((response) => {
            commit(types.LOAD_PROJECT_CATEGORIES_GROUP, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadCurrentCategoryGroup = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/${id}/projects/category/groups'`).then((response) => {
            commit(types.LOAD_PROJECT_CATEGORY, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeCategoryGroup = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/projects/category/groups', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateCategoryGroup  = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/projects/category/groups', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteCategoryGroup = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+id+'/projects/category/groups').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


