<template>
    <div class="card-header card-header-stretch min-h">   
        <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x nav-stretch fs-6 border-0">
            <li class="nav-item" @click="setTag('')">
                <a class="nav-link" :class="{' active': isActive('')}"  >{{$t("projects.allCategories")}}</a>
            </li>
            <li v-for="group in categoryGroups" :key="group?.id" class="nav-item"  @click="setTag(group?.id)">
                <a class="nav-link" :class="{' active': isActive(group?.id)}">{{group?.name}}</a>
            </li>
        </ul>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';


const model = defineModel()
const store = useStore()

const isActive = (id) => model.value === id
const categoryGroups = computed(() => store.getters['projectCategories/getProjectCategoryGroups']) 

onMounted(() => {
  store.dispatch("projectCategories/loadCategoryGroups")
})

function setTag(id){
    model.value = id
}
</script>
<style scoped>
.min-h{
  min-height: 50px !important;
}

</style>