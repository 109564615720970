import * as types from './mutation-types'

export const getFieldTypes = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('enum/field/types').then((response) => {
            commit(types.TYPES_SUCCESS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getNpsList = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/nps/collection').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
} 

export const createNps = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`store/nps`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getNps = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get(`get/${id}/nps`)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updateNps = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .patch(`update/${data.id}/nps`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const deleteNps = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`delete/${data.id}/nps`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const sendNpsInvitation = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`send/nps/invitation`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getClienNps = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get(`get/client/${data.id}/nps?checkValidity=${data.checkValidity}&invitation=${data.invitation}`)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const getNpsResponses = ({ commit, dispatch, state }, nps) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get(`get/${nps}/nps/responses/collection`)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const saveNpsResponse = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`save/nps/response`, data) 
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const verifyNpsResponse = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`verify/nps/response`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getNpsResponse = ({ commit, dispatch, state }, response) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get(`get/${response}/nps/response`)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getNpsStatistics = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios 
            .get(`get/${id}/nps/statistics`)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export const getNpsTotalResponse = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get(`get/${id}/nps/response/total`)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getNpsTotalResponseAverage = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios
            .get(`get/${id}/nps/response/average`)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
