<template>
    <div :class="[containerClass, hasIcon(icon)]">
      <label :id="`date-input-${name}-label`" :for="`date-input-${name}`" :class="[labelClass || 'form-label fs-6 fw-bolder text-black', {isRequired: 'required'}]">
        {{ label }} <span v-if="isRequired" class="text-danger"> * </span>
      </label>
      <div  :class="IsInputGroup ? 'input-group' : ''">
          <span v-show="iconDir === 'left'" :id="`date-input-${name}-addon`" class="input-group-text" :class="addonClass">
          {{ iconText }}
            <i :class="getIcon(icon)"></i>
          </span>
          <VueDatePicker 
              v-model="value"
              :enable-time-picker="enableTime" 
              :id="`date-input-${name}`" 
              :placeholder="placeholder"
              :readonly="isDisabled"
              :rules="rules"
              :model-type="dateFormat || 'dd-MM-yyyy'"
              :required="isRequired"
              :time-picker="noCalendar"
              :clearable="false"
              :hide-input-icon="iconDir"
              :ui="{ input: inputClass + ' pt-3 pb-4' }"
              :format="convertFormatDate"
              :locale="locale"
              :cancel-text="$t('button.cancel')"
              :select-text="$t('button.select')"
              :auto-apply="enableTime ? false : true"

          />
          <span v-show="iconDir === 'right'" :id="`date-input-${name}-addon`" class="input-group-text" :class="addonClass">
            {{ iconText }}
              <i :class="getIcon(icon)"></i>
          </span>
      </div>
    </div>
  <!--              :range="{ noDisabledRange: range }"-->

  </template>

  <script setup>
  import { useField } from 'vee-validate';
  import {useLocales} from "@/composables/locales";
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  import {ref, computed} from 'vue'

  const {locale} = useLocales()
  const props = defineProps( {
    size: {
      type: String,
      default: "lg",
    },
    parentId: { type: String, default: '' },
    icon: String,
    iconDir: String,
    name: String,
    id: String,
    modelValue: String,
    placeholder: String,
    isRequired: {type: Boolean, default: true},
    inputClass: {type:String, default: "form-control form-control-lg form-control-solid"},
    labelClass: {type:String, default: "form-label"},
    containerClass: {
      type: String,
      default: 'col-12 mt-2 mb-5'
    },
    label: String,
    isDisabled: Boolean,
    rules: String,
    iconText: String,
    IsInputGroup: Boolean,
    addonClass: String,
    disabled: Array,
    dateFormat: String,
    enableTime: {type: Boolean, default: false},
    noCalendar: {type: Boolean, default: false},
    minDate: String,
    maxDate: String,
  })

/*
  range: {type: Boolean, default: false}
*/

  const { handleChange, value, handleBlur, errorMessage, meta } = useField(() => props.name, undefined, {
    syncVModel: true,
  });

  const convertFormatDate = computed(() => {
    const dateFormat = locale.value === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    return props.enableTime ? `${dateFormat} HH:mm` : dateFormat;
  })

  function getClasses (size) {
    let sizeValue, isValidValue;

    sizeValue = size ? `form-control-${size}` : null;

    isValidValue = meta.touched && !meta.valid ? `invalid` : "valid";

    return `${sizeValue} ${isValidValue}`;
  }

  function getIcon (icon) {
    return icon ? icon : null
  }
  
  function hasIcon (icon) {
    return icon ? "input-group" : null
  }

  </script>
  <style scoped>
  @import "@eonasdan/tempus-dominus/dist/css/tempus-dominus.min.css";

  input.invalid {
    border: 1px solid red;
  }
  </style>
