import * as types from './mutation-types'

export default {
  [types.LOAD_MAILS](state, data) {
    state.mails = data
  },
  [types.DESTROY_MAIL](state, ids) {
    state.mails.data = state.mails.data.filter(item => !ids.includes(item.id));
  },
  [types.LOAD_USER_CONTACTS](state, data) {
    state.user_contacts = data
  },
  [types.LOAD_PRIORITIES](state, data) {
    state.priorities = data.data
  }
}
