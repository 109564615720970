import * as types from './mutation-types'
import {LOAD_USER_EXPENSES} from "./mutation-types";

export const loadAccountingDashboardData = ({ commit, dispatch, state }, {search, start_date, end_date}) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`accounting/expenses/dashboard?search=${search}&date_start=${start_date}&date_end=${end_date}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAccountingOfficeUsersExpensesStats = ({ commit, dispatch, state }, {office,search, start_date, end_date}) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`accounting/office/${office}/users/expenses/statistics`, {
            params:{
                search: search,
                date_start: start_date,
                date_end: end_date
            }
        }).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadSocietyOfficesWithCount = ({ commit, dispatch, state }, company) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/society/${company}/offices?withUnvalidatedExpenseCount=${true}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadOfficeExpenseStats = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`accounting/offices/expenses/statistics?expenseStatus=${data.status}&search=${data.search}&officeSelected=${data.office}&date_start=${data.start_date}&date_end=${data.end_date}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadOfficeExpenseList = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`accounting/offices/expenses/scrolling?page=${data.page}&expenseStatus=${data.status}&search=${data.search}&officeSelected=${data.office}&date_start=${data.start_date}&date_end=${data.end_date}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
} 

export const loadUserExpenseStats = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`accounting/users/expenses/statistics?user_id=${data.user_id}&expenseStatus=${data.status}&typeSelected=${data.type}&search=${data.search}&office_id=${data.office}&date_start=${data.start_date}&date_end=${data.end_date}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadUserExpenseList = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`accounting/users/expenses/scrolling?page=${data.page}&user_id=${data.user_id}&expenseStatus=${data.status}&typeSelected=${data.type}&search=${data.search}&office_id=${data.office}&date_start=${data.start_date}&date_end=${data.end_date}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const expenseFileDownloadRequest = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`accounting/expense/download/request`, data, { responseType: 'blob' }).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadExpenses = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/expenses/collection').then((response) => {
            commit(types.LOAD_EXPENSES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadUserExpenses = ({commit}, filter) => {
    return new Promise((resolve, reject) => {
        window.axios.get('expenses/users/scrolling'+filter).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadUserExpensesStatistic = ({commit}, filter) => {
    return new Promise((resolve, reject) => {
        window.axios.get('expenses/users/statistics'+filter).then((response) => {
            resolve(response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const getExpensesById = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/${id}/expense`).then((response) => {
            commit(types.SET_CURRENT_EXPENSE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createExpense = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/expense', data).then((response) => {
            resolve(response)
            commit(types.ADD_EXPENSE, response.data.expense)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateExpense = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('expense/' + data.id + '/update', data.data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteExpense = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/expense').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const sendExpense = ({commit}, expenseId) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`expense/${expenseId}/send`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const approveExpense = ({commit}, expenseId) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`approve/expenses`, {"expenses":[expenseId]}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const validateExpense = ({commit}, expenseId) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`expense/${expenseId}/validate`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const rejectExpense = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`expense/${data.id}/reject`, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const payExpense = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`expense/pay`, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const loadExpenseStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('expense/status/enum').then((response) => {
            commit(types.LOAD_EXPENSE_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadExpenseTypes = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('types').then((response) => {
            commit(types.LOAD_EXPENSE_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const loadExpenseTypesEnum = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('expense/type/enum').then((response) => {
            commit(types.LOAD_EXPENSE_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}