<template>
  <div class="d-flex flex-center flex-column flex-column-fluid">
    <div class="w-lg-500px p-10 p-lg-15 mx-auto">
      <form id="kt_sign_in_form" class="form w-100" novalidate="novalidate"
            @submit.prevent="validateBeforeSubmit">
        <div class="text-center mb-10">
          <h1 class="text-dark mb-3 mt-10">{{ $t('auth.login') }}</h1>
        </div>
        <div class="fv-row mb-10">
          <label class="form-label fs-6 fw-bolder text-color required  ">{{ $t('auth.email') }}</label>
          <input v-model="loginData.email" autocomplete="off"
                 class="form-control form-control-lg form-control-solid" name="email" type="text"/>
        </div>
        <div class="fv-row mb-10">
          <div class="d-flex flex-stack mb-2">
            <label
                class="form-label fw-bolder fs-6 mb-0 text-color required">{{ $t('auth.password') }}</label>
          </div>
          <div class="password-input mb-5">
            <input v-model="loginData.password" autocomplete="off" class="form-control form-control-lg form-control-solid" :type="inputType"/>
            <span class="toggle-password" @click="togglePasswordVisibility">
              <i :class="eyeIconClass"></i>
            </span>
          </div>
          <div class="d-flex flex-stack mb-2">
            <label
                class="form-label fw-bolder fs-6 mb-0 text-color" >
            </label>
            <router-link :to="{name: 'forgot-password'}" class="link-primary fs-6 fw-bolder">
              {{ $t('auth.forgot_password') }}
            </router-link>
          </div>
        </div>
        <div class="text-center mb-5">
          <button id="kt_sign_in_submit" :disabled="isLoading"
                  class="btn btn-lg btn-primary w-100 mb-5"
                  type="submit">
            <span class="indicator-label">{{ $t('auth.login') }}</span>
            <span class="indicator-progress">{{ $t('loading') }}
                                   <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>

import {mapActions} from "vuex";

export default {
  name: 'ConnexionView',
  data() {
    return {
      loginData: {
        email: '',
        password: ''
      },
      isLoading: false,
      showPassword: false,

    }
  },
  computed : {
      inputType() {
        return this.showPassword ? 'text' : 'password';
      },
      eyeIconClass() {
        return this.showPassword ? 'fas fa-eye-slash' : 'fas fa-eye';
      },
  },
  methods: {

    ...mapActions('auth', [
      'login'
    ]),

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    changeLang(lang){
      this.$i18n.locale = lang.code
      localStorage.setItem('locale', lang.code)
    },

    async validateBeforeSubmit() {
      this.$loading('kt_sign_in_submit')
      this.isLoading = true
      this.login(this.loginData).then((res) => {
        window.toastr['success'](i18n.global.t('notification.logged'))
        this.$router.push({ name: 'home-path' })

        this.$stopLoading('kt_sign_in_submit')
        this.isLoading = false
      }).catch(() => {

        this.$stopLoading('kt_sign_in_submit')
        this.isLoading = false 
      })
    },

    registerLink() {
      return '//' + process.env.VUE_APP_HOST + (process.env.VUE_APP_PORT ? ':' + process.env.VUE_APP_PORT : '') + '/create_account'
    },
  },
}
</script>
<style scoped>
  .text-color{
    color: rgba(16, 16, 16, 0.50);
  }
  .password-input {
    position: relative;
  }

  .toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  #eye-icon {
    font-size: 1.2em;
  }
</style>
