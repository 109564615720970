<template>
 <div class="form-check form-check-custom form-check-solid " :class="{' mb-5': bottom}">
  <input class="form-check-input" type="checkbox" 
        v-model="modelValue"
        :disabled="disabled" 
        :id="id"/>
      <label :for="id" :class="[labelClass || 'form-check-label fs-6 fw-bolder text-black', {isRequired: 'required'}]">
        <span class="d-flex flex-column">
          <span>{{ label }} <span v-if="isRequired" class="text-danger"> * </span></span>
          <small v-if="desc" class="text-sm fw-light" v-html="desc"></small>
        </span>
      </label>
  </div>
</template>

<script setup>

import { defineProps, defineModel } from 'vue';

const modelValue = defineModel({ type: Boolean, required: true });
const props = defineProps({
  label: String,
  disabled: Boolean,
  isRequired: Boolean,
  id: String,
  bottom: {type: Boolean, default: true},
  desc: {type: String, default: ''}
});


</script>
<style scoped>
input.invalid {
  border: 1px solid red;
}
</style>