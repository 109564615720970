<template>
  <div :class="[containerClass, hasIcon(icon)]">
    <label v-if="label" :id="`input-${name}-label`" :for="`input${name}`" :class="[labelClass || 'form-label fs-6 ', {isRequired: 'required'}]">
      {{ label }} <span v-if="isRequired" class="text-danger"> * </span>
    </label>
    <div  :class="IsInputGroup ? 'input-group' : ''">
       <span v-show="iconDir === 'left'" :id="`date-input-${name}-addon`" class="input-group-text" :class="addonClass">
       {{ iconText }}
        <i :class="getIcon(icon)"></i>
       </span>
      <input
          class="form-control"
          :class="[{ 'invalid': (meta.touched && !meta.valid) }, getClasses(size), inputClass]"
          :name="name"
          :id="`date-input-${name}`"
          v-model="value"
          :placeholder="placeholder"
          :required="isRequired"
          :aria-label="label"
          :aria-describedby="`input-${name}-addon`"
          :disabled="isDisabled"
          :rules="rules"
      />
      <span v-show="iconDir === 'right'" :id="`date-input-${name}-addon`" class="input-group-text" :class="addonClass">
         {{ iconText }}
          <i :class="getIcon(icon)"></i>
      </span>
    </div>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { nextTick, onMounted, ref, watch} from "vue";
const props = defineProps( {
  size: {
    type: String,
    default: "default",
  },
  parentId: { type: String, default: '' },
  icon: String,
  iconDir: String,
  name: String,
  id: String,
  modelValue: String,
  placeholder: String,
  isRequired: Boolean,
  inputClass: {type:String, default: "form-control form-control-solid"},
  labelClass: String,
  containerClass: {
    type: String,
    default: 'col-12 mb-10'
  },
  label: String,
  isDisabled: Boolean,
  rules: String,
  iconText: String,
  IsInputGroup: Boolean,
  addonClass: String,
  disabled: Array,
  dateFormat: String,
  minDate: String,
  maxDate: String,
  showLabel: { type : Boolean, default: true}
})

const datepicker = ref('')
const { handleChange, value, handleBlur, errorMessage, meta } = useField(() => props.name, undefined, {
  syncVModel: true,
});

watch(() => props.modelValue, (value) => {
  if(value) {
    nextTick(() => {
      //initDataPicker()
    })
  }
})

watch(() => [props.minDate, props.maxDate], (value) => {
  if(value) {
    nextTick(() => {
      initDataPicker()
    })
  }
})

function getClasses (size) {
  let sizeValue, isValidValue;

  sizeValue = size ? `form-control-${size}` : null;

  isValidValue = meta.touched && !meta.valid ? `invalid` : "valid";

  return `${sizeValue} ${isValidValue}`;
}
function getIcon (icon) {
  return icon ? icon : null
}
function hasIcon (icon) {
  return icon ? "input-group" : null
}

function initDataPicker() {
  if(datepicker.value){
    datepicker.value.destroy()
  }

  datepicker.value = $("#date-input-" + props.name).flatpickr({
    onReady: function () {
      const initDate = props.modelValue ? new Date(props.modelValue) : new Date()
      this.jumpToDate(initDate)
    },
    dateFormat: props.dateFormat || "d-m-Y",
    disable: props.disabled || [],
    minDate: props.minDate || '',
    maxDate: props.maxDate || '',
  })
}

onMounted(() => {
  initDataPicker()
})

</script>
<style scoped>
input.invalid {
  border: 1px solid red;
}
</style>
