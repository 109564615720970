import * as types from './mutation-types'

export default {
  [types.LOAD_FEEDS](state, data) {
    state.feeds = data.data
    state.feedsCount = data.count
  },
  [types.ADD_FEED](state, data) {
    state.feeds.push(data)
    state.feedsCount = data.count + 1
  },
  [types.LOAD_FEED_COMMENTS](state, data) {
    state.comments = data.Comments
  },
  [types.ADD_FEED_COMMENT](state, data) {
    state.comments.push(data.Comment)
  },
  [types.DELETE_FEED](state, id) {
    state.feeds = state.feeds.filter(item => item.id !== id);
    state.feedsCount = data.count - 1
  },
  [types.LOAD_FEED_REACTIONS](state, data) {
    state.reactions = data
  },
  [types.LOAD_NEXT_FEEDS] (state, data) {
    state.feeds = [...state.feeds, ...data.data]
  },
}
