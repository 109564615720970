import * as types from './mutation-types'
const notificationSound = new Audio('/assets/media/sounds/notification.mp3')
export const loadNotifications = ({ commit, dispatch, state } , page) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`get/notifications?page=${page}`).then((response) => {
      commit(types.LOAD_DATA, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}


export const openedNotifications = ({ commit, dispatch, state },id) => {
    return new Promise((resolve, reject) => {
      window.axios.post(`set/notification/count`).then((response) => {
        commit(types.SET_NOTIFICATION_COUNT)
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
}

export const readNotification = ({ commit, dispatch, state },id) => {
    return new Promise((resolve, reject) => {
      window.axios.post(`read/${id}/notification`).then((response) => {
        commit(types.SET_DATA, response.data.notification)
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
}
export const listenNewNotification = ({ commit, dispatch, state }) => {

  const notify = (e)=> {
    let notification = e.notification
    let notificationAlreadyAdd = state.notifications.find(n => n.id === notification.id)
    if(!notificationAlreadyAdd){
      notification.data_read = false
      commit(types.ADD_TO_NOTIFICATION, notification)
      notificationSound?.play()
    }
  }
  if(window.Echo){
    window.Echo.channel(`App.Notification`)
        .listen('.Core\\v1\\Notification\\Event\\NewNotificationEvent', (e) => {
          if(
              ( !e.other || (e.other && e.other.id !== e.notification?.user.id)) &&
              e.notification?.usersData?.map(elt => elt.user_id).includes(rootState.user.currentUser.id)
          ){
            notify(e)
          }
        })
    ;
  }
}












