import * as types from './mutation-types'

export const loadFolders = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/folders', data).then((response) => {
            commit(types.LOAD_FOLDERS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createFolder = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('create/folder', data).then((response) => {
            commit(types.ADD_FOLDER, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteFolder = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('delete/folder', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}