import * as types from './mutation-types'

export default {
  [types.LOAD_WEEKDAYS] (state, data) {
    state.weekdays = data
  },

  [types.LOAD_COMPANY_WORKDAYS] (state, {id, workDays}) {
    state.companiesWorkDays[id] = workDays
  },
  [types.ADD_WEEKDAY_ENUM] (state, data) {
    state.weekday_enums = data
  },
}
