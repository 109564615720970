import allowances from "./modules/allowances";
import others from "./modules/others";
import pensions from "./modules/pensions";
import payrollTaxes from "./modules/payrollTaxes";
import holydays from "./modules/holydays"
import absences from "./modules/absences/index";
import absence_types from "./modules/absence_types/index";
import contracts from "./modules/contracts";
import payslip from "./modules/payslip";

export default {
  modules: {
    absences,
    absence_types,
    holydays,
    pensions,
    allowances,
    others,
    payrollTaxes,
    contracts,
    payslip
  }
}
