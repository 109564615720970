import * as types from './mutation-types'
import {ADD_TASK_PRIORITIE} from "./mutation-types";

export const loadProjectSteps = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('project/'+id+'/category/step/collection').then((response) => {
            commit(types.ADD_PROJECT_STEPS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProjectStepsWithTaskCount = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('project/'+id+'/category/step/with/taskCount').then((response) => {
            commit(types.ADD_PROJECT_STEPS_WITH_TASKS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateProjectStep = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('update/project/category/step/' + data.id, data).then((response) => {
            commit(types.UPDATE_PROJECT_STEP, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createProjectStep = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/project/' + data.project_id + '/category/step', data).then((response) => {
            commit(types.ADD_PROJECT_STEP, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteProjectStep = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('delete/project/category/step/' + id).then((response) => {
            commit(types.DESTROY_PROJECT_STEP, id)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changePositionStep = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('switch/project/category/step/'+data.id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

