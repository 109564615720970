import * as types from './mutation-types'
import {ADD_PENSION} from "./mutation-types";

export const loadPensionTypeEnum = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('payslip/pension/type/enum', data).then((response) => {
            commit(types.ADD_PENSION_TYPE_ENUM, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPensions = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/pension/collection', data).then((response) => {
            commit(types.ADD_PENSIONS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updatePension = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/pension', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createPension = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/pension', data).then((response) => {
            commit(types.ADD_PENSION, response.data.pension)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deletePension = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/pension').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

