import {reactive} from "vue";

export function useFormFieldTypes() {

    const formFieldTypes = reactive({
        TEXT: 'text',
        TEXTAREA : 'textarea',
        RADIO : 'radio',
        CHECKBOX : 'checkbox',
    })


    return { formFieldTypes }
}
