import * as types from './mutation-types'

export default {
  [types.ADD_ALLOWANCES](state, data) {
    state.allowances = data.allowances
  },
  [types.ADD_ALLOWANCE] (state, data) {
    state.allowances.push(data.allowances)
  },
}
