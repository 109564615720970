import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";
import { computed } from "vue";


export default {
    menus:  computed(() => (
        {
            sectionTitle: i18n.global.t('menu.explorer.title'),
            module : moduleAbilities.EXPLORER_DOCUMENT,
            permission: [abilities.VIEW_DOCUMENT],
            children: [
                {
                    title: i18n.global.t('menu.fm.documents'),
                    icon: 'fas fa-folder icon-right2',
                    route: 'file-manager-view',
                    group: ['file-manager-view'],
                    id: 'step-file-manager-view',
                    permission: [abilities.VIEW_DOCUMENT],
                    children: [],
                    module: moduleAbilities.EXPLORER_DOCUMENT
                },
            ]
        }
    ))
}
