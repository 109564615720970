import * as types from './mutation-types'

export default {
  [types.LOAD_EXPENSES](state, data) {
    state.expenses = data.expenses
  },

  [types.ADD_EXPENSE](state, data) {
    state.expenses.push(data)
  },

  [types.SET_CURRENT_EXPENSE](state, data) {
    state.currentExpense = data.expense
  },

  [types.LOAD_EXPENSE_STATUS](state, data) {
    state.expenseStatus = data
  },

  [types.LOAD_EXPENSE_TYPES](state, data) {
    state.expenseTypes = data.types
  },
}
