import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";
import { computed } from "vue";

const mailGroup = ['mail-index']

export default {
    menus:  computed(()=>(
        {
            title: i18n.global.t('menu.mail.title'),
            icon: 'fas fa-envelope icon-right2',
            route: 'mail-index',
            group: mailGroup,
            id: 'step-mail',
            permission: [],
            children: [],
            module: moduleAbilities.COMMUNICATION_MAIL
        }
    ))

}
