import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";
import Abilities from "@/composables/abilities";
import { computed } from "vue";

const contractGroup = ['contracts']
const payslipGroup = ["payslips", "payslips-office-create", "payslip-file"]
const absenceGroup = ['rh-absences', 'rh-absences-view']

export default {
    menus:  computed(() => (
        {
            sectionTitle: i18n.global.t("menu.rh.title"),
            module : [moduleAbilities.RH_ABSENCE, moduleAbilities.RH_CONTRACT, moduleAbilities.RH_PAYSLIP],
            permission: abilities.VIEW_RH,
            children: [
                {
                    title: i18n.global.t('menu.rh.absences'),
                    icon: 'fas fa-umbrella-beach icon-right2',
                    route: 'rh-absences',
                    group: absenceGroup,
                    id: 'step-rh-absences',
                    permission: [abilities.VIEW_HOLIDAY],
                    children: [],
                    module: moduleAbilities.RH_ABSENCE,
                },
                {
                    title: i18n.global.t('menu.rh.payslips'),
                    icon: 'fas fa-money-check icon-right2',
                    route: 'payslips',
                    group: payslipGroup,
                    id: 'step-payslip-summary',
                    permission: [abilities.VIEW_PAYSLIP],
                    children: [],
                    module: moduleAbilities.RH_PAYSLIP,
                    query: {}
                },
                {
                    title: i18n.global.t('menu.rh.contracts'),
                    icon: 'fas fa-file icon-right2',
                    route: 'contracts',
                    group: contractGroup,
                    id: 'step-rh-contracts',
                    permission: [Abilities.VIEW_EMPLOYEE_CONTRACT],
                    children: [],
                    module: moduleAbilities.RH_CONTRACT,
    
                },
    
            ]
        }
    ))
}
