import * as types from './mutation-types'

export const loadPayslipTaxeTypeEnum = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('payslip/tax/type/enum', data).then((response) => {
            commit(types.ADD_TAXE_TYPE_ENUM, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPayslipTaxes = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/payroll-taxes/collection', data).then((response) => {
            commit(types.ADD_TAXES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updatePayslipTaxe = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/payroll-taxes', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createPayslipTaxe = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/payroll-taxes', data).then((response) => {
            commit(types.ADD_TAXE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deletePayslipTaxe = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/payroll-taxes').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

