import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";
import { name } from "vue-number-format";

export default {
    /*
    |--------------------------------------------------------------------------
    | RH Routes
    |--------------------------------------------------------------------------|
    */

    path: 'customer',
    name: 'customer',
    children: [
        {
            path: '',
            component: () => import('@/Modules/Customer/views/Customer/Index.vue'),
            name: 'customer-list',
            meta: {},
        },

    ]

}
