<script setup>

import {computed, defineProps} from 'vue'
import { useFormFieldTypes } from '../composable/useFormFieldTypes';
import { useFormFieldBuilder } from '../composable/useFormBuilder';

const model = defineModel()

const props = defineProps({
  formData: { type: Object, required: true },
  formErrors: { type: Array, default: () => [] },
  parentId: String,
  withId: {Boolean, default: true}
})

const { formFieldTypes } = useFormFieldTypes();

const component = computed(() => useFormFieldBuilder(props.formData))
const id = computed(() => {
  if(!props.withId){
    return component.value.data.name
  }else{
    return component.value.data.name+'_'+component.value.data.id
  }
})

const errors = computed(() => {
  return props?.formErrors[id.value] || {}
})


</script>

<template>
  <div class="mb-5">
    <component
        v-if="component"
        :is="component?.component"
        v-bind="{...component.data, parentId}"
        :name="formFieldTypes.select === component.data.type ? component.data.name + component.data.id : component.data.name"
        v-model="model[id]"
        :errors="errors"
    />
  </div>
</template>

<style scoped>

</style>