export const getConversations = (state) => state.conversations
export const getUsers = (state) => state.users
export const getConversation = (state) => (id) => state.conversations[id] || {}
export const getMessages = (state) => (id) => {
    let conversation = state.conversations[id]
    return conversation && conversation.messages ? conversation.messages : [];
}
export const getCalls = (state) => (id) => {
    let conversation = state.conversations[id]
    return conversation && conversation.calls ? conversation.calls : [];
}

export const getActions = (state) => (id) => {
    let conversation = state.conversations[id]
    return conversation && conversation.actions ? conversation.actions : [];
}

export const getCurrentConversationUsers = (state) => state.currentConversationUser
export const getConversionModules = (state) => state.modules
