import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | projects Routes
    |--------------------------------------------------------------------------|
    */

    path: 'mail',
    name: 'mail',
    children: [
        {
            path: 'list',
            component: () => import('@/Modules/Mail/views/Mail/Index.vue'),
            name: 'mail-index',
            meta: { requiresAccess: [moduleAbilities.COMMUNICATION_MAIL]},
        },
        {
            name: "user-config",
            path: "user_imap",
            component: () => import('@/views/User/LayoutUserAccount/BaseSettingUserLayout.vue'),
            children: [
                {
                    path: '',
                    component: () => import('@/Modules/Mail/views/Config/Index.vue'),
                    name: 'config-imap',
                    meta: { requiresAccess: [moduleAbilities.COMMUNICATION_MAIL]},
                },
            ]
        },
        {
            name: "settings-system-imap",
            path: "settings-system",
            component: () => import('@/views/Settings/Layout/BaseSettingSystemLayout.vue'),
            children: [
                {
                    name: "settings-imap",
                    path: "imap",
                    component: () => import('@/Modules/Mail/views/Settings/Imap/index.vue'),
                    meta: { requiresPermission:  abilities.EMAIL_CONFIG_VIEW ,requiresAccess: [moduleAbilities.COMMUNICATION_MAIL]}
                }
            ]
        },
    ]

}
