import * as types from './mutation-types'

export default {
  [types.ADD_PENSIONS](state, data) {
    state.pensions = data.pensions
  },
  [types.ADD_PENSION] (state, data) {
    state.pensions.push(data)
  },
  [types.ADD_PENSION_TYPE_ENUM](state, data) {
    state.types = data
  },
}
