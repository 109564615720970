import * as types from './mutation-types'

export default {
  [types.ADD_CHECKLISTS](state, data) {
    state.checklists = data.data
  },
  [types.ADD_CHECKLIST] (state, data) {
    state.checklists.push(data)
  }
}
