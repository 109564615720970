import * as types from './mutation-types'
import {ADD_ALERT_CONFIG} from "./mutation-types";
import alertConfig from "@/Modules/Projects/store/modules/alertConfig/index";

export default {
  [types.ADD_ALERT_CONFIG](state, data) {
    state.alerts = data.data
  },

  [types.GET_ALERT_CONFIG](state, data) {
    state.alerts = data.alerts
  },

}
