import store from '@/store';
import { ref, computed } from 'vue'

export function useFormatAmount() {
    const locale = computed(() => i18n.global.locale.value)

    const currency = computed(() => store.getters['company/getCompanyCurrency']) ?? "CFA"

    function formatAmount(amount) {
        return new Intl.NumberFormat(locale.value, {
            style: 'currency',
            currency: currency.value?.iso ?? 'XOF',
        }).format(amount);
    }
 
    function formatNumber(n) {
        return  Intl.NumberFormat(locale.value).format(n)
    }

    return { formatAmount, formatNumber }
}