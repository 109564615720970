<template>
   <SubLayout
      :pageTitle="title"
      :pageBreadcrumbs="breadcrumbs"
      :hasBack="false"
  >
    <template #customLayoutContent>
      <div class="d-flex flex-stack bg-white mt-10 p-5 mx-10 card shadow-sm mb-5">
        <ActionsBar v-model="search" :showSearch="showSearch" v-model:category="filter" v-model:type="contactType" :title="title" :breadcrumbs="breadcrumbs" :filter-by="filterBy" :filter-by-type="filterByType"/>
      </div>

      <CardView v-if="route.query.viewType === modelViewType.card"
                :filter="filter"
                :type="contactType"
                :search="search"

      />
      <ListView  v-if="route.query.viewType === modelViewType.list" :filter="filter" :type="contactType"/>
      
      <div class="modal fade" tabindex="-1" id="contactCreateModal">
        <div class="modal-dialog modal-xl">
          <create-contact id="contactCreateModal" :get-countries="getCountries" :get-currencies="getCurrencies" :contact="formCreateData" :modal="true"
            @success="chargeContacts">
          </create-contact>
        </div>
      </div>
      <div class="modal fade" tabindex="-1" id="companyCreateModal">
        <div class="modal-dialog modal-xl">
          <create-contact id="companyCreateModal" :get-countries="getCountries" parent="contactCreateModal" :get-currencies="getCurrencies" :contact="formCreateCompanyData" :modal="true"
            @success="chargeContacts">
          </create-contact>
        </div>
      </div>

      <ImportContactModal @reload="chargeContacts"></ImportContactModal>
      <PostFormModal @postOffice="selectPosts" id="postCreateModal" parent="contactCreateModal" ></PostFormModal>

    </template>
  </SubLayout>
</template>

<script setup>
import ImportContactModal from "@/components/contact/ImportContactModal";
import CreateContact from "@/components/contact/CreateContact";
import {BASE_IMAGE_URL} from "@/services/api";
import {computed, nextTick, onMounted, ref, watch, onBeforeMount, inject} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import SubLayout from "@/views/Layouts/partials/SubLayout.vue";
import ActionsBar from "./components/ActionsBar";
import ListView from './components/List.vue'
import {useModelViewType} from "@/composables/modelViewType";
import CardView from './components/Card.vue'
import PostFormModal from "@/components/Post/postModal.vue"
import i18n from "@/plugins/i18n";

const route = useRoute()
const store = useStore()

const { modelViewType } = useModelViewType()

const title = ref('breadcrumbs.contact.contacts')
const breadcrumbs = ref([
      {
        name: 'breadcrumbs.contact.list',
        path: '/admin/dashboard'
      },

    ])
const formCreateData = ref({
  id: null,
  gender: null,
  contactType: 'particular',
  lastname: null,
  firstname: null,
  address: {
    street: null,
    street2: null,
    city: null,
    zipcode: null,
    country: null
  },
  customer: true,
  birthday: null,
  supplier: false,
  telephone: [],
  previewAvatar: null,
  email: null,
  website: null,
  currency_id: '',
  post_id:null,
  company_id: null,
  enable_portal: false,
})

const formCreateCompanyData = ref({
  id: null,
  gender: null,
  contactType: 'particular',
  lastname: null,
  firstname: null,
  address: {
    street: null,
    street2: null,
    city: null,
    zipcode: null,
    country: null
  },
  customer: true,
  birthday: null,
  supplier: false,
  telephone: [],
  previewAvatar: null,
  email: null,
  website: null,
  currency_id: '',
  post_id:null,
  company_id: null,
  enable_portal: false,
})


const emitter = inject('emitter')

const search = ref('')
const filter = ref('all')
const contactType = ref('all')
const isReady = ref(false)
const isLoadContact = ref(false)
const isLoading = ref(false)
const showSearch = ref(false)

const getCountries = computed(() => store.getters['countries/getCountries'])
const getCurrencies = computed(() => store.getters['company/getCurrencies'])
const filterBy = computed(() =>  [
  { name: 'Tous', value: 'all', translate: i18n.global.t('label.allType') },
  { name: 'Societé', value: 'society', translate: i18n.global.t('society') },
  { name: 'Particulier', value: 'particular', translate: i18n.global.t('particular') }
]) ;
const filterByType = computed(() =>  [
  { name: 'Tous', value: 'all', translate: i18n.global.t('label.allStatus') },
  { name: 'Client', value: 'customer', translate: i18n.global.t('customer') },
  { name: 'Fournisseur', value: 'supplier', translate: i18n.global.t('supplier') },
  { name: 'Client & Fournisseur', value: 'customer_supplier', translate: i18n.global.t('customer_supplier')},
]) ;

onMounted(async() => {
  isReady.value = false
  isLoading.value = true
  loadCountries()
  loadCurrencies()
  showSearch.value = route.query.viewType !== modelViewType.value.list
  if (route.query.status) {
    contactType.value = route.query.status;
  }
})

watch(() => route.query.viewType, (value) => {
  if (value === modelViewType.value.list) showSearch.value = false
  else showSearch.value = true
})


const loadCountries = async () => {
  try {
    await store.dispatch('countries/loadCountries')
  }catch (e) {
  }
}

const loadCurrencies = async () => {
  try {
    await store.dispatch('company/loadCurrencies')
  }catch (e) {
  }
}

async function chargeContacts(contact) {
  if(contact){
    formCreateData.value.company_id = contact.id
  }
  isReady.value = false
  emitter.emit('reload-contact-page')
  isReady.value = true
}

function selectPosts(post){
  formCreateData.value.post_id = post
}



onBeforeMount(() => {
  if(!route.query.viewType) {
    route.query.viewType = modelViewType.value.card
  }
})
</script>

<style scoped>
#contenu {
  padding-right: 15px;
  height: calc(100vh - 200px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

#contenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #F5F5F5;
}

#contenu::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#contenu::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #bcbaba;
}



.contact-card {
  height: 330px !important;
  border-radius: 10px;
}

.contactList {
  margin-bottom: 0;
  margin-top: 0;
}

</style>
