import * as types from './mutation-types'

export default {
  [types.LOAD_PROJECT_CATEGORIES](state, data) {
    state.project_categories = data.data
  },
  [types.LOAD_PROJECT_CATEGORY](state, data) {
    state.current_project_category = data
  },
  [types.LOAD_PROJECT_CATEGORIES_GROUP](state, data) {
    state.project_category_groups = data
  },
  
}
