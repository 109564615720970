import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Ls from './services/ls'
import axios from 'axios'
import { BASE_URL } from './services/api'
import i18n from './plugins/i18n'
import axiosDefault from "@/services/defaultAxios";
import { useLocales } from '@/composables/locales';
import {useLoader} from "@/composables/loader";
import mitt from 'mitt'
import {useHasAbility} from "@/composables/hasAbility";
import {useHasModuleAccess} from "@/composables/hasModuleInstalled";
import moduleAbilities from "@/composables/moduleAbilities";
import {getClientTimezone, getTenantId} from "@/mixins/Helpers";
import sygmefAxios from "@/services/sygmefAxios";



const loadTenantTheme = async () => {
    try {
        await import(`./theme/${getTenantId()}css`);
    } catch (error) {
    }
};

loadTenantTheme().then(r => {} )

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


window.axios = axios

window.axiosDefault = axiosDefault

window.sygmefAxios = sygmefAxios

window.Ls = Ls
window.i18n = i18n
window.api_url = BASE_URL

const emitter = mitt()

window.axios.defaults.baseURL = BASE_URL

axios.interceptors.request.use(function (config) {
    const AUTH_TOKEN = Ls.get('auth.token')
    const { currentLanguage } = useLocales();

    const currentCompany = Ls.get('current.company') || store.getters['offices/getSelectCompanyId']

    config.headers['X-localization'] = currentLanguage.value?.code;
    config.headers['clientTz'] = getClientTimezone();

    if(currentCompany) {
        config.headers['company'] = currentCompany;
    }


    if (AUTH_TOKEN) {
        config.headers.Authorization = `Bearer ${AUTH_TOKEN}`
    }
    return config
}, function (error) {
    return Promise.reject(error)
})


window.axios.interceptors.response.use(undefined, function (err) {

    if (!err.response) {
        window.toastr.error('Network error: Please check your internet connection or wait until servers are back online')

    } else {
        if (err.response.data &&  err.response.status === 401) {
            store.dispatch('auth/logout', true)
            router.push('/login')
        } else if (err.response.data && err.response.status === 404 && Ls.get('auth.token')) {
            router.push({name : 'NotFound'})
        } else if (err.response.data && err.response.status === 403) {
            $('.modal').modal('hide');
            router.push({name : 'PermissionDenied'})

        } else if (err.response.data && err.response.status === 422) {

            if(typeof err.response.data.message == "object"){
                window.toastr.error(Object.values(err.response.data.message).join(", "))
            }else{
                window.toastr.error((err.response.data.message) ? err.response.data.message : i18n.global.t('main.error_try'))
            }
        } else if (err.response.data.errors) {
            const errors = JSON.parse(JSON.stringify(err.response.data.errors))
            for (const i in errors) {
                window.toastr.error(errors[i])
            }
        } else {
            window.toastr.error((err.response.data.message) ? err.response.data.message : 'Unknown error occurred')
        }
    }

    return Promise.reject(err)
})

import './echo';


window.vueJs = createApp(App)
    .use(store)
    .use(i18n)
    .use(router)
    .use(VueAxios, axios)
    .provide('emitter', emitter)
    .provide('moduleAbilities',moduleAbilities)
    .mixin({
        methods: {
            $loading: useLoader().loading,
            $stopLoading: useLoader().stopLoading,
            $can: useHasAbility().can,
            $canAccess: useHasModuleAccess().canAccess,
            $getCssVariable: (variable) => window.getComputedStyle(document.documentElement).getPropertyValue(variable).trim()
        },

    })
    .mount('#app')
