import i18n from "@/plugins/i18n";
import {reactive} from "vue";

export function useNpsIcon() {

    const ICONS = reactive({
        STAR: 'stars',
        EMOJI : 'emojis',
    })

    const icons = reactive([
        {value: ICONS.STAR, name: i18n.global.t(ICONS.STAR) + "(⭐)"},
        {value: ICONS.EMOJI, name: i18n.global.t(ICONS.EMOJI) + "(😊)"},
    ])


    return { icons,ICONS }
}
