import * as types from './mutation-types'

export default {
  [types.ADD_CONTRACTS](state, data) {
    state.contracts = data.contracts
  },
  [types.ADD_CONTRACT] (state, data) {
    state.contracts.push(data)
  },
  [types.ADD_CONTRACT_TYPE_ENUM](state, data) {
    state.types = data
  },
}
