import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";
import { computed } from "vue";

const expenseGroup = ['expenses-summary-stats', 'office-users-expenses-overview','office-expenses','office-users-expenses-list', 'office-user-expense-details',]
const accountGroup = ['finances-accounts', 'finances-accounts-view']


export default {
    menus: computed(() => (
        {
            sectionTitle: i18n.global.t('menu.finance.title'),
            module : [moduleAbilities.FINANCE_ACCOUNT, moduleAbilities.FINANCE_EXPENSE],
            permission: abilities.VIEW_FINANCE,
            children: [
                {
                    title: i18n.global.t('menu.financial.accounts'),
                    icon: 'fas fa-piggy-bank icon-right2',
                    route: 'finances-accounts',
                    group: accountGroup,
                    id: 'step-finances-accounts',
                    permission: [abilities.VIEW_ACCOUNT, abilities.VIEW_BOX],
                    children: [],
                    module: [moduleAbilities.FINANCE_ACCOUNT],
                },
                {
                    title: i18n.global.t('menu.finance.expenses'),
                    icon: 'fas fa-credit-card icon-right2',
                    route: 'expenses-summary-stats',
                    group: expenseGroup,
                    id: 'step-expense-summary',
                    permission: [abilities.APPROVED_EXPENSE, abilities.VALIDATE_EXPENSE, abilities.REJECT_EXPENSE, abilities.PAID_EXPENSE],
                    children: [],
                    module: [moduleAbilities.FINANCE_EXPENSE],
                    query: {viewType: 'list'}
                },
            ]
        }
    
    ))
}
