import * as types from './mutation-types'

export const loadContractTypeEnum = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('employee/contract/type/enum', data).then((response) => {
            commit(types.ADD_CONTRACT_TYPE_ENUM, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadContract = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/employee/contract/collection', data).then((response) => {
            commit(types.ADD_CONTRACTS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateContract = ({ commit }, data) => {
    let formData = $objectToFormData(data)
    formData.append("_method", "POST")
    return new Promise((resolve, reject) => {
        window.axios.post('update/' + data.id + '/employee/contract', formData,{headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createContract = ({ commit }, data) => {
    let formData = $objectToFormData(data)

    return new Promise((resolve, reject) => {
        window.axios.post('store/employee/contract', formData,{headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteContract = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/employee/contract').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const getContract = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.get('get/' + id + '/employee/contract').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadContract = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('download/'+data.id+'/employee/contract', { responseType: 'blob' })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.name);
            document.body.appendChild(link);
            link.click();
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


function $objectToFormData(obj, formData = new FormData(), parentKey = '') {
    if (obj && typeof obj === 'object' && !(obj instanceof File) && !(obj instanceof Blob)) {
        Object.entries(obj).forEach(([key, value]) => {
            const fullKey = parentKey ? `${parentKey}[${key}]` : key;
            
            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    $objectToFormData(item, formData, `${fullKey}[${index}]`);
                });
            } else if (typeof value === 'object' && value !== null) {
                $objectToFormData(value, formData, fullKey);
            } else {
                formData.append(fullKey, value);
            }
        });
    } else {
        formData.append(parentKey, obj);
    }
    return formData;
  }
