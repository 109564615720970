import * as types from './mutation-types'


export const loadAbsenceTypes = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/holiday_types/collection').then((response) => {
            commit(types.ADD_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getUserCountryHolidayTypeManagement = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(id+'/holiday_types/show').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createAbsenceType = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/holiday_type', data).then((response) => {
            commit(types.ADD_TYPE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getAbsenceTypeConfig = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.get('get/'+data.id+'/holiday_types/'+data.country+'/show').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setAbsenceTypeConfig = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('holiday_type/'+data.society_id+'/management', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateAbsenceType = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.put('update/' + data.id + '/holiday_type', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteAbsenceType = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+id+'/holiday_type').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}