<template>
    <div class="modal fade" :id="id" tabindex="-1">
      <div class="modal-dialog">
        <form @submit.prevent="submitForm" class="form">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ edit ? $t('categories.edit_category') : $t('categories.create_category') }}</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" @click="closeModal">
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                            fill="currentColor" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                            fill="currentColor" />
                    </svg>
                  </span>
              </div>
            </div>
            <div class="modal-body">
              <RequiredText />
              <Input
                container-class="form-group mb-5"
                :name="`name`+ id"
                type="text"
                :label="$t('categories.name')"
                labelClass="form-label fs-6 text-black"
                :inputClass="'form-control form-control-lg form-control-solid'"
                size="lg"
                v-model="formData.name"
              />
              <CKEditor 
                :label="$t('categories.description')"
                labelClass="form-label fs-6 text-black"
                :id="'category-editor'+id"
                :content="formData.description"
                @text="getDescription"
              />
            </div>
            <div class="modal-footer">
              <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.cancel" @click="closeModal"></Button>
              <Button btn-class="btn-sm me-3" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :is-loading="isLoading"></Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script setup>
    import {ref, defineEmits, defineProps, computed, onMounted} from "vue";
    import Input from "@/components/Input.vue";
    import {useStore} from "vuex";
    import Button from "@/components/Button.vue"
    import RequiredText from "@/components/RequiredText.vue";
    import CKEditor from '@/components/CKEditor.vue';

    const store = useStore()
    
    const emits = defineEmits(['load', 'account'])
    const props = defineProps({
        id: {
          type: String,
          required: true
        },
        parent: {
          type: String,
          required: true
        },
        form: {
          type: Object,
          default: {
            id: '',
            name: '',
            description: ''
          }
        },
        edit: {
          type: Boolean,
          default: false
        }
    })
    const isLoading = ref(false)

    const formData = ref(props.form)
  
    function getDescription(text) {
      formData.value.description = text
    }

    function closeModal(){
      if(props.parent){
         $('#'+props.parent).modal('toggle')
      }
      $('#'+props.id).modal('toggle')
      formData.value.name = ''
      formData.value.description = ''
    }

    function submitForm(){
      isLoading.value = true
        if(!props.edit){
          store.dispatch('projectCategories/storeProjectCategory', formData.value)
            .then((response) => {
                isLoading.value = false
                emits('load', response.data?.data?.id)
                closeModal()

                window.toastr['success'](i18n.global.t('categories.created_successfully'))
            })
            .catch(err => {
                isLoading.value = false
            })
        }else{
          store.dispatch('projectCategories/updateProjectCategory', formData.value)
            .then((response) => {
                isLoading.value = false
                emits('load', response.data?.data?.id)
                closeModal()

                window.toastr['success'](i18n.global.t('categories.updated_successfully'))
            })
            .catch(err => {
                isLoading.value = false
            })
        }
    }
</script>
    
<style scoped>
  .icon-wrapper {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-wrapper i {
    position: relative;
    font-size: 1.5em;
  }

  .icon-wrapper .icon-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    color: white;
    margin-top: 8px;
  }
</style>