import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const initialState = {
  status: [],
  holidays: [],
  userWorkdays: [],
  userPublicHolidays: [],
  manage_absences: [],
  societyHolidaysByOffice: [],
  societyOffices: [],
  usersHolidays: []
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
