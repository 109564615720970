import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | Crm Routes
    |--------------------------------------------------------------------------|
    */

    path: 'crm',
    name: 'crm',
    children: [

        /*
        |--------------------------------------------------------------------------
        | Pipeline Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'pipeline',
            children: [
                {
                    path: '',
                    component: () => import('@/Modules/CRM/views/Pipeline'),
                    name: 'pipelines',
                    meta: {  requiresPermission: [abilities.VIEW_OPPORTUNITIES, abilities.VIEW_ALL_OPPORTUNITIES], requiresAccess: [moduleAbilities.CRM_PIPELINE]},

                },
                {
                    path: ':id/view',
                    component: () => import('@/Modules/CRM/views/Pipeline/view'),
                    name: 'pipelines-view',
                    meta: { requiresPermission: [abilities.VIEW_OPPORTUNITIES, abilities.VIEW_ALL_OPPORTUNITIES], requiresAccess: [moduleAbilities.CRM_PIPELINE]},
                },
                {
                    path: 'statistics',
                    component: () => import('@/Modules/CRM/views/Pipeline/Statistic.vue'),
                    name: 'pipelines-chart',
                    meta: { requiresPermission: abilities.VIEW_STATS_OPPORTUNITIES, requiresAccess: [moduleAbilities.CRM_PIPELINE]},
                },
            ]
        },

        /*
        |--------------------------------------------------------------------------
        | Payment Routes
        |--------------------------------------------------------------------------|
        */

        {
            path: 'payment',
            children: [
                {
                    path: '',
                    component: () => import('@/Modules/CRM/views/Payment/Index.vue'),
                    name: 'payments',
                    meta: { requiresPermission: [abilities.VIEW_PAYMENT, abilities.VIEW_ALL_PAYMENT] , requiresAccess: [moduleAbilities.CRM_INVOICE ] },
                },
                {
                    path: 'create',
                    component:  () => import('@/Modules/CRM/views/Payment/Detail.vue'),
                    name: 'payments-create',
                    meta: { requiresPermission: abilities.CREATE_PAYMENT , requiresAccess: [moduleAbilities.CRM_INVOICE ] },
                },
                {
                    path: 'view/:id',
                    component:  () => import('@/Modules/CRM/views/Payment/view.vue'),
                    name: 'payments-view',
                    meta: { requiresPermission: [abilities.VIEW_PAYMENT, abilities.VIEW_ALL_PAYMENT], requiresAccess: [moduleAbilities.CRM_INVOICE ]  },
                },
            ]
        },
        /*
        |--------------------------------------------------------------------------
        | Advance Routes
        |--------------------------------------------------------------------------|
        */

        {
            path: 'advance',
            children: [
                {
                    path: 'view/:id',
                    component:  () => import('@/Modules/CRM/views/Advance/view.vue'),
                    name: 'advances-view',
                    meta: { requiresPermission: [abilities.VIEW_INVOICE, abilities.VIEW_ALL_INVOICE] , requiresAccess: [moduleAbilities.CRM_INVOICE ] },
                },
            ]
        },
        /*
        |--------------------------------------------------------------------------
        | Estimate Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'estimates',
            children: [
                {
                    path: '',
                    component:  () => import('@/Modules/CRM/views/Estimate/Index.vue'),
                    name: 'estimates',
                    meta: { requiresPermission: [abilities.VIEW_ESTIMATE, abilities.VIEW_ALL_ESTIMATE] , requiresAccess: [moduleAbilities.CRM_INVOICE ] },
                },
                {
                    path: 'create',
                    component:  () => import('@/Modules/CRM/views/Estimate/Detail.vue'),
                    name: 'estimates-create',
                    meta: { requiresPermission: abilities.CREATE_ESTIMATE , requiresAccess: [moduleAbilities.CRM_INVOICE ] },
                },
                {
                    path: 'edit/:id',
                    component:  () => import('@/Modules/CRM/views/Estimate/Detail.vue'),
                    name: 'estimates-edit',
                    meta: { requiresPermission: [abilities.UPDATE_ESTIMATE, abilities.UPDATE_ALL_ESTIMATE] , requiresAccess: [moduleAbilities.CRM_INVOICE ] },
                },
                {
                    path: 'view/:id',
                    component: () => import('@/Modules/CRM/views/Estimate/view'),
                    name: 'estimates-view',
                    meta: { requiresPermission: [abilities.VIEW_ESTIMATE, abilities.VIEW_ALL_ESTIMATE] , requiresAccess: [moduleAbilities.CRM_INVOICE ] },

                },
                {
                    path: 'statistics',
                    component:  () => import('@/Modules/CRM/views/Estimate/Statistic.vue'),
                    name: 'estimates-chart',
                    meta: { requiresPermission: abilities.VIEW_STATS_ESTIMATE  , requiresAccess: [moduleAbilities.CRM_INVOICE ] },
                },
            ]
        },

          /*
        |--------------------------------------------------------------------------
        | Appointments Routes
        |--------------------------------------------------------------------------|
        */

        {
            path: 'appointments',
            children: [
                {
                    path: '',
                    component: () => import('@/Modules/CRM/views/Appointment/Index.vue'),
                    name: 'appointments',
                    meta: { requiresPermission: [abilities.VIEW_APPOINTMENT, abilities.VIEW_ALL_APPOINTMENT] , requiresAccess: [moduleAbilities.CRM_APPOINTMENT ] },
                },
                {
                    path: 'create',
                    component:  () => import('@/Modules/CRM/views/Appointment/Detail.vue'),
                    name: 'appointments-create',
                    meta: { requiresPermission: abilities.CREATE_APPOINTMENT , requiresAccess: [moduleAbilities.CRM_APPOINTMENT ] },
                },
                {
                    path: 'edit/:id',
                    component:  () => import('@/Modules/CRM/views/Appointment/Detail.vue'),
                    name: 'appointments-edit',
                    meta: { requiresPermission: [abilities.UPDATE_APPOINTMENT, abilities.UPDATE_ALL_APPOINTMENT] , requiresAccess: [moduleAbilities.CRM_APPOINTMENT ] },
                },
                {
                    path: 'view/:id',
                    component:  () => import('@/Modules/CRM/views/Appointment/view.vue'),
                    name: 'appointments-view',
                    meta: { requiresPermission: [abilities.VIEW_APPOINTMENT, abilities.VIEW_ALL_APPOINTMENT] , requiresAccess: [moduleAbilities.CRM_APPOINTMENT ] },
                },
                {
                    path: 'statistic',
                    component:  () => import('@/Modules/CRM/views/Appointment/Statistic.vue'),
                    name: 'appointments-chart',
                    meta: { requiresPermission: abilities.VIEW_STATS_APPOINTMENT , requiresAccess: [moduleAbilities.CRM_APPOINTMENT ] },
                },
            ]
        },
    ]

}
