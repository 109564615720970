import {componentLibraries as Components} from "./useComponentsLibrary.js";
import { useFormFieldTypes } from "./useFormFieldTypes.js";

export function useFormFieldBuilder(data = {}) {
    const { formFieldTypes } = useFormFieldTypes();
    switch (data.type) {
        
        case formFieldTypes.RADIO:
            return {component: Components.FormRadioGroup, data};
        case formFieldTypes.CHECKBOX:
            return {component: Components.FormCheckboxGroup, data};
        case formFieldTypes.TEXTAREA:
            return {component: Components.FormTextArea, data};
        default:
            return {component: Components.FormInput, data};
    }

}