<template>
  <div class="btn shadow-sm btn-icon btn-icon-muted btn-active-light btn-active-color-primary text-primary w-30px w-md-40px fs-4"
       data-kt-menu-trigger="hover" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
       :style="{'border': '1px solid '+ $getCssVariable('--bs-primary') }"
  >
    {{ currentLanguage.code.toUpperCase() }}
  </div>
  <div class="menu menu-sub menu-sub-dropdown menu-column w-150px" data-kt-menu="true">
    <div v-for="lang in languages" :key="lang.code" class="menu-item px-3" :class="currentLanguage.code === lang.code ? 'active' : ''">
      <a @click.prevent="changeLang(lang)" href="#" class="menu-link d-flex px-5">{{ $t(lang.title) }}</a>
    </div>
  </div>
</template>

<script setup>

import { nextTick, onMounted, onUpdated} from "vue";
import {useLocales} from "@/composables/locales";

const {languages, currentLanguage, locale, setLocale} = useLocales()

function changeLang(lang){
  setLocale(lang.code)
}

onMounted(() => {
  KTApp.init()
})

onUpdated(() => {
  nextTick(() => {
    KTApp.init()
  })
})

</script>

<style scoped>
  .active{
    background-color: #f1f5f7;
  }
</style>
