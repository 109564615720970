import * as types from './mutation-types'

export const loadAbsences = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('user/holidays').then((response) => {
            commit(types.ADD_HOLIDAYS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadManageAbsences = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('manage/holidays/calendar?startDate='+data.start_date+'&endDate='+data.end_date+'&office_id='+data.office).then((response) => {
            commit(types.ADD_MANAGE_HOLIDAYS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadSocietyHolidaysByOffice = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('society/holiday/statistics?startDate='+data.start_date+'&endDate='+data.end_date+'&search='+data.search).then((response) => {
            commit(types.ADD_SOCIETY_MANAGE_HOLIDAYS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getUserCountryWorkdays = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('user/country/workdays').then((response) => {
            commit(types.ADD_USER_WORkDAYS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getUserCountryPublicHolidays = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('user/public/holidays').then((response) => {
            commit(types.ADD_USER_PUBLIC_HOLIDAYS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getAbsencesStatistics = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('holidays/user/statistique').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getAbsencesManageStatistics = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('holidays/statistique?startDate='+data.startDate+'&endDate='+data.endDate+'&office_id='+data.office_id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAbsencesStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('holiday/status/enum').then((response) => {
            commit(types.ADD_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showAbsence = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('show/'+id+'/holiday').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createAbsence = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/holiday', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const approbateAbsence = ({commit}, id) => {

    return new Promise((resolve, reject) => {
        window.axios.post('approve/'+ id +'/holiday').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const validateAbsence = ({commit}, id) => {

    return new Promise((resolve, reject) => {
        window.axios.post('validate/'+ id +'/holiday').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const rejectAbsence = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('reject/'+ data.id +'/holiday', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteAbsence = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+id+'/holiday').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadSocietyOfficesHolidays = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/society/'+ id +'/offices/holidays').then((response) => {
            commit(types.LOAD_SOCIETY_OFFICES_HOLIDAYS, response.data.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadUsersHolidays = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/office/'+ id +'/users/holidays').then((response) => {
            commit(types.LOAD_USERS_HOLIDAYS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}