import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | projects Routes
    |--------------------------------------------------------------------------|
    */

    path: 'projects',
    name: 'projects',
    redirect: '/projects/list',
    children: [ 
        {
            path: 'list',
            component: () => import('@/Modules/Projects/views/Projects/Index.vue'),
            name: 'projects-index',
            meta: { requiresAccess: [moduleAbilities.NOTARIAL_REPERTORY,moduleAbilities.PROJECT]},
        },
        {
            path: ':id/view',
            component: () => import('@/Modules/Projects/views/Projects/View.vue'),
            name: 'projects-view',
            meta: { requiresAccess: [moduleAbilities.NOTARIAL_REPERTORY,moduleAbilities.PROJECT]},
        },
        {
            path: ':project/tasks/:id/details',
            component: () => import('@/Modules/Projects/views/Tasks/View.vue'),
            name: 'tasks-view',
            meta: { requiresAccess: [moduleAbilities.NOTARIAL_REPERTORY,moduleAbilities.PROJECT]},
        },
        {
            path: 'settings-projects',
            name: 'settings-projects',
            component: () => import('@/Modules/Projects/views/Setting/Layouts/BaseProjectsSetting.vue'),
            meta: { requiresAccess: [moduleAbilities.NOTARIAL_REPERTORY,moduleAbilities.PROJECT]},
            children: [
                {
                    name: "settings-projects-steps",
                    path: "steps",
                    component: () => import('@/Modules/Projects/views/Setting/Steps/Index.vue'),
                },
                {
                    name: "settings-projects-status",
                    path: "status",
                    component: () => import('@/Modules/Projects/views/Setting/Status/Index.vue'),
                },
                {
                    name: "settings-projects-priorities",
                    path: "priorities",
                    component: () => import('@/Modules/Projects/views/Setting/Priorities/Index.vue'),
                },
                {
                    name: "settings-projects-category-groups",
                    path: "category/groups",
                    component: () => import('@/Modules/Projects/views/Setting/CategoryGroups/Index.vue'),
                },
                {
                    name: "settings-projects-categories",
                    path: "categories",
                    component: () => import('@/Modules/Projects/views/Setting/Categories/Index.vue'),
                },
                {
                    name: "settings-projects-categories-create",
                    path: "categories/create",
                    component: () => import('@/Modules/Projects/views/Setting/Categories/Creation.vue'),
                },
                {
                    name: "settings-projects-categories-edit",
                    path: "categories/:id/edit",
                    component: () => import('@/Modules/Projects/views/Setting/Categories/Detail.vue'),
                },
                {
                    name: "settings-projects-alert-config",
                    path: "alert",
                    component: () => import('@/Modules/Projects/views/Setting/AlertConfig/Index.vue'),
                    meta: { requiresPermission:  "" }
                },
            ]
        },
        {
            path: 'reports',
            component: () => import('@/Modules/Projects/views/Reports/Index.vue'),
            name: 'reports-index',
            meta: { requiresAccess: [moduleAbilities.NOTARIAL_REPORT]},
        },
        {
            path: 'user/performances',
            component: () => import('@/Modules/Projects/views/Performance/Index.vue'),
            name: 'performance-index',
            meta: { requiresAccess: [moduleAbilities.NOTARIAL_REPORT]},
        },
    ]

}
