import * as types from './mutation-types'
import {createFormData} from "@/mixins/Helpers";

export const loadFeeds = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/publication/list', {params: data}).then((response) => {
            commit(types.LOAD_FEEDS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadNextFeeds = ({ commit, state }, data) => {
    let after = state.feeds.map(item => item.id).join(',');
    let filter = {after: after, ...data}
    return new Promise((resolve, reject) => {
        window.axios.get('get/publication/list', {params: filter}).then((response) => {
            commit(types.LOAD_NEXT_FEEDS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showFeed = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/' + data.publication_id + '/publication', {params: data}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadFeedFile = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('download/' + id + '/publication/files', { responseType: 'blob' }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createFeed = ({commit}, data) => {
    let formSubmit = createFormData(data)
    return new Promise((resolve, reject) => {
        window.axios.post('store/publication', formSubmit).then((response) => {
            commit(types.ADD_FEED, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateFeed = ({commit}, data) => {
    let formSubmit = createFormData(data)
    return new Promise((resolve, reject) => {
        window.axios.post('update/publication/' + data.id, formSubmit).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteFeed = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/publication/' + id).then((response) => {
            commit(types.DELETE_FEED, id)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadFeedComments = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/'+ id +'/comments').then((response) => {
            commit(types.LOAD_FEED_COMMENTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createFeedComment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/publication/comment', data).then((response) => {
            commit(types.ADD_FEED_COMMENT, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const replyFeedComment = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('reply/to/comment/'+data.comment_id, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadFeedReactions = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/reaction/type/enum').then((response) => {
            commit(types.LOAD_FEED_REACTIONS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createFeedReaction = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/reaction', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}