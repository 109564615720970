import { createI18n } from 'vue-i18n';
import merge from 'lodash.merge'
import en from './en.json';
import fr from './fr.json';

import crmFr from '@/Modules/CRM/lang/fr.json';
import crmEn from '@/Modules/CRM/lang/en.json';

import financeFr from '@/Modules/Financial/lang/fr.json';
import financeEn from '@/Modules/Financial/lang/en.json';

import rhFr from '@/Modules/RH/lang/fr.json';
import rhEn from '@/Modules/RH/lang/en.json';

import fmFr from '@/Modules/FileManager/lang-module/fr.json';
import fmEn from '@/Modules/FileManager/lang-module/en.json';

import npsFr from '@/Modules/Nps/lang/fr.json';
import npsEn from '@/Modules/Nps/lang/en.json';

import projectsFr from '@/Modules/Projects/lang/fr.json';
import projectsEn from '@/Modules/Projects/lang/en.json';

import chatFr from '@/Modules/Chat/lang/fr.json';
import chatEn from '@/Modules/Chat/lang/en.json';

import mailFr from '@/Modules/Mail/lang/fr.json';
import mailEn from '@/Modules/Mail/lang/en.json'; 
import { getTenantReplacements } from '@/mixins/Helpers';
import { watch } from 'vue';

import feedFr from '@/Modules/NewsFeed/lang/fr.json'
import feedEn from '@/Modules/NewsFeed/lang/en.json'

const capitalize = (value) => {
  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1) 
  }
  return value
}

const replaceVariables = (obj,replacements) => {

  if (typeof obj === 'string') {
    return capitalize(obj.replace(/\{(\w+)\}/g, (_, key) => replacements[key] || `{${key}}`))
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, replaceVariables(value, replacements)])
    )
  }
  return obj
}

const updateMessages = (locale) => {
  const replacements = getTenantReplacements(locale);

  const french = merge({}, fr, crmFr, financeFr, rhFr, fmFr, npsFr, replaceVariables(projectsFr, replacements), chatFr, mailFr, feedFr);
  const english = merge({}, en, crmEn, financeEn, rhEn, fmEn, npsEn, replaceVariables(projectsEn, replacements), chatEn, mailEn, feedEn);

  return { fr: french, en: english };
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('locale') || 'fr',
  messages: updateMessages(localStorage.getItem('locale') || 'fr'), 
  globalInjection: true,
  modifiers: {
    capitalize: (value) => {
      if (typeof value === 'string') {
        return value.charAt(0).toUpperCase() + value.slice(1) 
      }
      return value
    }
  }
});

watch(() => i18n.global.locale.value, (newLocale) => {
  const updatedMessages = updateMessages(newLocale);
  i18n.global.setLocaleMessage(newLocale, updatedMessages[newLocale]);
});

export default i18n;
