

<template>
  <div v-if="props.type == ICONS.EMOJI" class="">

      <div class="d-flex justify-content-center align-items-center gap-10">
        <div
            class="rating-label cursor-pointer me-2"
            @click="selectNps(index+1)"
            v-for="(el, index) in elements"
            :key="i"
            data-bs-toggle="tooltip" 
            data-bs-placement="top"
            :title="getTooltip(index+1)"
            :class="isSelected(index+1) ? 'text-warning' : ''">
            <i :class="[el, 'fs-1', isSelected(index+1) ? 'text-warning' : '']"></i>
        </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex justify-content-center align-items-center gap-10">
        <div
            class="rating-label cursor-pointer me-2"
            @click="selectNps(i)"
            v-for="i in 5"
            :key="i"
            data-bs-toggle="tooltip" 
            data-bs-placement="top"
            :title="getTooltip(i)"
            :class="isChecked(i) ? 'checked' : ''">
            <i class="fa fa-star fs-1 "></i> 
        </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useNpsIcon } from "../../composable/useNpsIcon";

const { ICONS } = useNpsIcon()
const model = defineModel()

const props = defineProps({
  type: {
    type: String,
    default: "emojis",
  },
});
const emit = defineEmits(["update:modelValue"]);

const elements = computed(() =>
  props.type === ICONS.EMOJI
    ? ["fas fa-angry", "fas fa-frown", "fas fa-meh", "fas fa-smile", "fas fa-grin-stars"]
    : ["⭐", "⭐", "⭐", "⭐", "⭐"]
);

const getNpsScore = (index) => {
    if (index === 1) return 4;
    if (index === 2) return 6; 
    if (index === 3) return 8; 
    if (index === 4) return 9; 
    if (index === 5) return 10;
};

const selectNps = (index) => {
  let score = getNpsScore(index);
  model.value = score
};

const getTooltip = (index) => {
  
};

const isChecked = (i) => {
    return getNpsScore(i) <= model.value;
};

const isSelected = (i) => {
    return getNpsScore(i) === model.value;
};

</script>
<style scoped>
.fs-1{
    font-size: xx-large !important;
}
</style>