import * as types from './mutation-types'

export default {
  [types.ADD_ACCOUNTS_DATA](state, data) {
    state.accounts = Array.isArray(data) ? Object.assign({}, data) : data
  },

  [types.ADD_BOXES_DATA](state, data) {
    state.boxes = Array.isArray(data) ? Object.assign({}, data) : data
  },

  [types.LOAD_TRANSACTION_TYPES](state, data) {
    state.transactionTypes = data
  },
}
