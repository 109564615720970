import * as types from './mutation-types'
import {ADD_TASK_PRIORITIE} from "./mutation-types";

export const loadTaskPriorities = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('task-priorities/collections', data).then((response) => {
            commit(types.ADD_TASK_PRIORITIES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateTaskPriority = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('task-priorities/' + data.id + '/update', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createTaskPriority = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('task-priorities/store', data).then((response) => {
            commit(types.ADD_TASK_PRIORITY, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteTaskPriority = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('task-priorities/' + id + '/delete').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

