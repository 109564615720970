<template>
    <div class="modal fade" tabindex="-1" :id="id">
        <div class="modal-dialog modal-lg">
          <form id="kt_modal_form_project" @submit.prevent="updateProject" class="form mt-5" >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ $t('projects.update_project') }}</h5>
                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                            fill="currentColor"/>
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                            fill="currentColor"/>
                    </svg>
                  </span>
                </div>
              </div>
              <div class="modal-body">
                <ProjectForm
                    :form="formEditData"
                    :id="id"
                    :categories="categories"
                    :has-progress="true"
                    :has-cat="!!formEditData?.project_category_id"
                    :has-detail="!!formEditData?.description"
                />
              </div>
              <div class="modal-footer">
                <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.cancel" @click="closeModal"></Button>
                <Button btn-class="btn-sm me-3" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :is-loading="isLoading"></Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <CategoryModal id="project_category_edit" :parent="id" @load="loadCategories"/>
</template>

<script setup>

import { defineProps,onMounted,ref, watch } from 'vue';
import { useStore } from "vuex";
import Button from "@/components/Button.vue"
import { computed } from 'vue';
import ProjectForm from './ProjectForm.vue';
import CategoryModal from "../../Modals/CategoryModal"

const props = defineProps({
  id:{
    type: String, 
    default: "update_project_modal"
  },
  form: {
    type: Object,
    default: {
      id: '',
      title: '',
      reference: '',
      start_date: '',
      end_date: '',
      description: '',
      key_word: [],
      creation_date: '',
      closing_date: '',
      contact_id: '',
      project_category_id: '',
      manager_id: '',
      office_id: '',
      budget_name: '',
      forecast_budget: '',
      currency_id: '',
      allow_dispatch: false,
      users: []
    }
  },
  office:{
    type: String, 
    default: ""
  }
})

const emit = defineEmits(['reloadDatatable'])

const store = useStore()

const formEditData = ref(props.form)

const currentSociety = computed(() => store.getters['offices/getSelectedCompany'])
const categories = computed(() => store.getters['projectCategories/getProjectCategories'])

const isLoading = ref(false)

watch(() => props.office, (value) => {
  if(value) formEditData.value.office_id = value
})

watch(() => props.form, (value) => {
  if(value) formEditData.value = {...props.form,office_id: props.office }
}, {deep: true})

function closeModal(){
  formEditData.value = null
  formEditData.value = {
    title: '',
    reference: '',
    start_date: '',
    end_date: '',
    description: '',
    key_word: [],
    creation_date: '',
    closing_date: '',
    contact_id: '',
    project_category_id: '',
    manager_id: '',
    office_id: props.office,
    budget_name: '',
    forecast_budget: '',
    currency_id: '',
    users: []
  }
  $('#'+props.id).modal('toggle')
}

function loadCategories(id){
    store.dispatch("projectCategories/loadProjectCategories")
    formEditData.value.project_category_id = id
}

async function updateProject() {
    isLoading.value = true
    try{
      await store.dispatch('projects/updateProject', formEditData.value).then((res) => {
        emit('reloadDatatable')
        closeModal()
        window.toastr.success(i18n.global.t("projects.project_updated_successfully"))
        formEditData.value = {}
        isLoading.value = false
      })
    }catch(err){
      isLoading.value = false
    }
}
 
onMounted(async() => {
    store.dispatch("contacts/loadContacts")
    loadCategories()
    store.dispatch("user/loadUsers")
    store.dispatch('currencies/loadCurrencies')

})
</script>

<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding: 1rem;
  scroll-behavior: smooth;
}

</style>
