<template>
  <div id="kt_header" style="" class="header align-items-stretch">
    <div class="container-fluid d-flex align-items-stretch justify-content-between">

      <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show aside menu">
        <div class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
          <span class="svg-icon svg-icon-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                fill="currentColor" />
              <path opasociety="0.3"
                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                fill="currentColor" />
            </svg>
          </span>
        </div>
      </div>

      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a href="#" class="d-lg-none">
          <img alt="Logo" src="/favicon.png" class="h-30px" />
        </a>
      </div>

      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <div class="d-flex align-items-stretch" id="kt_header_nav">
          <div
              class="header-menu align-items-stretch aside-hoverable"
              data-kt-drawer="true"
              data-kt-drawer-name="header-menu"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="{default:'200px', '300px': '250px'}"
              data-kt-drawer-direction="end"
              data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
              >
            <div
              class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
              id="#kt_header_menu" data-kt-menu="true">
            </div>
          </div>
        </div>

        <div class="d-flex align-items-stretch">
          <div class="d-flex align-items-center me-3">
            <Select2
                container-class="col-12"
                :multiple="false"
                :show-search="true"
                selectClass="form-control form-select form-select-solid rounded-end-0 border-start min-w-150px shadow-sm"
                name="company_select"
                :placeholderText="$t('society')"
                :IsInputGroup="true"
                :is-required="false"
                :is-disabled="hideDropdown"
                icon="bi bi-plus"
                color="primary"
                v-model="selectedCompany"
                :clickAddon="openCompanyModal">
              <option v-for="(society) in getOfficesBySocieties" :key="society.id" :value="society.id">
                {{ society.name }}
              </option>
            </Select2>
          </div>
          <div class="d-flex align-items-center me-2">
            <NotificationDropdown :count="numberNotification" />
          </div>
          <div class="d-flex align-items-center me-2">
            <LangDropdown />
          </div>
          <div class="d-flex align-items-center me-2">
            <router-link :to="{ name: 'profile' }" id="step-profil"
                         data-bs-toggle="tooltip"
                         data-bs-custom-class="tooltip-dark"
                         data-bs-placement="top"
                         data-bs-html="true"
                         data-bs-dismiss="click"
                         :title="$t('tooltip.userProfile') + '<br> <span>'+ userName +'</span>'"
              class="btn btn-icon btn-primary btn-active-light btn-active-color-light shadow-sm w-30px w-md-40px h-30px h-md-40px">
              <i class="bi bi-person-fill fs-2"></i>
              <span v-if="false" class="fs-4">{{ userNameCode }}</span>
            </router-link>
          </div>
          <div class="d-flex align-items-center me-2">
            <ModulesDropdown />
          </div>
          <div class="d-flex align-items-center">
            <span @click.prevent="log_out"
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-dark"
                  data-bs-placement="top"
                  data-bs-dismiss="click"
                  :title="$t('header.logout')"
                  class="btn btn-icon btn-black btn-color-white btn-active-black btn-active-color-black shadow-sm w-30px h-30px w-md-40px h-md-40px">
              <i class="fa fa-sign-out-alt fs-2"> </i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreateSocietyModal id="header_create_society_modal" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LangDropdown from "@/components/LangDropdown.vue";
import ModulesDropdown from "@/components/ModulesDropdown.vue";
import Select2 from "@/components/Select2.vue";
import Ls from "@/services/ls";
import CreateSocietyModal from "@/components/Office/CreateSocietyModal";
import NotificationDropdown from "@/components/Notification/NotificationDropdown.vue";

export default {
  components: {NotificationDropdown, CreateSocietyModal, ModulesDropdown, LangDropdown, Select2},
  props : {
    numberNotification : {
      type : String,
      default : 0
    }
  },
  data() {
    return {
      langs: {
        en: {
          'img': '/assets/media/flags/united-states.svg',
          'title': 'header.language.english',
          'code': 'en'
        },
        fr: {
          'img': '/assets/media/flags/france.svg',
          'title': 'header.language.french',
          'code': 'fr'
        },
      },
      currency: 'XOF',
      formCreateCompany: {
        id: null,
        society: '',
        society_email: '',
        ifu_number: '',
        rccm_number: '',
        society_phone_code: '',
        society_phone: '',
        currency_id:'',
        country_id:'',
        zipcode: '',
        address: '',
        headquarters_address: '',
        legal_form: '',
        logo_url: null,
        image_url: null
      },
      selectedCompany: null
    }
  },
  watch: {
    selectedCompany: function (value) {
      Ls.set('current.company', value)
      this.$store.commit('offices/SET_SELECTED_COMPANY', value);
    },
    getSelectedCompany: function (value) {
      if(value) {
        this.selectedCompany = value.id
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    ...mapActions('permission', [
      'loadPermissions'
    ]),
    ...mapActions('offices', [
      'loadOfficesBySocieties'
    ]),
    ...mapActions('moduleAccess', [
      'loadModules'
    ]),

    changeLang(lang) {
      this.$i18n.locale = lang.code
      localStorage.setItem('locale', lang.code)
      this.$nextTick(() => {
        $('body .kt_select').select2()
        $('body .kt_select').select2().select2()
        $('body .kt_select2').select2().select2()
        $('body .kt_select2').select2().select2()
      })
    },
    async log_out() {
      Swal.fire({
        text: i18n.global.t('swal.logout'),
        icon: "success",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: i18n.global.t('swal.yes'),
        cancelButtonText: i18n.global.t('swal.no'),
        customClass: {
          confirmButton: "btn fw-bold btn-primary",
          cancelButton: "btn fw-bold btn-secondary"
        }
      }).then(async (result) => {
        if (result.value) {
          this.logout()
          window.toastr['success'](i18n.global.t("notification.logout"))
        }
      });
    },
    openCompanyModal(){
      $('#kt_modal_header_create_society_modal').modal('toggle')
    },

    initComponents(){
      KTMenu.init();
      KTDrawer.init();
      KTToggle.init()
      KTUtil.init()
      KTApp.init()
    },
  },
  mounted() {
    //this.loadOfficesBySocieties()
  },
  updated() {
    this.$nextTick(() => {
      this.initComponents();
    })
  },
  computed: {
    ...mapGetters('user', [
      'currentUser'
    ]),

    ...mapGetters('offices', [
      'getOfficesBySocieties',
      'getSelectedCompany'
    ]),

    userName() {
      return this.currentUser ? this.currentUser.fullname : ""
    },

    userNameCode() {
      let [first, last] = this.userName.split(' ')
      let code = first[0]
      if(last) {
        code += last[0]
      }
      return code
    },

    currentLanguage() {
      return this.langs[this.$i18n.locale]
    },

    hideDropdown(){
      return [
        'contact-view-path','invoices-edit','invoices-view', 'items-details','recurrent-invoices-edit',
        'recurrent-invoices-view', 'expenses','expenses-create','expenses-edit','expenses-view',
        'pipelines-view','estimates-edit','estimates-view','appointments-edit','appointments-view',
        'office-users-expenses-list','office-expenses','office-user-expense-details','nps-details',
        'nps-preview','nps-responses','nps-responses-details','nps-statistics',
      ].includes(this.$route.name)
    }
  },
}
</script>

<style scoped>

.bdg {
  display: inline-block;
  padding: .325rem .5rem;
  font-size: .85rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .325rem;
}

.bdg.bdg-circle{
  border-radius: 50%;
  padding: 0;
  min-width: unset;
  width: 1.75rem;
}

.bdg.bdg-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  min-width: 1.75rem;
  padding: 0 .1rem;
  line-height: 0;
}

</style>
