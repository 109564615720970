import * as types from './mutation-types'

export default {
  [types.LOAD_REASONS](state, data) {
    state.reasons = data.reasons
  },

  [types.LOAD_REASON_TYPES](state, data) {
      state.reasonTypes = data.types
    },

  [types.ADD_REASON](state, data) {
    state.reasons.push(data)
  },
}
