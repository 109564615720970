import * as types from './mutation-types'
import {createFormData} from "@/mixins/Helpers";

export const loadProjectStatus = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/project/status/enum').then((response) => {
            commit(types.ADD_PROJECT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProjectReport = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/projects/rapport/stats?office_id='+data.office+'&status='+data.status+'&startDate='+data.start_date+'&endDate='+data.end_date+'&contact_id='+data.contact_id+'&project_category_id='+data.project_category_id+'&user_id='+data.user_id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProjects = ({commit}, data) => {
    let contact = data.contact_id || ''
    let project_category_id = data.project_category_id || ''
    let project_category_group_id = data.project_category_group_id || ''
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/projects/card/list?office_id='+data.office+'&startDate='+data.start_date+'&endDate='+data.end_date+'&contact_id='+contact+'&project_category_id='+project_category_id+'&project_category_group_id='+project_category_group_id).then((response) => {
            commit(types.LOAD_PROJECTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createComment = ({commit}, {comment, id}) => { 
    return new Promise((resolve, reject) => {
        window.axios.post(`store/project/${id}/comment`, {
            comment: comment
        }).then((response) => {
            commit(types.ADD_PROJECT_COMMENT, {
                comment: response.data,
                id: id
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getProjectStatusStat = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.get('get/projects/status/Stats?office_id='+data.office+'&startDate='+data.start_date+'&endDate='+data.end_date+'&contact_id='+data.contact_id+'&project_category_id='+data.project_category_id+'&project_category_group_id='+data.project_category_group_id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProjectComments = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/project/${id}/comments`).then((response) => {
            commit(types.ADD_PROJECT_COMMENTS, {
                comments: response.data,
                id: id
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeProject = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/project', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteProjectComment = ({commit}, {id, project}) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`delete/comment/${id}`).then((response) => {
            commit(types.REMOVE_PROJECT_COMMENT, {id, project})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showProject = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.get('show/' + data.project_id + '/project', data).then((response) => {
            commit(types.CURRENT_PROJECT, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getProject = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.get(`get/project/${data.id}/rapport/details?user_id=${data.user_id}`, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProjectDirectories = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/project/${id}/folder/collection`).then((response) => {
            commit(types.ADD_PROJECT_DIRECTORIES, {
                directories: response.data.data,
                id: id
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addUsersToProject = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('add/project/'+ data.project_id +'/users', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createDirectory = ({commit}, {name, id}) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`store/project/${id}/folder`, {
            name: name
        }).then((response) => {
            commit(types.ADD_PROJECT_DIRECTORY, {
                directory: response.data.data,
                id: id
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteDirectory = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+data.projectFolder+'/project/folder').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createDirectoryFile = ({commit}, data) => {
    let formSubmit = createFormData(data)
    return new Promise((resolve, reject) => {
        window.axios.post(`upload/projectFolder/${data.project_folder_id}/files`, formSubmit).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteDirectoryFile = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`delete/projectFolder/${data.project_folder_file_id}/file`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteDirectoryAllFile = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`delete/projectFolder/${data.project_folder_id}/multi/files`, {params: data}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadProjectFile = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.get('download/file/'+data.id, { responseType: 'blob' }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.name);
            document.body.appendChild(link);
            link.click();
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadProjectAllFile = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('download/multiple-files', data, { responseType: 'blob' }).then((response) => {
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'fichiers.zip';
            if (contentDisposition) {
                let match = contentDisposition.split('=');
                if (match && match[1]) {
                    let matchSepare = match[1].split('.')
                    fileName = matchSepare[0]+'_'+moment().format('DD-MM-YYYY')+'.'+matchSepare[1];
                }  
            }
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            link.remove();
            window.URL.revokeObjectURL(url);
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateProject = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/project', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteProject = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+data.project+'/project').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const projectLogs = ({commit}, id) => {

    return new Promise((resolve, reject) => {
        window.axios.get('get/project/' + id + '/logs').then((response) => {
            commit(types.LOAD_CURRENT_PROJECT_LOGS, response.data.logs)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addKeywords = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('add/keyWord/' + data.file_id + '/files', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadReportStatus = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/report/status/enum').then((response) => {
            commit(types.LOAD_REPORT_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProjectStepTasks = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/project/' + id + '/category/step').then((response) => {
            commit(types.LOAD_PROJECT_STEP_TASKS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showProjectReport = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/' + id + '/project/report').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateProjectReport = ({ commit }, data) => {
    let formSubmit = createFormData(data)
    return new Promise((resolve, reject) => {
        window.axios.post('update/' + data.id + '/project/report', formSubmit).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createProjectReport = ({ commit }, data) => {
    let formSubmit = createFormData(data)
    return new Promise((resolve, reject) => {
        window.axios.post('store/project/report', formSubmit).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteProjectReport = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/project/report').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const publishProjectReport = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('publish/' + id + '/project/report/news/feed').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const clientPublishProjectReport = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('publish/' + id + '/project/report/for/client').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const loadBudgetTransactionType = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/budget/transaction/type/enum').then((response) => {
            commit(types.ADD_BUDGET_TRANSACTION_TYPE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const addFundsToBudget = ({commit}, data) => {

    let formData = new FormData();

    formData.append(`reason`, data.reason);
    formData.append(`project_id`, data.project_id);
    formData.append(`amount`, data.amount);

    data.files.forEach((file, index) => {
        if(file instanceof File){
            formData.append(`files[${index}]`, file);
        }
    });

    return new Promise((resolve, reject) => {
        window.axios.post('store/project/'+data.project_id+'/deposit',  formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const spendFromBudget = ({commit}, data) => {
    let formData = new FormData();


    formData.append(`reason`, data.reason);
    formData.append(`project_id`, data.project_id);
    formData.append(`amount`, data.amount);
    formData.append(`project_category_step_id`, data.project_category_step_id);


    data.files.forEach((file, index) => {
        if(file instanceof File){
            formData.append(`files[${index}]`, file);
        }
    });

    return new Promise((resolve, reject) => {
        window.axios.post('store/project/'+data.project_id+'/withdrawal',  formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getBudgetTransactionHistories = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/project/'+data.id+'/histories', {params: data}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getProjectStepWithBudget = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/project/'+id+'/steps').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getProjectBudget = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/project/'+id+'/budget').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const saveProjectBudget = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('update/project/'+data.id+'/budget', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getTransactionDetails = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/project/transaction/'+id+'/details').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getBudgetStatistics = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/project/'+data.id+'/'+data.year+'/statistics', {params: data}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getBudgetMonthStatistics = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/project/'+data.id+'/'+data.month+'/'+data.year+'/statistics',{params: data}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}