export const ADD_CONVERSATIONS = 'ADD_CONVERSATIONS'
export const ADD_CONVERSATION = 'ADD_CONVERSATION'
export const ADD_MESSAGES = 'ADD_MESSAGES'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const ADD_USERS = 'ADD_USERS'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const LOAD_CONVERSATION_USERS = 'LOAD_CONVERSATION_USERS'
export const CHAT_RESET = 'CHAT_RESET'
export const LOAD_CHAT_MODULE = 'LOAD_CHAT_MODULE'

