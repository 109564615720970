import * as types from './mutation-types'
import {ADD_TASK_PRIORITIE} from "./mutation-types";

export const loadTaskStatus = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('task-status/collections', data).then((response) => {
            commit(types.ADD_TASK_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateTaskStatus = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put('task-status/' + data.id + '/update', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createTaskStatus = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('task-status/store', data).then((response) => {
            commit(types.ADD_TASK_STATUT, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteTaskStatus = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('task-status/' + id + '/delete').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

