import * as types from './mutation-types'

export default {
  [types.LOAD_DATA] (state, data) {
    let notifications = data.notifications;
    state.notifications = [
      ...state.notifications,
      ...notifications.data.filter(notification => !state.notifications.some(old => old.id === notification.id))
    ];
    state.notification_count = data.count;
    state.notification_current_page = notifications.current_page;
    state.notification_last_page = notifications.last_page;
  },

  [types.ADD_TO_NOTIFICATION] (state, notification) {
    state.notifications.unshift(notification)
    state.notification_count = state.notification_count + 1;
  },

  [types.SET_DATA] (state, id) {
    state.notifications = state.notifications.map( notification => {
      if(notification.id === id){
        notification.data_read = true;
      }
      return notification
    })
  },

  [types.SET_NOTIFICATION_COUNT] (state) {
    state.notifications = state.notifications.map( notification => {
      notification.data_opened = true;
      return notification
    })
    state.notification_count = 0;
  },
}
