export default {

  VIEW_DASHBOARD: 'dashboard_view',
  VIEW_STATISTIC: 'statistic_view',
  VIEW_USER_PROFILE: null,
  VIEW_COMPANY: null,

  CREATE_CONTACT: 'contact_create',
  VIEW_CONTACT: 'contact_view',
  UPDATE_CONTACT: 'contact_update',
  DELETE_CONTACT: 'contact_delete',
  CONTACT_IMPORT: 'contact_import',
  CONTACT_EXPORT: 'contact_export',
  CONTACT_VIEW_ESTIMATE: 'contact_estimate_view',
  CONTACT_VIEW_INVOICE: 'contact_invoice_view',
  CONTACT_VIEW_OPPORTUNITY: 'contact_opportunity_view',


  CREATE_APPOINTMENT: 'appointment_create',
  VIEW_APPOINTMENT: 'appointment_view',
  VIEW_ALL_APPOINTMENT: 'appointment_view_all',
  UPDATE_APPOINTMENT: 'appointment_update',
  UPDATE_ALL_APPOINTMENT: 'appointment_update_all',
  DELETE_APPOINTMENT: 'appointment_delete',
  DELETE_ALL_APPOINTMENT: 'appointment_delete_all',
  IMPORT_APPOINTMENT: 'appointment_import',
  EXPORT_APPOINTMENT: 'appointment_export',
  SEND_APPOINTMENT: 'appointment_send',
  VIEW_STATS_APPOINTMENT: 'appointment_view_stats',
  CANCEL_APPOINTMENT: 'appointment_cancel',

  CREATE_APPOINTMENT_TYPE: 'appointment_type_create',
  VIEW_APPOINTMENT_TYPE: 'appointment_type_view',
  UPDATE_APPOINTMENT_TYPE: 'appointment_type_update',
  DELETE_APPOINTMENT_TYPE: 'appointment_type_delete',

  CREATE_RECURRING_APPOINTMENT: 'recurring_appointment_create',
  VIEW_RECURRING_APPOINTMENT: 'recurring_appointment_view_all',
  UPDATE_RECURRING_APPOINTMENT: 'recurring_appointment_update',

  CREATE_ESTIMATE: 'estimate_create',
  VIEW_ESTIMATE: 'estimate_view',
  VIEW_ALL_ESTIMATE: 'estimate_view_all',
  UPDATE_ESTIMATE: 'estimate_update',
  UPDATE_ALL_ESTIMATE: 'estimate_update_all',
  DELETE_ESTIMATE: 'estimate_delete',
  DELETE_ALL_ESTIMATE: 'estimate_delete_all',
  IMPORT_ESTIMATE: 'estimate_import',
  EXPORT_ESTIMATE: 'estimate_export',
  PAID_ESTIMATE: 'estimate_paid',
  SEND_ESTIMATE: 'estimate_send',
  VIEW_STATS_ESTIMATE: 'estimate_view_stats',
  PRINT_ESTIMATE: 'estimate_print',
  DOWNLOAD_ESTIMATE: 'estimate_download',

  CREATE_INVOICE: 'invoice_create',
  VIEW_INVOICE: 'invoice_view',
  VIEW_ALL_INVOICE: 'invoice_view_all',
  UPDATE_INVOICE: 'invoice_update',
  UPDATE_ALL_INVOICE: 'invoice_update_all',
  DELETE_INVOICE: 'invoice_delete',
  IMPORT_INVOICE: 'invoice_import',
  EXPORT_INVOICE: 'invoice_export',
  PAID_INVOICE: 'invoice_paid',
  SEND_INVOICE: 'invoice_send',
  VIEW_STATS_INVOICE: 'invoice_view_stats',
  ARCHIVE_INVOICE: 'invoice_archive',
  PRINT_INVOICE: 'invoice_print',
  DOWNLOAD_INVOICE: 'invoice_download',
  NORMALIZE_INVOICE: 'invoice_normalize',
  CREDIT_INVOICE: 'invoice_credit',

  CREATE_PAYMENT: 'payment_create',
  VIEW_PAYMENT: 'payment_view',
  VIEW_ALL_PAYMENT: 'payment_view_all',
  UPDATE_PAYMENT: 'payment_update',
  DELETE_PAYMENT: 'payment_delete',
  IMPORT_PAYMENT: 'payment_import',
  EXPORT_PAYMENT: 'payment_export',
  PAID_PAYMENT: 'payment_paid',
  SEND_PAYMENT: 'payment_send',
  CANCEL_PAYMENT: 'payment_cancel',
  PRINT_PAYMENT: 'payment_print',
  DOWNLOAD_PAYMENT: 'payment_download',

  CREATE_RECURRING_INVOICE: 'recurring_invoice_create',
  VIEW_RECURRING_INVOICE: 'recurring_invoice_view',
  VIEW_ALL_RECURRING_INVOICE: 'recurring_invoice_view_all',
  UPDATE_RECURRING_INVOICE: 'recurring_invoice_update',
  UPDATE_ALL_RECURRING_INVOICE: 'recurring_invoice_update_all',

  CREATE_OPPORTUNITIES: 'opportunities_create',
  VIEW_OPPORTUNITIES: 'opportunities_view',
  VIEW_ALL_OPPORTUNITIES: 'opportunities_view_all',
  UPDATE_OPPORTUNITIES: 'opportunities_update',
  UPDATE_ALL_OPPORTUNITIES: 'opportunities_update_all',
  DELETE_OPPORTUNITIES: 'opportunities_delete',
  ARCHIVE_OPPORTUNITIES: 'opportunities_archive',
  EXPORT_OPPORTUNITIES: 'opportunities_export',
  VIEW_STATS_OPPORTUNITIES: 'opportunities_stats',
  VIEW_PREVISION_OPPORTUNITIES: 'opportunities_prevision_view',
  VIEW_ESTIMATE_OPPORTUNITIES: 'opportunities_estimate_view',
  VIEW_INVOICE_OPPORTUNITIES: 'opportunities_invoice_view',
  GENERATE_ESTIMATE_OPPORTUNITIES: 'opportunities_generate_estimate',
  GENERATE_INVOICE_OPPORTUNITIES: 'opportunities_generate_invoice',
  OPPORTUNITIES_STATE_CHANGE: "opportunities_state_change",

  CREATE_ACTIVITY: 'activity_view',
  VIEW_ACTIVITY: 'activity_create',
  UPDATE_ACTIVITY: 'activity_update',
  DELETE_ACTIVITY: 'activity_delete',

  CREATE_OPPORTUNITY_STATE: 'opportunity_state_create',
  VIEW_OPPORTUNITY_STATE: 'opportunity_state_view',
  UPDATE_OPPORTUNITY_STATE: 'opportunity_state_update',
  DELETE_OPPORTUNITY_STATE: 'opportunity_state_delete',

  CREATE_REASON: 'reason_create',
  VIEW_REASON: 'reason_view',
  UPDATE_REASON: 'reason_update',
  DELETE_REASON: 'reason_delete',

  CREATE_TYPE_ACTIVITY: 'type_activity_create',
  VIEW_TYPE_ACTIVITY: 'type_activity_view',
  UPDATE_TYPE_ACTIVITY: 'type_activity_update',
  DELETE_TYPE_ACTIVITY: 'type_activity_delete',

  CREATE_TAG: 'tag_create',
  VIEW_TAG: 'tag_view',
  UPDATE_TAG: 'tag_update',
  DELETE_TAG: 'tag_delete',

  CREATE_TAX: 'taxe_create',
  VIEW_TAX : 'taxe_view',
  UPDATE_TAX: 'taxe_update',
  DELETE_TAX: 'taxe_delete',

  CREATE_POST_OFFICE: 'post_office_create',
  VIEW_POST_OFFICE: 'post_office_view',
  UPDATE_POST_OFFICE: 'post_office_update',
  DELETE_POST_OFFICE: 'post_office_delete',

  CREATE_ATTRIBUT: 'attribut_create',
  VIEW_ATTRIBUT: 'attribut_view',
  UPDATE_ATTRIBUT: 'attribut_update',
  DELETE_ATTRIBUT: 'attribut_delete',

  CREATE_BRAND: 'brand_create',
  VIEW_BRAND: 'brand_view',
  UPDATE_BRAND: 'brand_update',
  DELETE_BRAND: 'brand_delete',

  CREATE_CATEGORY: 'category_create',
  VIEW_CATEGORY: 'category_view',
  UPDATE_CATEGORY: 'category_update',
  DELETE_CATEGORY: 'category_delete',

  CREATE_PRODUCT: 'product_create',
  VIEW_PRODUCT: 'product_view',
  UPDATE_PRODUCT: 'product_update',
  DELETE_PRODUCT: 'product_delete',
  IMPORT_PRODUCT: 'product_import',
  EXPORT_PRODUCT: 'product_export',

  CREATE_VARIATION: 'variation_create',
  VIEW_VARIATION: 'variation_view',
  UPDATE_VARIATION: 'variation_update',
  DELETE_VARIATION: 'variation_delete',

  CREATE_PAYMENT_CONDITION: 'payment_condition_create',
  VIEW_PAYMENT_CONDITION: 'payment_condition_view',
  UPDATE_PAYMENT_CONDITION: 'payment_condition_update',
  DELETE_PAYMENT_CONDITION: 'payment_condition_delete',

  CREATE_PAYMENT_TYPE: 'payment_type_create',
  VIEW_PAYMENT_TYPE: 'payment_type_view',
  UPDATE_PAYMENT_TYPE: 'payment_type_update',
  DELETE_PAYMENT_TYPE: 'payment_type_delete',

  CREATE_PROFILE: 'profiles_create',
  VIEW_PROFILE: 'profiles_view',
  UPDATE_PROFILE: 'profiles_update',
  DELETE_PROFILE: 'profiles_delete',

  CREATE_USER: 'users_create',
  VIEW_USER: 'users_view',
  VIEW_ALL_USER: 'users_view_all',
  UPDATE_USER: 'users_update',
  UPDATE_ALL_USER: 'users_update_all',
  DELETE_USER: 'users_delete',
  DELETE_ALL_USER: 'users_delete_all',
  IMPORT_USER: 'users_import',
  EXPORT_USER: 'users_export',
  USER_PERMISSION: 'users_permission',

  CREATE_RIGHT: 'right_create',
  VIEW_RIGHT: 'right_view',
  UPDATE_RIGHT: 'right_update',
  DELETE_RIGHT: 'right_delete',
  RIGHT_PERMISSION: 'right_permission_view',

  VIEW_DEPARTMENT: 'department_view',
  CREATE_DEPARTMENT: 'department_create',
  UPDATE_DEPARTMENT: 'department_update',
  DELETE_DEPARTMENT: 'department_delete',


  EMAIL_CONFIG_VIEW :  'smtp_view',
  EMAIL_CONFIG_CREATE : 'smtp_create',


  EMAIL_MESSAGE_VIEW :  'email_message_view',
  EMAIL_MESSAGE_CREATE : 'email_message_create',
  EMAIL_MESSAGE_UPDATE : 'email_message_update',
  EMAIL_MESSAGE_DELETE : 'email_message_delete',

  REMIND_CONFIG_VIEW :  'frequency_view',
  REMIND_CONFIG_CREATE : 'frequency_create',

  TEMPLATE_CONFIG_VIEW :  null,


  VIEW_SETTINGS :  ['taxe_view','users_view','right_view','department_view','reason_view',
    'opportunity_state_view','type_activity_view','appointment_type_view','payment_type_view',
    'payment_condition_view','email_message_view','smtp_view','post_office_view','tag_view'
  ],
  VIEW_GENERAL_SETTINGS :  [ null,'taxe_view','users_view','right_view','department_view',
    'payment_type_view', 'payment_condition_view','email_message_view','smtp_view',
    null,'post_office_view',
  ],
  COMPANY_SETTING : [null,'taxe_view','department_view','post_office_view'],
  MULTI_COMPANY_SETTING : ['email_message_view', 'smtp_view'],
  USER_SETTING: ['users_view', 'right_view'],
  SYSTEM_SETTING: [ 'payment_type_view', 'payment_condition_view','email_message_view','smtp_view',null],
  VIEW_CRM_SETTINGS :  [ 'reason_view', 'opportunity_state_view','type_activity_view','appointment_type_view','tag_view'],
  VIEW_PIPELINE_SETTINGS :  [ 'reason_view', 'opportunity_state_view','type_activity_view','tag_view'],
  VIEW_APPOINTMENT_SETTINGS :  ['appointment_type_view'],
  VIEW_CRM :  ['opportunities_view','opportunities_view_all','estimate_view','estimate_view_all', 'appointment_view','appointment_view_all',
    'payment_view', 'payment_view_all', 'appointment_view_stats', 'opportunities_stats', 'appointment_view_stats',
    'estimate_view_stats'
  ],
  VIEW_FINANCE : [
    'account_view', 'box_view', 'expense_approved', 'expense_validate', 'expense_approved', 'expense_pay'
  ],
  VIEW_DOCUMENT : 'file_manager_view',
  VIEW_RH : ['holiday_view', 'payslips_view', 'employee_contract_view'],
  VIEW_FINANCE_SETTINGS: ['reason_view', 'expense_recurrence_view'],
  VIEW_EXPENSE_SETTINGS: ['reason_view', 'expense_recurrence_view'],
  VIEW_RH_SETTINGS: ['pension_view', 'allowance_view', 'other_view', 'payroll_tax_view'],
  VIEW_PAYSLIP_SETTINGS: ['pension_view', 'allowance_view', 'other_view', 'payroll_tax_view'],
  VIEW_PROJECT_SETTINGS : ['project_create_category'],


  ADMIN: 'Admin',


  VIEW_EXPENSE: 'expense_view',
  UPDATE_EXPENSE: 'expense_update',
  DELETE_EXPENSE: 'expense_delete',
  PAID_EXPENSE: 'expense_pay',
  SEND_EXPENSE: 'expense_send',
  VIEW_STATS_EXPENSE: 'expense_view_stats',
  DOWNLOAD_EXPENSE: 'expense_download',
  VALIDATE_EXPENSE: 'expense_validate',
  APPROVED_EXPENSE : 'expense_approved',
  REJECT_EXPENSE : 'expense_approved',

   CREATE_EXPENSE_REPORT : 'expense_create_report',
   CREATE_EXPENSE_OFFICE : 'expense_create_office',
   VIEW_OFFICE_EXPENSE : 'view_office_expense',
  
  
     VIEW_EXPENSE_REASON : 'expense_reason_view',
     CREATE_EXPENSE_REASON : 'expense_reason_create',
     UPDATE_EXPENSE_REASON : 'expense_reason_update',
     DELETE_EXPENSE_REASON : 'expense_reason_delete',

     VIEW_EXPENSE_TYPE : 'expense_type_view',


   VIEW_EXPENSE_RECURRING : 'expense_recurrence_view',
   CREATE_EXPENSE_RECURRING : 'expense_recurrence_create',
   UPDATE_EXPENSE_RECURRING : 'expense_recurrence_update',
   DISABLE_EXPENSE_RECURRING : 'expense_recurrence_disable',
   ACTIVATE_EXPENSE_RECURRING : 'expense_recurrence_active',

  CREATE_ACCOUNT: 'account_create',
  VIEW_ACCOUNT: 'account_view',
  UPDATE_ACCOUNT: 'account_update',
  DELETE_ACCOUNT: 'account_delete',
  DEPOSIT_ACCOUNT: 'account_deposit',
  WITHDRAWAL_ACCOUNT: 'account_withdrawal',
  TRANSFER_ACCOUNT: 'account_transfer',
  VIEW_STAT_ACCOUNT: 'account_view_stats',

  CREATE_BOX: 'box_create',
  VIEW_BOX: 'box_view',
  UPDATE_BOX: 'box_update',
  DELETE_BOX: 'box_delete',
  DEPOSIT_BOX: 'box_deposit',
  WITHDRAWAL_BOX: 'box_withdrawal',
  TRANSFER_BOX: 'box_transfer',
  VIEW_STAT_BOX: 'box_view_stats',

  CREATE_HOLIDAY: 'holiday_create',
  VIEW_HOLIDAY: 'holiday_view',
  UPDATE_HOLIDAY: 'holiday_update',
  DELETE_HOLIDAY: 'holiday_delete',
  APPROVE_HOLIDAY: 'holiday_approve',
  VALIDATE_HOLIDAY: 'holiday_validate',
  VIEW_STAT_HOLIDAY: 'holiday_view_stats',
  REJECT_HOLIDAY: 'holiday_reject',
  CANCELLED_HOLIDAY : 'holiday_cancelled',

  CREATE_PUBLIC_HOLIDAY: 'public_holiday_view',
  VIEW_PUBLIC_HOLIDAY: 'public_holiday_create',
  UPDATE_PUBLIC_HOLIDAY: 'public_holiday_update',
  DELETE_PUBLIC_HOLIDAY: 'public_holiday_delete',

  CREATE_HOLIDAY_TYPE: 'holiday_type_view',
  VIEW_HOLIDAY_TYPE: 'holiday_type_create',
  UPDATE_HOLIDAY_TYPE: 'holiday_type_update',
  DELETE_HOLIDAY_TYPE: 'holiday_type_delete',

  CREATE_SOCIETY: 'society_view',
  VIEW_SOCIETY: 'society_create',
  UPDATE_SOCIETY: 'society_update',
  DELETE_SOCIETY: 'society_delete',

  CREATE_OFFICE: 'office_view',
  VIEW_OFFICE: 'office_create',
  UPDATE_OFFICE: 'office_update',
  DELETE_OFFICE: 'office_delete',


   VIEW_PAYSLIP : 'payslips_view',
   CREATE_PAYSLIP : 'payslips_create',
   UPDATE_PAYSLIP : 'payslips_update',
   DELETE_PAYSLIP : 'payslips_delete',
   SEND_PAYSLIP : 'payslips_send',
   VALIDATE_PAYSLIP : 'payslips_validate',
    DOWNLOAD_PAYSLIP  : 'download_payslip',
    GENERATE_PAYSLIP  : 'generate_payslip',


   VIEW_ALLOWANCE : 'allowance_view',
   CREATE_ALLOWANCE : 'allowance_create',
   UPDATE_ALLOWANCE : 'allowance_update',
   DELETE_ALLOWANCE : 'allowance_delete',

    VIEW_OTHER : 'other_view',
    CREATE_OTHER : 'other_create',
    UPDATE_OTHER : 'other_update',
    DELETE_OTHER : 'other_delete',

   VIEW_PAYROLL_TAX : 'payroll_tax_view',
   CREATE_PAYROLL_TAX : 'payroll_tax_create',
   UPDATE_PAYROLL_TAX : 'payroll_tax_update',
   DELETE_PAYROLL_TAX : 'payroll_tax_delete',


   VIEW_PENSION : 'pension_view',
   CREATE_PENSION : 'pension_create',
   UPDATE_PENSION : 'pension_update',
   DELETE_PENSION : 'pension_delete',

  
  
   VIEW_EMPLOYEE_CONTRACT : 'employee_contract_view',
   CREATE_EMPLOYEE_CONTRACT : 'employee_contract_create',
   UPDATE_EMPLOYEE_CONTRACT : 'employee_contract_update',
   DELETE_EMPLOYEE_CONTRACT : 'employee_contract_delete',
   STORE_FILE_EMPLOYEE_CONTRACT : 'employee_contract_store_file',
   DOWNLOAD_CONTRACT_EMPLOYEE_CONTRACT : 'employee_contract_download',
   DOWNLOAD_PAYSLIP_EMPLOYEE_CONTRACT : 'employee_contract_download_payslip',


   VIEW_EMPLOYEE : 'employee_view',
   CREATE_EMPLOYEE : 'employee_create',
   UPDATE_EMPLOYEE : 'employee_update',
   DELETE_EMPLOYEE : 'employee_delete',

    VIEW_POST_CATEGORY : 'post_category_view',
    CREATE_POST_CATEGORY : 'post_category_create',
    UPDATE_POST_CATEGORY : 'post_category_update',
    DELETE_POST_CATEGORY : 'post_category_delete',


    VIEW_NORMALIZED_KEY : 'normalized_key_view',
   CREATE_NORMALIZED_KEY : 'normalized_key_create',
   UPDATE_NORMALIZED_KEY : 'normalized_key_update',
   DELETE_NORMALIZED_KEY : 'normalized_key_delete',

   VIEW_NPS : 'nps_view',
   CREATE_NPS : 'nps_create',
   UPDATE_NPS : 'nps_update',
   DELETE_NPS : 'nps_delete',
   SEND_NPS : 'nps_send',
   NPS_STATISTIC : 'nps_statistic',
   VIEW_STATISTIC_NPS : 'nps_view_statistic',

  VIEW_PROJECT: 'project_view',
  CREATE_PROJECT: 'project_create',
  UPDATE_PROJECT: 'project_update',
  DELETE_PROJECT: 'project_delete',
  STATS_PROJECT: 'project_stats',

  STEP_PROJECT_VIEW: 'project_view_step',
  STEP_PROJECT_CREATE: 'project_create_step',
  STEP_PROJECT_UPDATE: 'project_update_step',
  STEP_PROJECT_DELETE: 'project_delete_step',

  TASK_PROJECT_VIEW: 'project_view_task',
  TASK_PROJECT_CREATE: 'project_create_task',
  TASK_PROJECT_UPDATE: 'project_update_task',
  TASK_PROJECT_DELETE: 'project_delete_task',

  FOLDER_PROJECT_VIEW: 'project_view_folder',
  FOLDER_PROJECT_CREATE: 'project_create_folder',
  FOLDER_PROJECT_UPDATE: 'project_update_folder',
  FOLDER_PROJECT_DELETE: 'project_delete_folder',

  ADD_PROJECT_USER: 'project_add_user',
  SEND_PROJECT_COMMENT: 'project_send_comment',
  CREATE_PROJECT_CATEGORY: 'project_create_category',

  VIEW_CHAT: 'chat_view',
  VIEW_MAIL: ''



}
