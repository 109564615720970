import Echo from 'laravel-echo';
import { getTenantId } from '@/mixins/Helpers'
import Ls from './services/ls'

if(window.io){
    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: getTenantId() + process.env.VUE_APP_SOCKET_URL + ':' + process.env.VUE_APP_SOCKET_PORT,
        auth: {
            headers: {
                Authorization: "Bearer " + Ls.get('auth.token'),
                Accept: "application/json",
                'X-Tenant': getTenantId() ? getTenantId().replace(/\.$/, "") : '',
            },
        },
    });
    
}