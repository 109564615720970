<script setup>
import { ref, onMounted, computed } from "vue";
import {getImagePath} from "@/mixins/Helpers";

const props = defineProps({
    filename: { type: String, required: true },
    class: { type: String, },
    style: { type: String, },
});

const imagePath = ref(null);

onMounted(() => {
  getImagePath(props.filename).then((path) => imagePath.value=path );
});
</script>

<template>
  <img v-if="imagePath" :src="imagePath" :alt="props.filename" :class="props.class" :style="props.style"/>
</template>
