import * as types from "./mutation-types";

export const loadAccountsForCountry = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/accounts/${id}/office`).then((response) => {
            commit(types.ADD_ACCOUNTS_DATA, response.data.accounts)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadSocietyBoxes = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/boxes`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadBoxesForOffice = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/boxes/${id}/office`).then((response) => {
            commit(types.ADD_BOXES_DATA, response.data.boxes)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createAccount = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`store/account/${data.society_id}/office`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const createAccountDeposit = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`store/account/${data.account_id}/deposit`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const createAccountWithdrawal = ({ commit, dispatch, state }, data) => {
    let formData = new FormData();
    formData.append('account_id', data.account_id);
    formData.append('amount', data.amount);
    formData.append('reason', data.reason);
    formData.append('expense_created', data.expense_created);
    formData.append('expense_reason', data.expense_reason);
    formData.append('expense_amount', data.expense_amount);
    formData.append('expense_date', data.expense_date);
    formData.append('office_id', data.office_id);
    data.invoices.forEach((invoice, key) => {
            formData.append('invoices[' + key + ']', invoice);
        }
    )
    
    formData.append('_method', 'POST');

    return new Promise((resolve, reject) => {
        window.axios.post(`store/account/${data.account_id}/withdrawal`, formData, data, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
};

export const createAccountTransfer = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`store/account/${data.account_id}/transfer`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updateAccount = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .patch(`update/account/${data.id}/office`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const createBox = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`store/box/${data.office_id}/office`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const createBoxDeposit = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`store/box/${data.box_id}/deposit`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const createBoxWithdrawal = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`store/box/${data.box_id}/withdrawal`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const createBoxTransfer = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .post(`store/box/${data.Box_id}/transfer`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updateBox = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios
            .patch(`update/box/${data.id}/office`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export const accountMonthStatistics = ({ commit, dispatch, state }, data) => {
    if(data.account_id && data.month && data.year){
        return new Promise((resolve, reject) => {
            window.axios.get(`/get/account/${data.account_id}/${data.month}/${data.year}/statistics`,).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export const boxMonthStatistics = ({ commit, dispatch, state }, data) => {
    if(data.account_id && data.month && data.year){
        return new Promise((resolve, reject) => {
            window.axios.get(`/get/box/${data.account_id}/${data.month}/${data.year}/statistics`,).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}




export const accountStatistics = ({ commit, dispatch, state }, data) => {
    if(data.account_id && data.year){
        return new Promise((resolve, reject) => {
            window.axios.get(`/get/account/${data.account_id}/${data.year}/statistics`,).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export const boxStatistics = ({ commit, dispatch, state }, data) => {
    if(data.account_id && data.year){
        return new Promise((resolve, reject) => {
            window.axios.get(`/get/box/${data.account_id}/${data.year}/statistics`,).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export const loadDetailTransaction = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/transaction/${data}/details`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const loadTransactionTypesEnum = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('transaction/type/enum').then((response) => {
            commit(types.LOAD_TRANSACTION_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadSocietyOffices = ({ commit, dispatch, state }, company) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/society/${company}/offices?withUnvalidatedExpenseCount=${false}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



