import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | projects Routes
    |--------------------------------------------------------------------------|
    */

    path: 'news_feed',
    name: 'news_feed',
    children: [
        {
            path: 'list',
            component: () => import('@/Modules/NewsFeed/views/Feed/Index.vue'),
            name: 'feed-index',
            meta: {},
        },
    ]

}
