import i18n from "@/plugins/i18n"
import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";
import { computed } from "vue";


export default {
    menus:  computed(() => (
        {
            title: i18n.global.t('menu.chat.title'),
            icon: 'fa fa-comment icon-right2',
            route: 'chats',
            group: ['chats'],
            id: 'step-chat-view',
            permission: [abilities.VIEW_CHAT],
            children: [],
            module: moduleAbilities.COMMUNICATION_MESSENGER
        }
    ))
}
