import * as types from './mutation-types'

export default {
  [types.ADD_TASK_PRIORITIES](state, data) {
    state.priorities = data.data
  },
  [types.ADD_TASK_PRIORITY] (state, data) {
    state.priorities.push(data)
  }
}
