import * as types from './mutation-types'
import {ADD_TASK_PRIORITIE} from "./mutation-types";

export const loadTaskFiles = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('tasks/get/'+data+'/upload/files', data).then((response) => {
            commit(types.ADD_FILES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const uploadTaskFiles = ({ commit }, data) => {
    let formData = new FormData();

    data.files.forEach((file, index) => {
        if(file instanceof File){
            formData.append(`files[${index}]`, file);
        }

        formData.append('task_id', data?.task_id)
    });

    return new Promise((resolve, reject) => {
        window.axios.post('tasks/upload/files', formData, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            commit(types.ADD_FILE, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteTaskFile = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('tasks/delete/' + id + '/files').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

