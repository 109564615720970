import * as types from './mutation-types'

export default {
  [types.ADD_TASK_STATUS](state, data) {
    state.status = data.data
  },
  [types.ADD_TASK_STATUT] (state, data) {
    state.status.push(data)
  }
}
