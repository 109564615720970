import * as types from './mutation-types'

export default {
  [types.LOAD_FOLDERS](state, data) {
    state.folders = data
  },
  [types.ADD_FOLDER](state, data) {
    state.folders.push(data)
  }
}
