import * as types from './mutation-types'

export default {
  [types.ADD_TYPES](state, data) {
    state.types = data
  },
  [types.ADD_TYPE](state, data) {
    state.types.push(data.holiday_type)
  },
}
