<template>
    <div class="card-body py-5">
        <div class="row mb-5">
            <Select2
                :parent-id="'#'+id"
                :is-required="true"
                container-class="col-12 mb-5"
                :multiple="false"
                :show-search="true"
                :name="'category'+id"
                @update:modelValue="handleCategorySelected"
                :label="$t('projects.category')"
                label-class="form-label"
                v-model="formData.project_category_id"
            >
                <option v-for="category in categories" :value="category?.id" :key="category?.id">
                    {{ category.name }}
                </option>
            </Select2>
             
            <Select2
                :parent-id="'#'+id"
                :is-required="true"
                container-class="col-12 mb-5"
                :multiple="false" 
                :show-search="true"
                :name="'collaborators'+id"
                :label="$t('projects.label.customer')"
                label-class="form-label"
                v-model="formData.contact_id"
            >
              <option v-for="contact in contacts" :value="contact?.id" :key="contact?.id">
                  {{ contact.fullname }}
              </option>
            </Select2>
            <Input
                :name="`${id}-reference`"
                container-class="col-6 mb-5"
                type="text"
                :label="$t('projects.label.number')"
                :isRequired="false" size="lg"
                v-model="formData.reference"
                :mask="selectedCatPrefix"
            />
            <Input
                :name="`${id}-name`"
                container-class="col-6 mb-5"
                type="text"
                :label="$t('projects.label.title')"
                :isRequired="true" size="lg"
                v-model="formData.title"
            />
            <InputDate 
                container-class="col-6 mb-5"
                :name="'start_date'+id"
                :label="$t('projects.label.startDate')"
                :isRequired="true"
                v-model="formData.start_date"
            />
            <InputDate 
                container-class="col-6 mb-5"
                :name="'end_date'+id"
                :label="$t('projects.label.endDate')"
                :isRequired="false" 
                :minDate="formData.start_date"
                v-model="formData.end_date"
            />
        </div>
        <Card class="card shadow-none border border-secondary p-5 mb-5">
            <SwitchInput id="withTeam" :label="$t('projects.label.category')" v-model="withTeam" :between="true"/>
        </Card>
        <div class="row" v-show="withTeam">
            <Select2
                :parent-id="'#'+id"
                :is-required="false"
                container-class="mb-5"
                :multiple="false"
                :show-search="true"
                :name="'manager'+id"
                :label="$t('projects.label.manager')"
                label-class="form-label"
                v-model="formData.manager_id"
            >
                <option v-for="user in users" :value="user?.id" :key="user?.id"> 
                    {{ user.fullname }}
                </option>
            </Select2>
            <Select2
                :parent-id="'#'+id"
                :is-required="false"
                container-class="mb-5"
                :multiple="true"
                :show-search="true"
                :name="'assigned'+id"
                :label="$t('projects.label.assigned')"
                label-class="form-label"
                v-model="formData.users"
            >
                <option v-for="user in users" :value="user?.id" :key="user?.id">
                    {{ user.fullname }}
                </option>
            </Select2>
            
        </div>
        <Card class="card shadow-none border border-secondary p-5 mb-5">
            <SwitchInput id="withDetails" :label="$t('projects.label.details')" v-model="withDetails" :between="true"/>
        </Card>
        <div class="row" v-show="withDetails">
            <CKEditor 
                :label="$t('projects.label.description')"
                :id="'invoice-editor'+id"
                :content="formData.description"
                @text="getDescription"
            />
            <TagifyInput
                containerClass="col-12 mt-5 mb-5"
                type="text"
                :label="$t('projects.label.keywords')"
                labelClass="form-label"
                :inputClass="'form-control form-control-lg form-control-solid'"
                :isRequired="false" size="lg"
                v-model="formData.key_word"
                :parent-id="'#'+id"
                :name="`${id}-keyword`"
                :helper-text="$t('attributes.tag_input_text')"
            />
        </div>
        <div v-if="$canAccess(moduleAbilities.PROJECT_BUDGET)">
            <Card class="card shadow-none border border-secondary p-5 mb-5" v-if="!form?.id">
                <SwitchInput id="withBudget" :label="$t('projects.label.budget')" v-model="withBudget" :between="true"/>
            </Card>
            <div class="row" v-show="withBudget">
                <div class="col-12">
                    <Input
                        container-class="form-group mb-5"
                        :name="`name`+ id"
                        type="text"
                        :label="$t('projects.label.box_name')"
                        labelClass="form-label fs-6 text-black"
                        :inputClass="'form-control form-control-lg form-control-solid'"
                        size="lg"
                        :is-required="withBudget"
                        v-model="formData.budget_name"
                    />
                </div> 
                <div class="col-6">
                    <div class="form-group mb-5">
                    <label class="form-label fs-6 text-black">{{ $t('projects.label.forecast_budget') }} <span class="text-danger">*</span> </label>
                    <VueNumberFormat v-model:value="formData.forecast_budget" class="form-control form-control-solid" :options="{ precision: 0, prefix: '', acceptNegative: false, isInteger: false  }"
                    ></VueNumberFormat>
                </div>
                </div>
                <div class="col-6">
                    <Select2
                    containerClass="col-12 mb-5"
                    :parent-id="'#'+id"
                    :name="`currency`+ id"
                    :multiple="false"
                    labelClass="form-label fs-6 text-black"
                    :label="$t('projects.label.currency')"
                    selectClass="form-select form-select-solid kt_select2"
                    :isRequired="withBudget"
                    :placeholderText="$t('projects.label.currency')"
                    v-model="formData.currency_id"
                >
                    <option :value="currency.id" v-for="currency in currencies" :key="currency.id">{{ currency.name + ' (' +  currency.iso + ')'}}</option>
                </Select2>
                </div>
                <SwitchInput id="allow_dispatch" :label="$t('budgets.allowDispatch')" v-model="formData.allow_dispatch" :between="true"/>
                <span class="fs-7 text-muted">{{ $t('budgets.allowDispatchText') }}</span>
                
            </div>  
        </div>
       
    </div> 
 </template>
 
<script setup>
import { defineProps,ref, watch, computed } from 'vue';
import { useStore } from "vuex";
import Select2 from '@/components/Select2.vue';
import Input from '@/components/Input.vue';
import InputDate from '@/components/InputDate.vue';
import CKEditor from '@/components/CKEditor.vue';
import SwitchInput from '@/components/SwitchInput.vue';
import Card from '@/components/Card.vue';
import TagifyInput from "@/components/TagifyInput.vue";
import VueNumberFormat from 'vue-number-format' 
import moduleAbilities from '@/composables/moduleAbilities';

const props = defineProps({
    form:{
        type:Object,
        default:{}
    },
    categories:{type: Array, default:[]},
    id:{type: String, default:""},
    
})

const store = useStore() 

const withBudget = ref(false)
const withTeam = ref(false)
const withDetails = ref(false)
const formData = ref({})

const contacts = computed(() => store.getters['contacts/getContacts'])
const users = computed(() => store.getters['user/getUsers'])
const selectedCatPrefix = computed(() => {
  if(!formData.value.project_category_id) return ''
  return props.categories.find(el => el.id === formData.value.project_category_id)?.prefix || ''
})
const currencies = computed(() => store.getters['currencies/getCurrencies'])

watch(() => props.form, (value) => {
    formData.value = value

    withTeam.value = !!(value?.manager_id || value?.users?.length);
    withDetails.value = !!(value?.description || value?.key_word?.length);
}, { deep: true });

function getDescription(text) {
    formData.value.description = text
}

function openCreateProjectCategory() {
    $('#'+props.id).modal('toggle')
    $('#project_category_create').modal('toggle')
}

function handleCategorySelected(id) {
      if(id) {
        let cat = props.categories.find(el => el.id === id)
        formData.value.reference = cat?.prefix
      }
    }

</script>