<template>
  <div class="modal fade" :id="id" tabindex="-1">
    <div class="modal-dialog">
      <form @submit.prevent="submitForm" class="form">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('projects.add_users') }}</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" @click="closeModal">
                <span class="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                          fill="currentColor" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                          fill="currentColor" />
                  </svg>
                </span>
            </div>
          </div>
          <div class="modal-body"> 
            <RequiredText />
            <Select2
              :parent-id="'#'+id"
              :name="`keywords`+ id"
              :multiple="true"
              labelClass="form-label fs-6 text-black mt-5"
              :label="$t('projects.users')"
              selectClass="form-select form-select-solid kt_select2"
              :isRequired="true"
              :placeholderText="$t('projects.select_users')"
              v-model="formData.users"
            >
              <option v-for="user in users" :value="user?.id" :key="user?.id">
                {{ user.fullname }}
              </option>
            </Select2>
          </div>
          <div class="modal-footer">
            <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.cancel" @click="closeModal"></Button>
            <Button btn-class="btn-sm me-3" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :is-loading="isLoading"></Button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
  import {ref, defineEmits, defineProps, computed, onMounted, watch} from "vue";
  import {useStore} from "vuex";
  import Button from "@/components/Button.vue"
  import RequiredText from "@/components/RequiredText.vue";
  import Select2 from "@/components/Select2.vue";

  const store = useStore()

  const users = computed(() => store.getters['user/getUsers'])
  
  const emits = defineEmits(['load'])
  const props = defineProps({
      id: {
        type: String,
        required: true
      },
      office: {
        type: String,
        required: true
      },
      form: {
        type: Object,
        default: {
          project_id: '',
          users: [],
        }
      }
  })
  const isLoading = ref(false)
  const formData = ref(props.form)

  watch(() => props.form, () => {
    formData.value = props.form
  }, {deep: true})

  function closeModal(){
      $('#'+props.id).modal('toggle')
  }

  function submitForm(){
    isLoading.value = true
    store.dispatch('projects/addUsersToProject', formData.value)
        .then((response) => {
            isLoading.value = false
            formData.value.users = []
            emits('load')
            closeModal()

            window.toastr['success'](i18n.global.t('projects.users_added_successfully'))
        })
        .catch(err => {
            isLoading.value = false
        })
  }

  onMounted(async() => {
      store.dispatch("user/loadUsers")
  })
</script>
    
<style scoped>
  .icon-wrapper {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-wrapper i {
    position: relative;
    font-size: 1.5em;
  }

  .icon-wrapper .icon-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    color: white;
    margin-top: 8px;
  }
</style>