import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | Chat Routes
    |--------------------------------------------------------------------------|
    */

    path: 'chats',
    children: [
        {
            path: '',
            component: () => import('@/Modules/Chat/views/Chat/Index.vue'),
            name: 'chats',
            meta: { requiresPermission: [abilities.VIEW_CHAT], requiresAccess: [moduleAbilities.COMMUNICATION_MESSENGER ]  },
        },

        {
            path: ':id/messages',
            component: () => import('@/Modules/Chat/views/Chat/Index.vue'),
            name: 'chats-conversation-messages',
            meta: { requiresPermission: [abilities.VIEW_CHAT], requiresAccess: [moduleAbilities.COMMUNICATION_MESSENGER ]  },
        },
    ]

}
