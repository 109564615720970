import * as types from './mutation-types'

export default {
  [types.ADD_COMMENTS](state, data) {
    state.comments = data.data
  },
  [types.ADD_COMMENT] (state, data) {
    state.comments.push(data)
  }
}
