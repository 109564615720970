import * as types from './mutation-types'

export const getPayslipStatus = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('payslip/status/enum').then((response) => {
            commit(types.LOAD_PAYSLIP_STATUS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getPayslipCompanyOffices = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/payslip/offices/statistics', {
            params: data
        }).then((response) => {
            commit(types.LOAD_PAYSLIP_COMPANY_OFFICES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const getPayslipOfficeEmployees = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/offices/payslips', {
            params: data
        }).then((response) => {
            commit(types.LOAD_PAYSLIP_OFFICE_EMPLOYEES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const getCurrentEmployeePayslip = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/draft/payslips', {
            params: data
        }).then((response) => {
            commit(types.LOAD_CURRENT_EMPLOYEE_PAYSLIP, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const storePayslip = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/payslip', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const updatePayslip = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch(`payslip/${data.id}/update`, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const validatePayslips = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('payslips/validate', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const downloadPayslips = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('download/payslip', {
            responseType: 'blob',
            params: data
        }).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const generatePayslipExpense = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('payslip/generate/monthly/expenses', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getPayslip = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/'+id+'/payslip', id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}