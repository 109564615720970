import * as types from './mutation-types'

export default {
  [types.ADD_PROJECT_MILESTONES](state, data) {
    state.allMilestones = data.data
  },
  [types.ADD_TASK_MILESTONES](state, data) {
    state.milestones = data.data
  },
  [types.ADD_TASK_MILESTONE] (state, data) {
    state.milestones.push(data)
  },
  [types.UPDATE_TASK_MILESTONE] (state, data) {
    const index = state.milestones.findIndex(milestone => milestone.id === data.id);
    if (index !== -1) {
        state.milestones[index] = { ...state.milestones[index], ...data };
    }
  },
  [types.DESTROY_TASK_MILESTONE] (state, id) {
    state.milestones = state.milestones.filter(milestone => milestone.id !== id);
  },
}
