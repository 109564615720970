import { createStore } from 'vuex'

import * as getters from './getters'
import mutations from './mutations'
import actions from './actions'
import taxes from './modules/taxes'
import brands from './modules/brands'
import attributs from './modules/attributs'
import categories from './modules/categories'
import variations from './modules/variations'
import products from './modules/products'
import auth from "@/store/modules/auth";
import contacts from './modules/contacts'
import countries from './modules/settings/countries'
import cities from './modules/settings/cities'
import department from './modules/department'
import user from "@/store/modules/user";
import permission from './modules/permission'
import role from "@/store/modules/role";
import company from "@/store/modules/company";
import currencies from "@/store/modules/settings/currencies";
import invoices from "@/store/modules/invoices";
import payments from "@/store/modules/payments";
import gender from "@/store/modules/settings/gender";
import stats from "@/store/modules/stats";
import mail from "@/store/modules/mail";
import smtp from "@/store/modules/smtp";
import crm from "@/Modules/CRM/store/index"
import finance from "@/Modules/Financial/store/index"
import rh from "@/Modules/RH/store/index"
import nps from "@/Modules/Nps/store/index"
import customer from "@/Modules/Customer/store/index"
import post from "@/store/modules/post/index"
import bank from "@/store/modules/bank"
import configs from "@/store/modules/settings/configs";
import moduleAccess from "@/store/modules/moduleAccess";
import normalization from "@/store/modules/normalization";
import fm from "@/Modules/FileManager/store"
import chat from "@/Modules/Chat/store/index"
import multiSociety from "@/store/modules/multiSociety";
import projects from "@/Modules/Projects/store/index"
import mails from "@/Modules/Mail/store/index"
import feeds from "@/Modules/NewsFeed/store/index"
import notifications from "@/store/modules/notifications"

const initialState = {
  isAppLoaded: false,
  appAsideMinimize: false
}

export default createStore({
  state: initialState,

  getters,
  mutations,
  actions,

  modules: {
    taxes,
    brands,
    attributs,
    categories,
    variations,
    products,
    auth,
    countries,
    cities,
    contacts,
    department,
    permission,
    user,
    role,
    company,
    currencies,
    invoices,
    payments,
    gender,
    stats,
    crm,
    finance,
    rh,
    nps,
    mail,
    smtp,
    post,
    bank,
    configs,
    moduleAccess,
    normalization,
    fm,
    multiSociety,
    projects,
    chat,
    mails,
    feeds,
    customer,
    notifications
  }
})
