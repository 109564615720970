import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | RH Routes
    |--------------------------------------------------------------------------|
    */

    path: 'file-manager',
    name: 'file-manager',
    redirect: 'admin/file-manager/view',
    children: [
        {
            path: 'view',

            children: [
                {
                    path: '',
                    component: () => import('@/Modules/FileManager/views/Index'),
                    name: 'file-manager-view',
                    meta: {  requiresPermission: abilities.VIEW_DOCUMENT, requiresAccess: [moduleAbilities.EXPLORER_DOCUMENT]},
                }
            ]
        },
    ]

}
