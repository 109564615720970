import Ls from "@/services/ls";

export const getOfficesBySocieties = (state) => state.offices
export const getSocietyOffices = (state) => state.society_offices
export const getEmployees = (state) => state.employees
export const getOffices = (state) => state.society_offices
export const getCompanyPresence = (state) => (id) => state.companiesPresence[id] || []
export const onlineDiscussion = (state) => (companyId, userId) => {
    let users =  state.companiesPresence[companyId] || []
    let user = users.find(x => x.id === userId)
    return !!user
}



export const getSelectedCompany = (state) => {
    if(state.selectedCompany) {
        return state.offices.find(el => el.id === state.selectedCompany)
    } else if(Ls.get('current.company')) {
        return state.offices.find(el => el.id === Ls.get('current.company'))
    } else {
        return state.offices?.[0]
    }
}

export const getSelectCompanyId = (state) => state.selectedCompany

