import * as types from './mutation-types'

export default {
  [types.ADD_TAXES](state, data) {
    state.taxes = data.payrollTaxes
  },
  [types.ADD_TAXE] (state, data) {
    state.taxes.push(data.payrollTax)
  },
  [types.ADD_TAXE_TYPE_ENUM](state, data) {
    state.types = data
  },
}
