<template>
  <div class="me-2">
    <button class="btn btn-sm btn-flex btn-outline-primary fw-bolder shadow-sm text-hover-white" :class="btnClass" :style="{borderRadius: '8px', borderWidth: border ? '1px' : '0', borderStyle: border ? 'solid' : 'none', borderColor: border ? $getCssVariable('--bs-primary') : '#fff'}"
            data-kt-menu-trigger="hover"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-start">
      <i v-if="showIcon" class="text-primary" :class="icon"></i>
      <span class="mx-3">{{ translate == true ? $t(displayText) || $t(btnTitle) : displayText || btnTitle }}</span>
      <i v-if="hideAngle" class="fa fa-angle-down text-primary fs-2"></i>
    </button>
    <div id="filterDropdown" class="menu menu-sub menu-sub-dropdown menu-column w-150px w-md-200px" data-kt-menu="true"> 
      <div v-if="search" class="menu-item py-2">
        <div class="menu-content fs-6 text-gray-900 fw-bold">
          <Input
              container-class="me-2"
              inputClass="form-control form-control-sm"
              name="input-search"
              :isInputGroup="true"
              :placeholder="$t('label.search')"
              v-model="searchValue"
          >
            <template #rightAddon>
              <span class="position-absolute border-0 bg-transparent end-0 top-50 translate-middle">
                <i class="fa fa-search"></i>
              </span>
            </template>
          </Input>
        </div>
      </div>
      <div v-if="search" class="separator border-gray-200 mb-3 opacity-75"></div>
      <div class="mh-250px hover-scroll-y" :class="{ 'py-2': !search }">
        <div v-if="btnTitle" class="menu-item px-3 my-1">
          <span  href="#" @click.prevent="toggleSelectedOption('')"
                 :class="{ 'bg-light-primary text-primary' : !modelValue }"
                 class="menu-link bg-hover-light-primary text-hover-primary px-3">
            {{ btnTitle }}
          </span>
        </div>
        <div v-for="(option, index) in filterOptions" :key="index" class="menu-item px-3 my-1">
          <span href="#" @click.prevent="toggleSelectedOption(option.value)"
                :class="{ 'bg-light-primary text-primary' : modelValue === option.value }"
                class="menu-link bg-hover-light-primary text-hover-primary px-3">
            {{ translate == true ? $t(option.name) : option.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import Input from '@/components/Input.vue';

const props = defineProps({
  btnTitle: { type: String, required: false },
  search: { type: Boolean, default: true },
  options: { type: Array, default: () => [] },
  valueField: { type: String, default: 'id' },
  nameField: { type: String, default: 'name' },
  modelValue: String,
  showIcon: { type: Boolean, default: true },
  btnClass: { type: String, required: false },
  translate: { type: Boolean, default: false },
  border: { type: Boolean, default: false },
  icon: {
    type: String,
    default: 'fa fa-filter'
  },
  hideAngle: { type: Boolean, default: true },
});

const emits = defineEmits(['update:modelValue']);
const searchValue = ref('');

const valueFieldSelector = computed(() => props.valueField || 'id');
const nameFieldSelector = computed(() => props.nameField || 'name');

const formattedOptions = computed(() => {
  return props.options.map(option => {
    const name = option[nameFieldSelector.value];
    const value = option[valueFieldSelector.value];
    return {
      name,
      value,
    };
  });
});

const filterOptions = computed(() => {
  if (!props.search) return formattedOptions.value;
  if (!searchValue.value) return formattedOptions.value;
  let list = formattedOptions.value.filter(option =>
      option.name?.toString().toLowerCase().includes(searchValue.value.toLowerCase())
  );

  return Object.values(list).length > 0 ? list : props.btnTitle ? props.btnTitle : formattedOptions.value[0]?.name
});

const displayText = computed(() => formattedOptions.value.find(el => el.value == props.modelValue)?.name )

function toggleSelectedOption(selected) {
  emits('update:modelValue', selected);
}
</script>

<style scoped>
/* Add your styles here */
</style>
