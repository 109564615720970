<template>
  <div class="d-flex flex-column fv-row" :class="containerClass">
    <label v-if="label" class="form-label" :class="labelClass">
      <span :class="required ? 'required' : ''">{{ label }}</span>
    </label>
    <div
      ref="editorRef"
      class="form-control form-control-solid min-h-90px h-100 w-100"
      :id="id"
    ></div>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, defineProps, defineEmits } from "vue";

  const props = defineProps({
    id: String,
    content: { type: String, default: "" },
    label: String,
    labelClass: String,
    required: Boolean,
    containerClass: String,
    disabled: { type: Boolean, default: false }
  });

  const editorRef = ref(null);
  let editorInstance = null;
  const emits = defineEmits(["text", "ready"]);

  onMounted(() => {
    if (window.ClassicEditor) {
      ClassicEditor
        .create(editorRef.value, {
          toolbar: [
            "heading", "bold", "italic", "underline", "strikethrough",
            "fontSize", "fontFamily", "fontColor", "fontBackgroundColor",
            "|", "alignment", "outdent", "indent",
            "|", "bulletedList", "numberedList", "todoList",
            "|", "blockQuote", "insertTable", "undo", "redo",
            "|", "removeFormat", "sourceEditing"
          ],
          readOnly: props.disabled
        })
        .then(editor => {
          editorInstance = editor;
          editor.setData(props.content);

          editor.model.document.on("change:data", () => {
            emits("text", editor.getData());
          });

          emits("ready", editorInstance);
        })
        .catch(error => console.error("CKEditor Error:", error));
    }
  });

  watch(() => props.content, (newValue) => {
    if (editorInstance && editorInstance.getData().trim() !== newValue.trim()) {
      editorInstance.setData(newValue);
    }
  });

  watch(() => props.disabled, (isDisabled) => {
    if (editorInstance) {
      editorInstance.isReadOnly = isDisabled;
    }
  });
</script>

<style>
  .ck-editor__editable {
    min-height: 170px;
  }
  .ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by {
    line-height: var(--ck-powered-by-line-height);
    display: none !important;
  }
</style>
