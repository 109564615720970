<template>
    <div class="mb-10">
        <Label :required="required" :label="label" :name="name" :labelClass="labelClass"/>
        <input
            :type="type"
            :name="name"
            :class="inputClass"
            :id="'kt_tagify'+id"
            v-model="value"
            :placeholder="placeholder"
            :required="required"
            :readonly="readonly"
        />

      <div class="w-100 text-start text-muted">{{$t("help")}}</div>
    </div>
  </template>
  
  <script setup>
  /* eslint-disable */
  import { useField } from 'vee-validate';
  import {onMounted, ref, watch} from "vue";
  import Label from "../Label.vue";
  const props = defineProps( {
    name: String,
    id: {type: String, default: ""},
    modelValue: Array,
    placeholder: String,
    type: {type:String, default: "text"},
    required: {type:Boolean, default: true},
    inputClass: {type:String, default: "form-control form-control-lg form-control-solid"},
    labelClass: String,
    label: String,
    readonly:{type: Boolean, default: false},
    help: {type:String, default:'emailsText' },

  })

  const emits = defineEmits(['update:modelValue'])

  const value = ref(props.modelValue)

  watch(() => props.modelValue, () => {
    value.value = props.modelValue
  },{deep: true})

  onMounted(() => {
    initTag()
  })


  const initTag = () => {
    let input = document.querySelector('#kt_tagify'+props.id);
    new Tagify(input, {
      pattern: props.type == "email" ? /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ : "" ,
      transformTag: function (e) {
        (e.class = "tagify__tag tagify__tag-light--" + ["success"][0]);
      },
    })
        .on("invalid", function (e) {
          if (e.detail.data.__isValid === 'pattern mismatch') {
            window.toastr['error'](i18n.global.t("notification.invalid_email"))
          }
        })
        .on('add', (e) => {
          emits('update:modelValue', e.detail.tagify.value.map(el => el.value))
        })
        .on("remove", (e) => {
          emits('update:modelValue', e.detail.tagify.value.map(el => el.value))
        });
  }

  </script>
  <style scoped>
  input.invalid {
    border: 1px solid red;
  }
  </style>