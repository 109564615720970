import accounts from "./modules/accounts"
import expenses from "./modules/expenses"
import reason from "./modules/reason"
import recurrentExpense from "./modules/recurrentExpense"
import offices from "./modules/offices"

export default {
    modules: {
      accounts,
      expenses,
      reason,
      recurrentExpense,
      offices
    }
}
