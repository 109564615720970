import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'



const initialState = {
  notifications: [],
  notification_count: 0,
  notification_last_page: 0,
  notification_current_page: 0,

}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
