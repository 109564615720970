import * as types from './mutation-types'
import {ADD_ALERT_CONFIG, ADD_TASK_PRIORITIE} from "./mutation-types";

export const loadAlertConfig = ({ commit},id ) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/project/alert',{params:{society_id:id}}).then((response) => {
            commit(types.GET_ALERT_CONFIG, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeAlertConfig = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/project/alert', data).then((response) => {
            commit(types.ADD_ALERT_CONFIG, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}