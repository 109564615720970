import * as types from './mutation-types'

export default {
  [types.LOAD_PAYSLIP_COMPANY_OFFICES](state, data) {
    state.payslipCompanyOffices = data.data
  },
  [types.LOAD_PAYSLIP_OFFICE_EMPLOYEES](state, data) {
    state.payslipOfficeEmployees = data
  },
  [types.LOAD_CURRENT_EMPLOYEE_PAYSLIP](state, data) {
    state.currentEmployeePayslip = data
  },
  [types.LOAD_PAYSLIP_STATUS](state, data) {
    state.payslipStatus = data
  },
}
