<template>
  <div class="aside-menu flex-column-fluid" style="overflow-y: auto; max-height: 80vh; overflow-x: hidden !important;">
    <div id="kt_aside_menu_wrapper"
         class="hover-scroll-overlay-y"
         data-kt-scroll="true"
         data-kt-scroll-activate="{default: false, lg: true}"
         data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
         data-kt-scroll-height="auto"
         data-kt-scroll-offset="0"
         data-kt-scroll-wrappers="#kt_aside_menu">

      <div id="kt_aside_menu"
           class="menu menu-column menu-sub-indention fw-semibold fs-6"
           data-kt-menu="true" data-kt-menu-expand="false">
          <template v-for="(section, index) in menu" :key="index">
          <div v-if="section.sectionTitle  && $canAccess(section.module) && $can(section.permission)" class="menu-item pt-5">
            <div class="menu-content">
              <span class="menu-heading fw-bold text-uppercase fs-7 text-gray-700"> {{ section.sectionTitle }} </span>
            </div>
          </div>

          <template v-for="(menuItems, index) in section.children" :key="index">
            <div v-if="menuItems.children.length && $can(menuItems.permission)  && $canAccess(menuItems.module) " class="menu-accordion menu-item" :class="{ 'show': activateUrl(menuItems.group) }" data-kt-menu-trigger="click">
              <span class="menu-link">
                <span class="menu-icon mr-3">
                  <i class="fs-2" :class="menuItems.icon"></i>
                </span>
                <span class="menu-title">{{ menuItems.title }}</span><span class="menu-arrow"></span>
              </span>
              <div class="menu-sub menu-sub-accordion" :class="{ 'show': activateUrl(menuItems.group) }">
                <div v-for="(items) in menuItems.children" class="menu-item" >
                  <router-link class="menu-link"
                               :class="{ 'active': activateUrl(items.group) }"
                              :to="{name: items.route}"
                              v-if=" $can(items.permission) && $canAccess(items.module)">
                            <span class="menu-bullet">
                              <span class="bullet bullet-dot"></span>
                            </span>
                    <span class="menu-title">{{ items.title }} </span>
                  </router-link>
                </div>

              </div>
            </div>

            <div v-else class="menu-item " v-if="$can(menuItems.permission) && $canAccess(menuItems.module)">
              <router-link  class="menu-link" :class="{ 'active': activateUrl( menuItems.group) }" :to="{name: menuItems.route}">
                <div :id="menuItems?.id" class="menu-icon mr-3">
                  <i class="fs-2" :class="menuItems.icon"></i>
                </div>
                <span class="menu-title ">{{ menuItems.title }}</span>
              </router-link>
            </div>
          </template>


        </template>
      </div>
    </div>
  </div>

  <div class="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
    <div style="padding: 0px!important;" class="btn btn-custom btn-light-primary btn-active-light-primary w-100" data-bs-toggle="tooltip" data-bs-trigger="hover">
      <span class="btn-label py-3">Version {{ appVersion }}</span>
      <span class="btn-icon" style="padding: 0px!important;">v{{ appVersion }}</span>
    </div>
  </div>
</template>

<script>
import { appVersion } from "@/mixins/Helpers";
import crmMenu from '@/Modules/CRM/menu'
import financeMenu from '@/Modules/Financial/menu'
import rhMenu from '@/Modules/RH/menu'
import fmMenu from '@/Modules/FileManager/menu'
import npsMenu from '@/Modules/Nps/menu'
import projectsMenu from '@/Modules/Projects/menu'
import chatMenu from '@/Modules/Chat/menu'
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";
import i18n from "@/plugins/i18n";
import mailMenu from '@/Modules/Mail/menu'

export default {
  data() {
    return {
      dashboard: ['home', 'home-path'],
      contact: ['contacts-path','contact-view-path'],
      invoiceIndex: ['invoices', "recurrent-invoices"],
      invoices: ['invoices', 'invoices-create', "invoices-edit", "invoices-view"],
      recurrentInvoices: ['recurrent-invoices', 'recurrent-invoices-create', "recurrent-invoices-edit", "recurrent-invoices-view"],
      modules: ['modules'],
      settings: ['settings-new', 'settings-taxes', 'settings', 'settings-users', 'settings-rights-view', 'settings-rights', 'settings-departments','settings-system-mail',
      'settings-department-users', "crm-settings-pipelines","crm-settings-pipelines-losse-reasons", "crm-settings-pipelines-activity-types", "crm-settings-pipelines-steps",
      "settings-recurrence-expense", "settings-recurrence-expense-create", "settings-recurrence-expense-edit", "settings-recurrence-expense-view",
      'settings-leaves', 'settings-leave-create', 'settings-leave-update', 'settings-projects-steps', 'settings-projects-status', 'settings-projects-priorities', 
      'settings-projects-categories', 'settings-projects-category-groups'],
      items: ['attributes', 'brands', 'categories', "items", "items-details"],
      attributes: ['attributes'],
      brands: ['brands'],
      categories: ['categories'],
      products: ["items", "items-details"],
      expenses: ['expenses', "expenses-new", "expenses-edit", "expenses-view"],

    }
  },
  computed: {
    menu() {
      return [
        {
          sectionTitle: '',
          module : '',
          children: [
            {
              title: i18n.global.t('menu.home'),
              module : moduleAbilities.DASHBOARD,
              icon: 'fas fa-home',
              route: 'home-path',
              group: this.dashboard,
              id: 'step-dashboard',
              permission: [abilities.VIEW_DASHBOARD],
              children: [],
              query: {}
            },
            {
              title: i18n.global.t('menu.contacts'),
              module : moduleAbilities.CONTACT,
              icon: 'fas fa-users',
              route: 'contacts-path',
              group: this.contact,
              id: 'contacts-path',
              permission: [abilities.VIEW_CONTACT],
              children: [],
              query: {viewType: 'card'}
            },
            {
              title: i18n.global.t('menu.items'),
              module : moduleAbilities.STOCK_ARTICLE,
              icon: 'fas fa-box',
              route: 'items',
              group: this.items,
              id: 'items-path',
              permission: [
                abilities.VIEW_ATTRIBUT, abilities.VIEW_BRAND, abilities.VIEW_CATEGORY,
                abilities.VIEW_PRODUCT, abilities.VIEW_VARIATION
              ],

              children: [
                  {
                    title: i18n.global.t('menu.products'),
                    route: 'items',
                    group: this.products,
                    id: 'step-products',
                    permission: [abilities.VIEW_PRODUCT],
                    children: [],
                    module: [],
                  },
                  {
                    title: i18n.global.t('menu.attributs'),
                    route: 'attributes',
                    group: this.attributes,
                    id: 'step-attributs',
                    permission: [abilities.VIEW_ATTRIBUT],
                    children: [],
                    module: [],
                  },
                  {
                    title: i18n.global.t('menu.brands'),
                    route: 'brands',
                    group: this.brands,
                    id: 'step-brands',
                    permission: [abilities.VIEW_BRAND],
                    children: [],
                    module: [],
                  },
                  {
                    title: i18n.global.t('menu.categories'),
                    route: 'categories',
                    group: this.categories,
                    id: 'step-categories',
                    permission: [abilities.VIEW_CATEGORY],
                    children: [],
                    module: [],
                  }
              ],
              query: {}
            },

            {
              title: i18n.global.t('menu.invoices'),
              icon: ' fas fa-file-invoice-dollar',
              route: 'invoices',
              group: this.invoiceIndex,
              id: 'step-invoices',
              permission: [abilities.VIEW_INVOICE, abilities.VIEW_ALL_INVOICE, abilities.VIEW_RECURRING_INVOICE, abilities.VIEW_ALL_RECURRING_INVOICE],
              module: [moduleAbilities.GENERAL_INVOICE, moduleAbilities.CRM_INVOICE],
              children: [
                {
                  title: i18n.global.t('menu.invoice_title'),
                  icon: 'fas fa-file-invoice-dollar icon-right1',
                  route: 'invoices',
                  group: this.invoices,
                  id: 'step-invoices',
                  permission: [abilities.VIEW_INVOICE, abilities.VIEW_ALL_INVOICE],
                  children: [],
                  module: [moduleAbilities.GENERAL_INVOICE],
                },
                {
                  title: i18n.global.t('menu.recurrent-invoice'),
                  icon: 'fas fa-file-invoice-dollar icon-right1',
                  route: 'recurrent-invoices',
                  group: this.recurrentInvoices,
                  id: 'step-recurrent-invoices',
                  permission: [abilities.VIEW_RECURRING_INVOICE, abilities.VIEW_ALL_RECURRING_INVOICE],
                  children: [],
                  module: [moduleAbilities.CRM_INVOICE],
                }
              ],
              query: {}
            },
            {
              title: i18n.global.t('menu.finance.expenses'),
              icon: 'fas fa-money-bill-wave icon-right2',
              route: 'expenses',
              group: this.expenses,
              id: 'step-expenses',
              permission: [abilities.VIEW_EXPENSE],
              children: [],
              module: moduleAbilities.FINANCE_EXPENSE,
              query: {viewType: 'list'}
            },

            {
              title: i18n.global.t('menu.settings'),
              icon: 'fas fa-cog icon-right2',
              route: 'settings',
              group: this.settings,
              id: 'step-setting',
              permission: abilities.VIEW_SETTINGS,
              children: [],
              query: {},
            },

          ]
        },
        crmMenu.menus.value,
        financeMenu.menus.value,
        rhMenu.menus.value,
        {
          sectionTitle: i18n.global.t('menu.communicate.title'),
          module : [moduleAbilities.COMMUNICATION_MESSENGER],
          permission: [abilities.VIEW_CHAT,abilities.VIEW_MAIL,moduleAbilities.COMMUNICATION_MESSENGER],
          children: [
            chatMenu.menus.value,
            mailMenu.menus.value,
          ]
        },
        projectsMenu.menus.value,

        fmMenu.menus.value,
        npsMenu.menus.value,

      ]
    },
    appVersion() {
      return appVersion.value
    }
  },

  methods: {
    activateUrl(group) {
      return group.includes(this.$route.name)
    },
    openCreateModal(){

    }
  },
}
</script>
<style scoped>
.aside.aside-dark .pos-btn-custom {
  color: #b5b5c3;
  background-color: rgba(63, 66, 84, .35);
}
.aside .pos-btn-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}
</style>
