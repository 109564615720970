import * as types from './mutation-types'
import {createFormData} from "@/mixins/Helpers";

export const loadMails = ({ commit }, data) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/emails', {params: data}).then((response) => {
            commit(types.LOAD_MAILS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadPriorities = ({ commit }) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/email/priorities').then((response) => {
            commit(types.LOAD_PRIORITIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadUsersAndContacts = ({ commit }) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/user/contact/email').then((response) => {
            commit(types.LOAD_USER_CONTACTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getMail = ({ commit }, data) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/folder/email', {params: data}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const restoreMail = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('restore/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const replyMail = ({ commit }, id) => {
    
    return new Promise((resolve, reject) => {
        window.axios.post('emails/reply/' + id).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const transferMail = ({ commit }, data) => {
    let formSubmit = createFormData(data)
    return new Promise((resolve, reject) => {
        window.axios.post('transfer/email/' + data.email_id, formSubmit).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createMail = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('send-email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const markAsRead = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('mark/asRead/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const markAsUnread = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('mark/asUnread/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const markAsImportant = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('mark/important/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const markAsNotImportant = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('mark/not/important/email', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeMailFolder = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('email/changeFolder', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteTrashMail = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('delete/email/to/Trash', data).then((response) => {
            commit(types.DESTROY_MAIL, data.email_ids)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteMail = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('delete/email', data).then((response) => {
            commit(types.DESTROY_MAIL, data.email_ids)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getUserConfig = ({ commit }) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/user/imap/configs').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setUserConfig = ({ commit }, data) => {
    
    return new Promise((resolve, reject) => {
        window.axios.post('set/user/imap/configs', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getImapConfig = ({ commit }) => {
    
    return new Promise((resolve, reject) => {
        window.axios.get('get/imap-config/collection').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setImapConfig = ({ commit }, data) => {
    
    return new Promise((resolve, reject) => {
        window.axios.post('store/imap-config', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}