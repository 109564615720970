<template>
    <div class="form-check form-check-custom form-check-primary form-check-solid" :class="classes">
      <input :name="name"
             class="form-check-input"
             type="radio" :value="value"
             :checked="model == value"
             :id="id"
             @change="handleChange"
             :required="required"
      />
      <Label :required="false" v-if="label" label-class="form-check-label" :label="label" :name="id" />
    </div>
  </template>
  
  <script setup>
  
  import {defineEmits, defineProps} from 'vue'
import Label from '../../Label.vue';
  
  const model = defineModel()
  
  const props = defineProps({
    label: String,
    name: String,
    id: String,
    value: [Boolean, Number, Object],
    valueType: {type: [String, Boolean, Number]},
    required: Boolean,
    classes: [String, Array, Object],
  })
  
  const handleChange = (e) => {
  
    let newValue = e.target.value;
  
    if (props.valueType === 'boolean') {
      newValue = newValue === 'true';
    } else if (props.valueType === 'number') {
      newValue = Number(newValue);
    }
    model.value = newValue
  }
  
  
  </script>
  
  <style scoped>
  
  </style>