import * as types from './mutation-types'
import store from "@/store";


export const loadConversationsModules = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/chat/module/types').then((response) => {
            commit(types.LOAD_CHAT_MODULE, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadConversations = ({commit}, payload) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/chat/conversations', {
            params: payload,
        }).then((response) => {
            commit(types.ADD_CONVERSATIONS, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const loadConversationUsers = ({commit}, conversation) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/chat/users/${conversation}/conversations`).then((response) => {
            commit(types.LOAD_CONVERSATION_USERS, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const loadMessages = ({commit, getters, dispatch}, conversationId) => {
    let conversation = getters.getConversation(conversationId)
    if(!conversation.loaded) {
        return new Promise((resolve, reject) => {
            window.axios.get(`chat/conversations/${conversationId}/messages`).then((response) => {
                commit(types.ADD_MESSAGES, {
                    messages: response.data.data,
                    id: conversationId,
                    count: response.data.data.length,
                })
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}
export const sendMessage = ({commit}, {content, conversationId}) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/conversations/${conversationId}/messages`, {
            message: content,
        }).then((response) => {
            commit(types.ADD_MESSAGE, { message: response.data.data, id: conversationId})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const createPersonalDiscussion = ({commit}, formData) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/conversations`, formData).then((response) => {
            commit(types.ADD_CONVERSATION, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const createGroupDiscussion = ({commit}, formData) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/group/conversations`, formData).then((response) => {
            commit(types.ADD_CONVERSATION, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const addGroupMembers = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/group/${data.conversation_id}/add/members`, data).then((response) => {
            commit(types.LOAD_CONVERSATION_USERS, response.data?.participators)
            commit(types.ADD_MESSAGE, { action: response.data?.action, id: data.conversation_id})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const removeGroupMembers = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/group/${data.conversation_id}/remove/members`, data).then((response) => {
            commit(types.LOAD_CONVERSATION_USERS, response.data?.participators)
            commit(types.ADD_MESSAGE, { action: response.data?.action, id: data.conversation_id})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const sendAudio = ({commit}, {audio, conversationId}) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/conversations/${conversationId}/messages/audio`, audio).then((response) => {
            commit(types.ADD_MESSAGE, { message: response.data.data, id: conversationId})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const sendFiles = ({commit}, {files, conversationId}) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/conversations/${conversationId}/messages/file`, files, {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            commit(types.ADD_MESSAGE, { message: response.data.data, id: conversationId})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const loadAllUsers = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`chat/all/users`, {
            params: data
        }).then((response) => {
            commit(types.ADD_USERS, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const loadCalls = ({commit, getters}, conversationId) => {
    if(!getters.getConversation(conversationId).loaded) {
        return new Promise((resolve, reject) => {
            window.axios.get(`chat/conversation/${conversationId}/calls`).then((response) => {
                commit(types.ADD_MESSAGES, {
                    calls: response.data.data,
                    id: conversationId,
                })
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}
export const startCall = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/call/start`, data).then((response) => {
            commit(types.ADD_MESSAGE, { call: response.data.data, id: data.conversation_id})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const joinCall = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/call/${data.id}/join`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const stopCall = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/call/${data.id}/stop`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const rejectCall = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/call/${data.id}/reject`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const listenNewConversationEvent = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        const userId = store.getters['user/currentUser']?.id

        if(userId && window.Echo) {
            window.Echo.channel(`App.New.Personal.Conversation.${userId}`)
                .listen('.Core\\v1\\Chat\\Events\\NewPersonalConversationEvent', (e) => {
                    if(e?.conversation && e.conversation.creator_id !== userId) {
                        commit(types.ADD_CONVERSATION, e.conversation)
                    }
                })
            window.Echo.channel(`App.New.Group.${userId}`)
                .listen('.Core\\v1\\Chat\\Events\\NewGroupEvent', (e) => {
                    if(e?.conversation && e.conversation.creator_id !== userId) {
                        commit(types.ADD_CONVERSATION, e.conversation)
                    }
                })
        }
    })
}

export const loadActions = ({commit, getters}, conversationId) => {
    if(!getters.getConversation(conversationId).loaded) {
        return new Promise((resolve, reject) => {
            window.axios.get(`chat/conversation/${conversationId}/actions`).then((response) => {
                commit(types.ADD_MESSAGES, {
                    actions: response.data.data,
                    id: conversationId,
                })
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export const shareMessage = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`transfer/chat/conversation`, data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

