import axios from 'axios'

const token = process.env.VUE_APP_MECEF_API_TOKEN

const config = {
    baseURL: 'https://developper.impots.bj/sygmef-emcf/api/',
};

const sygmefAxios = axios.create(config)



export default sygmefAxios