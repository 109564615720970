import * as types from './mutation-types'


export const loadReasonTypes = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('types').then((response) => {
            commit(types.LOAD_REASON_TYPES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadReasons = ({commit}, type="") => {
    return new Promise((resolve, reject) => {
        window.axios.get('reasons?type='+type).then((response) => {
            commit(types.LOAD_REASONS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createReason = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('reason', data).then((response) => {
            resolve(response)
            commit(types.ADD_REASON, response.data.reason)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateReason = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('reason/' + data.id + '/update', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteReason = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('reason/' + id + '/delete').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

