import { ref} from "vue";

export function useModelViewType() {

    const modelViewType = ref({
        list : 'list',
        kanban : 'kanban',
        graph : 'graph',
        prevision: 'prevision',
        calendar: 'calendar',
        grid: 'grid',
    })


    return { modelViewType }
}
