import Card from '@/Modules/CRM/components/Card.vue'
import { markRaw } from 'vue'
import Select2 from '@/components/Select2.vue'
import CheckboxInput from '@/components/CheckboxInput.vue'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import InputDate from '@/components/InputDate.vue'
import TextArea from '@/components/TextArea.vue'
import Label from '../components/Label.vue'
import RadioGroup from '../components/Inputs/Radio/RadioGroup.vue'
import CheckboxGroup from '../components/Inputs/Checkbox/CheckboxGroup.vue'


export const componentLibraries = markRaw({
    FormCard: Card,
    FormLabel: Label,
    FormSelect: Select2,
    FormRadioGroup: RadioGroup,
    FormCheckboxGroup: CheckboxGroup,
    FormRadio: CheckboxInput,
    FormButton: Button,
    FormInput: Input,
    FormDate: InputDate,
    FormTextArea: TextArea,
})