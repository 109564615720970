import * as types from "./mutation-types";

export const loadWeekdays = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/weekdays').then((response) => {
            commit(types.LOAD_WEEKDAYS, response.data.weeks)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getCountryWorkdays = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/get/office/${id}/workdays`).then((response) => {
            commit(types.LOAD_COMPANY_WORKDAYS, {
                id: id,
                workDays: response.data.workdays
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setCountryWorkdays = ({ commit, dispatch, state }, {company_id, data}) => {
    console.log(data)
    return new Promise((resolve, reject) => {
        window.axios.post(`/store/office/${company_id}/workdays`, {
            workdays: data
        }).then((response) => {
            console.log(response)
            /*commit(types.LOAD_COMPANY_WORKDAYS, {
                id: id,
                workDays: response.data.workdays
            })*/
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createPublicHoliday = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/public_holiday', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updatePublicHoliday = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.put('update/' + data.id + '/public_holiday', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deletePublicHoliday = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+id+'/public_holiday').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadWeekdayEnums = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('weekday/enum').then((response) => {
            commit(types.ADD_WEEKDAY_ENUM, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}